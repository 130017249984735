import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import CorrectiveListCompact from "../components/CorrectiveList";
import { CorrectiveContext, withCorrectiveProvider } from "../CorrectiveProvider";
import { useLocation, useHistory } from "react-router-dom";
import { EquipmentAndSpareContext } from "../../../equipment_spare/EquipmentAndSpareProvider";

const CorrectiveMaintenanceContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const { loadEquipmentList } = useContext(EquipmentAndSpareContext);
  const { loadCorrectiveList, dashboardFilterStatus } = useContext(CorrectiveContext);
  const { pagination, filterParams } = useContext(CorrectiveContext);
  const { loadFilterOptions } = useContext(MaintenanceContext);

  const searchtext = filterParams.searchText;

  useEffect(() => {
    if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
      const queryParams = new URLSearchParams(location);
      if (queryParams.has("status")) {
        queryParams.delete("status");
        history.replace({
          search: queryParams.toString(),
        });
      }

      loadCorrectiveList();
    }
    loadEquipmentList();
    loadFilterOptions();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    if (status && !dashboardFilterStatus) {
      loadCorrectiveList(null, status);
    }
  }, [pagination, searchtext]);

  return (
    <React.Fragment>
      <Head title="Brighter App | Corrective Maintenance" />
      <Content>
        <CorrectiveListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withCorrectiveProvider(CorrectiveMaintenanceContainer);
