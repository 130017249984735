import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Row, UncontrolledDropdown } from "reactstrap";
import { Icon, RSelect } from "../../../components/Component";
import { FormManagementContext } from "../FormManagementProvider";
import { sortOption } from "../../../utils/Utils";

function FormFilter() {
  const formManagementContext = React.useContext(FormManagementContext);
  const {
    filterParams,
    handleApplyFilter,
    resetFormListFilter,
    sfState,
    filterOptions,
    checkIsFilterApplied,
    showFilter,
  } = formManagementContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    resetFormListFilter();
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    checkIsFilterApplied();
    sfState.off();
    updateTableSm(false);
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleRoleFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedFormRole: selectedOption.value });
  };

  const handleStateFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedFormStatus: selectedOption.value });
  };

  const selectedRoleOption = filterOptions.formRoles?.find((o) => o.value === selectedFilter.selectedFormRole) || null;

  const selectedFormStatusOption =
    filterOptions.formStatusses?.find((o) => o.value === selectedFilter.selectedFormStatus) || null;

  return (
    <div className="vh-100">
      <div className="dropdown-head">
        <span className="sub-title dropdown-title fs-16px">Filter Options</span>
        <div onClick={sfState.off}>
          <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
        </div>
      </div>
      <div className="dropdown-body dropdown-body-rg">
        <Row className="gx-6 gy-3 d-block ">
          <Col size="12">
            <FormGroup>
              <label className="overline-title overline-title-alt">Role</label>
              <RSelect
                options={sortOption(filterOptions.formRoles)}
                placeholder="Any Role"
                onChange={handleRoleFilterSelection}
                value={selectedRoleOption}
              />
            </FormGroup>
          </Col>
          <Col size="12">
            <FormGroup>
              <label className="overline-title overline-title-alt">Status</label>
              <RSelect
                options={sortOption(filterOptions.formStatusses)}
                placeholder="Any Status"
                onChange={handleStateFilterSelection}
                value={selectedFormStatusOption}
              />
            </FormGroup>
          </Col>

          <Col className="d-flex justify-content-between" size="12">
            <Button
              outline={true}
              onClick={(ev) => {
                ev.preventDefault();
                handleReset();
              }}
              color="secondary"
            >
              {" "}
              Reset Filter
            </Button>
            <Button onClick={handleApply} color="secondary">
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default FormFilter;
