import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../components/Component";
import { sortOption } from "../../../utils/Utils";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";

const PvPlantListFilter = () => {
  const pvPlantManagementContext = React.useContext(PvPlantManagementContext);
  const { filterOptions } = pvPlantManagementContext;
  const { filterParams, handleApplyFilter, checkIsFilterApplied, sfState, showFilter, clientList, userList } =
    pvPlantManagementContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    pvPlantManagementContext.resetPvPlantListFilter();
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    updateTableSm(false);
    checkIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };
  const handleStateFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedPvPlantStatus: selectedOption.value });
  };

  const handlePlantManagerFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedPlantManager: selectedOption.value });
  };
  const handleClientFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedClient: selectedOption.value });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const selectedPvPlantStatusOption =
    filterOptions.plantStatuses?.find((o) => o.value === selectedFilter.selectedPvPlantStatus) || null;
  const selectedClientOption = clientList?.find((o) => o.value === selectedFilter.selectedClient) || null;

  const selectedPlantManagerOption = userList?.find((o) => o.value === selectedFilter.selectedPlantManager) || null;

  return (
    <div className="vh-100  ">
      <div className="dropdown-head">
        <span className="sub-title dropdown-title fs-16px">Filter Options</span>
        <div onClick={sfState.off}>
          <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
        </div>
      </div>
      <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={filterOptions.plantStatuses}
                  placeholder="Any Status"
                  onChange={handleStateFilterSelection}
                  value={selectedPvPlantStatusOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Plant Manager</label>
                <RSelect
                  options={sortOption(userList)}
                  placeholder="Any Plant Manager"
                  onChange={handlePlantManagerFilterSelection}
                  value={selectedPlantManagerOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Client</label>
                <RSelect
                  options={sortOption(clientList)}
                  placeholder="Any Client"
                  onChange={handleClientFilterSelection}
                  value={selectedClientOption}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchived"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchived">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                {" "}
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </UncontrolledDropdown>
    </div>
  );
};

export default PvPlantListFilter;
