import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../../components/Component";
import FilteredByList from "../../../../../components/FilteredBy";
import Check from "../../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../../components/toastify/Toastify";
import { PREVENTATIVE_TASK_FILTERS } from "../../../../../constants/PreferenceKeys";
import { MONTH, statusColorTheme } from "../../../../../constants/constants";
import { PREVENTATIVE_GENERATOR_PAGE } from "../../../../../constants/routes";
import ModalViewer from "../../../ModalViewer";
import { MODE, MONTH_OPTIONS, STATUS } from "../../constants";
import { PreventativeTaskContext } from "../PreventativeTaskProvider";
import { deletePreventativeTask } from "../PreventativeTaskRepository";
import ExportForm from "./ExportForm";
import PreventativeListFilter from "./PreventativeListFilter";
import PreventativeTaskForm from "./PreventativeTaskForm";

const PreventiveTaskList = () => {
  const preventativeContext = useContext(PreventativeTaskContext);
  const {
    pagination,
    filterParams,
    preventativeTasks,
    plants,
    engineers,
    preventativeTaskLoading,
    handleApplyFilter,
    loadPreventativeTasks,
    showFilter,
    sfState,
    isFilterApplied,
    archiveTask,
    unarchiveTask,
  } = preventativeContext;
  const [sm, updateSm] = useState(false);
  const [mode, setMode] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [exportLoading] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggle = () => setonSearch(!onSearch);

  const focusSearchRef = useRef();

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  const onSuccessfulModal = () => {
    loadPreventativeTasks();
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, taskText: onSearchText });
  };
  useEffect(() => {
    const filters = localStorage.getItem(PREVENTATIVE_TASK_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
      });
    }
  }, []);

  const currentItems = preventativeTasks?.items || [];

  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Preventative Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Preventative Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId)
      .then((res) => {
        successToast({ description: "Preventative Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Preventative Task" });
      });
  };

  const viewTask = (id) => {
    setMode(MODE.VIEW);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const editTask = (id) => {
    setMode(MODE.EDIT);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeTask(taskId);
      onSuccessfulModal();
      setIsOpen(false);
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Task" });
    }
  };
  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const getPlantName = (plantId) => {
    const plantData = plants.find((p) => p.value === plantId);
    return plantData?.label || "";
  };
  const getEngineerName = (plantId) => {
    const engineer = engineers.find((p) => p.value === plantId);
    return engineer?.label || "";
  };
  const handleDeleteConfirmation = (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };
  const getComponent = () => {
    return (
      <PreventativeTaskForm
        mode={mode}
        setMode={setMode}
        currentTaskId={currentTaskId}
        handleDeleteConfirmation={handleDeleteConfirmation}
        setIsOpen={setIsOpen}
      />
    );
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };

  const assignedEngineerSelectedData = filterParams.selectedAssignedEngineer.map((item, key) => {
    return engineers?.[engineers?.findIndex((status) => status.value === item)];
  });
  const monthSelectedData = filterParams.selectedMonthFilter.map((item, key) => {
    return MONTH_OPTIONS?.[MONTH_OPTIONS?.findIndex((status) => status.value === item)];
  });

  const plantSelectedData = filterParams.selectedPlant.map((item, key) => {
    return plants?.[plants?.findIndex((status) => status.value === item)];
  });
  const selectYear = filterParams.taskYear?.split("/")[0];

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Preventative Maintenance Ticket
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {preventativeTasks?.pagination?.totalCount || 0} preventative tickets.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedTaskStatus} title={"Status"} isMap={false} isStr={false} />
              <FilteredByList
                data={assignedEngineerSelectedData}
                title={"Assigned Engineer"}
                isMap={true}
                isStr={false}
              />
              <FilteredByList data={plantSelectedData} title={"Plant"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.startDate} title={"Start Date"} isMap={false} isStr={true} />
              <FilteredByList data={monthSelectedData} title={"Month"} isMap={true} isStr={false} />
              <FilteredByList data={selectYear} title={"Year"} isNotMap={true} isMap={false} isStr={true} />

              <FilteredByList data={filterParams.resolvedDate} title={"Resolved Date"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.taskText} title={"Searched by"} isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3 d-inline-block">
                  <li>
                    <Button
                      disabled={preventativeTasks?.pagination?.totalCount === 0}
                      className="btn btn-white btn-outline-light"
                      type="export"
                      isLoading={exportLoading}
                      onClick={createExport}
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </Button>
                  </li>
                  <Check permission={["manageTickets"]}>
                    <li>
                      <a
                        href={PREVENTATIVE_GENERATOR_PAGE}
                        // onClick={(ev) => {
                        //     ev.preventDefault();
                        // }}
                        className="btn btn-primary "
                      >
                        <Icon name="plus"></Icon>
                        <span>Generate Preventative Task</span>
                      </a>
                    </li>
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          {
            <div className="card-inner position-relative card-tools-toggle ">
              <div className="card-title-group ">
                <div className="card-tools"></div>
                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                          {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                          <Icon name="filter-alt"></Icon>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, taskText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by Ticket Name,status and Plant Name.enter to search"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                        if (e.keyCode === 27) {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }
                      }}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
          <DataTableBody compact className={"overflow-auto"}>
            <DataTableHead>
              <DataTableRow>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                        ? handleNameSort("DESC", "title")
                        : handleNameSort("ASC", "title");
                    }}
                  >
                    Ticket Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "title");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "title");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "title"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Ticket Identifier
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "identifier"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "code"
                        ? handleNameSort("DESC", "code")
                        : handleNameSort("ASC", "code");
                    }}
                  >
                    Code
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "code");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "code"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "code");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "code"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "frequency"
                        ? handleNameSort("DESC", "frequency")
                        : handleNameSort("ASC", "frequency");
                    }}
                  >
                    Frequency
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "frequency");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "frequency"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "frequency");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "frequency"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    Status
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "startedAt"
                        ? handleNameSort("DESC", "startedAt")
                        : handleNameSort("ASC", "startedAt");
                    }}
                  >
                    Start Date
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "startedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "startedAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "startedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "startedAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "resolvedAt"
                        ? handleNameSort("DESC", "resolvedAt")
                        : handleNameSort("ASC", "resolvedAt");
                    }}
                  >
                    Resolved Date
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "resolvedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "resolvedAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "resolvedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "resolvedAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "assignedToIds"
                        ? handleNameSort("DESC", "assignedToIds")
                        : handleNameSort("ASC", "assignedToIds");
                    }}
                  >
                    Assigned To
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "assignedToIds");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "assignedToIds"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "assignedToIds");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "assignedToIds"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plantId"
                        ? handleNameSort("DESC", "plantId")
                        : handleNameSort("ASC", "plantId");
                    }}
                  >
                    Plant Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "plantId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plantId"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "plantId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "plantId"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "months"
                        ? handleNameSort("DESC", "months")
                        : handleNameSort("ASC", "months");
                    }}
                  >
                    Months
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "months");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "months"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "months");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "months"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "year"
                        ? handleNameSort("DESC", "year")
                        : handleNameSort("ASC", "year");
                    }}
                  >
                    Year
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "year");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "year"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "year");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "year"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>
            <Sidebar toggleState={showFilter}>
              <PreventativeListFilter isPlantName={false} plants={plants} engineers={engineers} />
            </Sidebar>

            {preventativeTaskLoading ? (
              <></>
            ) : (
              currentItems.map((item, i) => {
                return (
                  <DataTableItem key={`${item.id}-preventative-masters`}>
                    <DataTableRow>
                      <div
                        className="user-card"
                        onClick={() => {
                          viewTask(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.title}
                            style={{
                              width: "90px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item.title || "-"}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.identifier}
                        style={{
                          width: "90px",
                          overflow: "hidden",
                          display: "inline-block",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item?.identifier || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.code}
                        style={{
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item?.code || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.frequency}
                        style={{
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item?.frequency || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <Badge className="text-capitalize" style={statusColorTheme[item.status]} pill>
                        {STATUS.find((d) => d.value === item.status).label || "-"}
                      </Badge>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item?.startedAt ? format(new Date(item?.startedAt || null), "dd/MM/yyyy") : "-"} </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      {item?.resolvedAt
                        ? format(new Date(item.resolvedAt ? item.resolvedAt : null), "dd/MM/yyyy")
                        : "-"}
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span
                        data-toggle="tooltip"
                        title={item?.assignedToIds
                          ?.map((id, key) => {
                            return getEngineerName(id);
                          })
                          .join(", ")}
                        style={{
                          width: "120px",
                          overflow: "hidden",
                          display: "inline-block",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                      >
                        {item?.assignedToIds
                          ?.map((id, key) => {
                            return getEngineerName(id);
                          })
                          .join(", ") || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{getPlantName(item.plantId) || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{MONTH[item.months] || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{item.year || "-"}</span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <Check permission={["manageTickets"]}>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <React.Fragment>
                                    <li
                                      /* className="nk-tb-action-hidden" */ onClick={() => {
                                        editTask(item.id);
                                      }}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit Task</span>
                                      </DropdownItem>
                                    </li>
                                    {item.isArchived ? (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => handleUnArchive(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#unarchive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="unarchive"></Icon>
                                            <span>UnArchive Task</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => handleArchive(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#archive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="archive"></Icon>
                                            <span>Archive Task</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                    <li className="divider"></li>
                                    <li
                                      /* className="nk-tb-action-hidden" */ onClick={() =>
                                        handleDeleteConfirmation(item.id)
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Delete Task</span>
                                      </DropdownItem>
                                    </li>
                                  </React.Fragment>
                                </ul>
                              </DropdownMenu>
                            </Check>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {preventativeTaskLoading && <Skeleton count={10} className="w-100" />}
          <div className="card-inner">
            {preventativeTasks.pagination && preventativeTasks.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={preventativeTasks.pagination.totalCount}
                paginate={preventativeContext.updateShowListPage}
                currentPage={preventativeTasks.pagination.currentPage}
                paginatePage={preventativeContext.updateShowListSize}
              />
            ) : (
              !preventativeTaskLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        <ModalViewer title="Preventative Task" isOpen={isOpen} toggleModal={toggleModal} component={getComponent()} />
        <ModalViewer
          title=" Preventative Maintenance - Select the Export Fields"
          isOpen={isExpotModalOpen}
          size="md"
          toggleModal={toggleExportModal}
          component={getExportComponent()}
        />
      </Block>
    </React.Fragment>
  );
};
export default PreventiveTaskList;
