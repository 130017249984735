import React, { useState } from "react";
import { errorToast, successToast } from "../../components/toastify/Toastify";
import useBoolean from "../../hooks/useBoolean";
import {
  createEquipmentAPI,
  createStockTransactionAPI,
  deleteEquipmentAPI,
  exportEquipmentAPI,
  getAllTask,
  getEquipmentAPI,
  getEquipmentAllOptions,
  getEquipmentFilterOptions,
  getEquipmentList,
  getStockTransactionAPI,
  updateEquipmentAPI,
} from "./EquipmentAndSpareRepository";

export const EquipmentAndSpareContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
  supplier: [],
  category: [],
  searchText: "",
  plant: [],
  ownership: "",
  isKeyComponent: "",
  status: "",
  sortingOrder: "",
  sortingField: "",
  table: "",
};

export const EquipmentAndSpareProvider = (props) => {
  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));
  const [equipmentList, setEquipmentList] = useState();
  const [isEquipmentListLoading, ielState] = useBoolean(false);
  const [isLoading, ilState] = useBoolean(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [maintenanceTicketOptions, setMaintenanceTicketOptions] = useState({});

  const [currentEquipment, setCurrentEquipment] = useState({});
  const [currentEquipmentList, setCurrentEquipmentList] = useState({});
  const [showFilter, sfState] = useBoolean(false);
  const [isExportLoading, iexlState] = useBoolean(false);
  const [isAddTransactionLoading, iatState] = useBoolean(false);
  const [isTransactionLoading, itState] = useBoolean(false);
  const [deleteData, handleDeleteData] = useState(false);
  const [transactions, setTransactions] = useState();
  const [transactionFilters, setTransactionFilters] = useState({});
  const [transactionPagination, setTransactionPagination] = useState(Object.assign({}, initialPaginationState));
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const getFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      supplier,
      category,
      plant,
      searchText,
      isKeyComponent,
      ownership,
      status,
      sortingField,
      sortingOrder,
      table,
    } = filterParams;
    if (supplier.length) queryParams["supplier"] = supplier;
    if (category.length) queryParams["category"] = category;
    if (plant && plant.length) queryParams["plant"] = plant;
    if (ownership) queryParams["ownership"] = ownership;
    if (searchText) queryParams["searchText"] = searchText;
    if (status) queryParams["status"] = status;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (table) queryParams["table"] = table;

    return { ...queryParams, isKeyComponent };
  };

  const loadEquipmentList = async (plantId) => {
    ilState.on();

    try {
      const filterParams = getFilterParams();
      if (plantId) {
        filterParams["plant"] = plantId;
      }
      const response = await getEquipmentList(filterParams);
      setEquipmentList(response.equipmentAndSpareList);
    } catch (e) {
      // CAPTURE EXCEPTION
      console.log({ e });
    } finally {
      ilState.off();
    }
  };

  const getAllTaskOption = async () => {
    ielState.on();
    try {
      const response = await getAllTask();
      setMaintenanceTicketOptions(response);
    } catch (error) {
      throw error;
    } finally {
      ielState.on();
    }
  };

  const loadFilterOptions = async () => {
    try {
      const response = await getEquipmentFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    }
  };

  const deleteEquipment = async (id) => {
    try {
      await deleteEquipmentAPI(id);
      loadEquipmentList();
    } catch (e) {
      throw e;
    }
  };

  const createEquipment = async (params) => {
    try {
      ilState.on();
      await createEquipmentAPI(params);
      successToast({ description: "Successfully Created" });
    } catch (e) {
      console.log("ee", e);
      errorToast({ description: `Error, ${e.message}` });
    } finally {
      ilState.off();
    }
  };

  const loadEquipment = async (id) => {
    try {
      ilState.on();

      const response = await getEquipmentAPI(id);
      setCurrentEquipment(response.inventory);
    } catch (e) {
      throw e;
    } finally {
      //
      ilState.off();
    }
  };

  const updateEquipment = async (equipmentId, params) => {
    try {
      ilState.on();
      await updateEquipmentAPI(equipmentId, params);
      successToast({ description: "Successfully Updated" });
    } catch (e) {
      errorToast({ description: `Error, ${e.message}` });
    } finally {
      ilState.off();
    }
  };

  const exportEquipment = async (equipmentId, params) => {
    try {
      iexlState.on();
      const res = await exportEquipmentAPI(equipmentId, params);
      return res;
    } catch (e) {
      console.log("ee", e);
    } finally {
      iexlState.off();
    }
  };

  const loadTransaction = async (params) => {
    try {
      itState.on();
      const filterParams = { ...transactionPagination, ...transactionFilters, ...params };
      const res = await getStockTransactionAPI(filterParams);
      setTransactions(res.stockTransactions);
    } catch (e) {
      console.log("ee", e);
    } finally {
      itState.off();
    }
  };

  const createStockTransaction = async (params) => {
    try {
      iatState.on();
      await createStockTransactionAPI(params);
      loadEquipmentList();
    } catch (e) {
      console.log("ee", e);
    } finally {
      iatState.off();
    }
  };

  const loadEquipmentOptions = async (params) => {
    try {
      const response = await getEquipmentAllOptions(params);
      setEquipmentOptions(response);
    } catch (e) {
      // HANDLE ERROR
    }
  };

  const checkIsFilterApplied = () => {
    if (
      filterParams?.category?.length !== 0 ||
      filterParams?.status ||
      filterParams?.isKeyComponent ||
      filterParams?.ownership ||
      filterParams?.supplier?.length !== 0
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();
  const resetEquipmentListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const mContext = {
    equipmentList,
    isEquipmentListLoading,
    loadEquipmentList,
    filterParams,
    handleApplyFilter,
    filterOptions,
    loadFilterOptions,
    deleteEquipment,
    pagination,
    isLoading,
    handleDeleteData,
    deleteData,
    createEquipment,
    loadEquipment,
    setCurrentEquipmentList,
    currentEquipmentList,
    currentEquipment,
    updateEquipment,
    showFilter,
    sfState,
    isFilterApplied,
    resetEquipmentListFilter,
    isExportLoading,
    exportEquipment,
    isAddTransactionLoading,
    createStockTransaction,
    isTransactionLoading,
    loadTransaction,
    transactions,
    transactionFilters,
    transactionPagination,
    getAllTaskOption,
    setTransactionFilters,
    setTransactionPagination,
    loadEquipmentOptions,
    maintenanceTicketOptions,
    equipmentOptions,
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
    updateShowSizePage: (size) => setPagination({ ...pagination, size }),
  };
  return <EquipmentAndSpareContext.Provider value={mContext}>{props.children}</EquipmentAndSpareContext.Provider>;
};

export const withEquipmentAndSpareProvider = (Container, containerProps) => (props) =>
  (
    <EquipmentAndSpareProvider>
      <Container {...containerProps} />
    </EquipmentAndSpareProvider>
  );
