import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ShowMoreText from "react-show-more-text";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { CURRENT_USER } from "../../constants/PreferenceKeys";
import useBoolean from "../../hooks/useBoolean";
import ModalViewer from "../../pages/maintenance/ModalViewer";
import { deleteComment, getComment, getCommentsList } from "../../repositories/CommentRepository";
import { findUpper } from "../../utils/Utils";
import { BlockTitle, Icon, UserAvatar } from "../Component";
import { confirmationSweetAlert } from "../sweetAlert/SweetAlert";
import { errorToast, successToast } from "../toastify/Toastify";
import CommentForm from "./CommentForm";

const initialPaginationState = {
  page: 1,
  size: 10,
};
const VIEW = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};
const Comments = (props) => {
  const { entityType, entityId, subModule } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [commentList, setCommentList] = useState([]);
  const [commentLoading, setCommentLoading] = useBoolean();
  const [currentCommentLoading, setCurrentCommentLoading] = useBoolean();
  const [comment, setComment] = useState();
  const [currentcomment, setCurrentComment] = useState();
  const [view, setView] = useState("");
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const getComments = async () => {
    setCommentLoading.on();
    try {
      const comment = await getCommentsList({ ...pagination, entityId, entityType });
      setCommentList(comment);
      setComment("");
      setCommentLoading.off();
    } catch (err) {
      //HANDLE ERROR WHILE GETTING COMMENT LIST
    }
  };
  const getCurrentComment = async () => {
    try {
      setCurrentCommentLoading.on();
      const commentS = await getComment(comment);
      setCurrentComment(commentS);
      setCurrentCommentLoading.off();
    } catch (err) {
      //HANDLE ERROR WHILE GETTING CURRENT COMMENT
    }
  };
  useEffect(() => {
    getComments();
  }, [pagination]);

  useEffect(() => {
    if (comment) getCurrentComment();
  }, [comment]);

  const onSuccessfulModal = () => {
    toggleModal();
    getComments();
  };
  const getComponent = () => {
    if (view === VIEW.CREATE) {
      return <CommentForm onSuccessfulModal={onSuccessfulModal} entityId={entityId} entityType={entityType} />;
    } else if (view === VIEW.EDIT) {
      return (
        !currentCommentLoading && (
          <CommentForm commentId={comment} currentcomment={currentcomment} onSuccessfulModal={onSuccessfulModal} />
        )
      );
    }
  };
  const handleDeleteCorrectiveSubtask = async (commentId) => {
    deleteComment(commentId)
      .then((res) => {
        successToast({ description: "Comment Deleted Successfully" });
        getComments();
      })
      .catch((e) => {
        errorToast({ description: "Error happened while Deleting Comment" });
      });
  };

  const handleDeleteConfirmation = async (commentId) => {
    confirmationSweetAlert({
      id: commentId,
      handleConfirmation: handleDeleteCorrectiveSubtask,
    });
  };

  const user = localStorage.getItem(CURRENT_USER);
  const currentUser = JSON.parse(user);

  return (
    <>
      <div className="d-flex justify-content-between mb-3 mt-2">
        <div className="d-flex align-items-center">
          <BlockTitle tag="h6">Comments</BlockTitle>
        </div>
        {commentList?.pagination?.totalCount < 1 && subModule === "edit" && (
          <div>
            <Button
              color="primary"
              className="ml-3"
              onClick={() => {
                toggleModal();
                setComment("");
                setView(VIEW.CREATE);
              }}
            >
              <Icon name="plus" />
              <span className="ml-1">Add Comment</span>
            </Button>
          </div>
        )}
        <ModalViewer size="sm" title="Comment" isOpen={isOpen} toggleModal={toggleModal} component={getComponent()} />
      </div>
      {commentList?.pagination?.totalCount ? (
        commentList?.items.map((item, key) => {
          return (
            <>
              {" "}
              <div key={`comment-${key.id}`} className="d-flex justify-content-between my-2">
                <div className="d-flex ">
                  <UserAvatar
                    theme={"primary"}
                    className="sm"
                    text={findUpper(`${item?.user?.firstName} ${item?.user?.lastName}`)}
                  />
                  <span className="ml-2 font-weight-bold align-center">{`${item?.user?.firstName} ${item?.user?.lastName}`}</span>
                </div>
                <div className="text-muted d-flex align-center">
                  {currentUser?.id === item?.userId && (
                    <div className="mr-1">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <React.Fragment>
                                  <li
                                    onClick={() => {
                                      toggleModal();
                                      setComment(item.id);
                                      setView(VIEW.EDIT);
                                    }}
                                  >
                                    <DropdownItem tag="a" href="#edit">
                                      <Icon name="edit-alt-fill"></Icon>
                                      <span>Edit Comment</span>
                                    </DropdownItem>
                                  </li>
                                  {
                                    <>
                                      <li className="divider"></li>
                                      <li onClick={() => handleDeleteConfirmation(item.id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#delete"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete Comment</span>
                                        </DropdownItem>
                                      </li>
                                      <li className="divider"></li>
                                    </>
                                  }
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                  )}
                  <span>{format(new Date(item?.updated_at || null), "dd/MM/yyyy HH:mm")}</span>
                </div>
              </div>
              <div className="row ">
                <div className="col-12 px-5 overflow-hidden">
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css pl-2"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    {item.description}
                  </ShowMoreText>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <span>No Comments</span>
      )}
      {commentLoading && <Skeleton count={1} height={100} className="w-100" />}
    </>
  );
};

export default Comments;
