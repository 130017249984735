import React, { useEffect, useState } from "react";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, Row, RSelect } from "../../../components/Component";
import { UserManagementContext } from "../UserManagementProvider";
import { sortOption } from "../../../utils/Utils";

const UserListFilter = () => {
  const userManagementContext = React.useContext(UserManagementContext);
  const { filterOptions } = userManagementContext;
  const { filterParams, handleApplyFilter, sfState, showFilter } = userManagementContext;

  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  const [tablesm, updateTableSm] = useState(false);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const handleReset = () => {
    userManagementContext.resetUserListFilter();
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);

    updateTableSm(false);
    // checkIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleRoleFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedUserRole: selectedOption.value });
  };

  const handleStateFilterSelection = (selectedOption) => {
    handleFilterSelection({ selectedUserStatus: selectedOption.value });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const selectedRoleOption = filterOptions.userRoles?.find((o) => o.value === selectedFilter.selectedUserRole) || null;

  const selectedUserStatusOption =
    filterOptions.userStatusses?.find((o) => o.value === selectedFilter.selectedUserStatus) || null;

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100 ">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Option</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Role</label>
                <RSelect
                  options={sortOption(filterOptions.userRoles)}
                  placeholder="Any Role"
                  onChange={handleRoleFilterSelection}
                  value={selectedRoleOption}
                />
              </FormGroup>
            </Col>
            <Col size="12">
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={sortOption(filterOptions.userStatusses)}
                  placeholder="Any Status"
                  onChange={handleStateFilterSelection}
                  value={selectedUserStatusOption}
                />
              </FormGroup>
            </Col>
            <Col size="6">
              <div className="custom-control custom-control-sm custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchived"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchived">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className={"d-flex justify-content-between"} size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>

        {/* <ul className="link-check">
            <li>
              <span>Order</span>
            </li>
            <li className={sort === "dsc" ? "active" : ""}>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setSortState("dsc");
                  sortFunc("dsc");
                }}
              >
                DESC
              </DropdownItem>
            </li>
            <li className={sort === "asc" ? "active" : ""}>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setSortState("asc");
                  sortFunc("asc");
                }}
              >
                ASC
              </DropdownItem>
            </li>
          </ul> */}
      </div>
    </UncontrolledDropdown>
  );
};

export default UserListFilter;
