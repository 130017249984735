/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import { withPreventativeTaskProvider, PreventativeTaskContext } from "../PreventativeTaskProvider";
import PreventativeTaskList from "../components/PreventativeTaskList";
import { useLocation, useHistory } from "react-router-dom";

const PreventiveMaintenanceContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");
  const plantId = new URLSearchParams(location).get("plantId");
  const { loadPreventativeTasks, loadOptions, dashboardFilterStatus } = useContext(PreventativeTaskContext);
  const { pagination, filterParams } = useContext(PreventativeTaskContext);

  const searchtext = filterParams.taskText;

  useEffect(() => {
    if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
      const queryParams = new URLSearchParams(location);
      if (queryParams.has("status")) {
        queryParams.delete("status");
        history.replace({
          search: queryParams.toString(),
        });
      }
      if (queryParams.has("plantId")) {
        queryParams.delete("plantId");
        history.replace({
          search: queryParams.toString(),
        });
      }
      loadPreventativeTasks();
    }
  }, [pagination, filterParams]);

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (status && !dashboardFilterStatus) {
      loadPreventativeTasks(parseInt(plantId), status, dashboard);
    }
  }, [pagination, searchtext, status]);

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Task" />
      <Content>
        <PreventativeTaskList />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeTaskProvider(PreventiveMaintenanceContainer);
