import { format } from "date-fns";
import React, { useContext } from "react";
import { Col, FormGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  Icon,
  Button,
  BlockTitle,
} from "../../../../../components/Component";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider";
import Attachments from "../../../../../components/attachments/Attachment";
import { TASK } from "../../../../../constants/PreferenceKeys";
import { CorrectiveContext } from "../../CorrectiveProvider";

const ViewCorrectiveSubTask = ({ sm, updateSm, setIsOpen, editTask, currentSubTask, currentUser }) => {
  const { filterOptions } = useContext(MaintenanceContext);
  const { toggleModal } = useContext(CorrectiveContext);

  let priority = {};
  let status = {};
  let slas = {};
  let assetCategories = {};

  for (let i = 0; i < filterOptions.taskStatuses?.length; i++) {
    status[filterOptions.taskStatuses[i].value] = filterOptions.taskStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.priorityStatuses?.length; i++) {
    priority[filterOptions.priorityStatuses[i].value] = filterOptions.priorityStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.slas?.length; i++) {
    slas[filterOptions.slas[i].value] = filterOptions.slas[i].label;
  }
  for (let i = 0; i < filterOptions.assetCategories?.length; i++) {
    assetCategories[filterOptions.assetCategories[i].value] = filterOptions.assetCategories[i].label;
  }

  if (!currentSubTask) return <Spinner />;

  return (
    <div className="overflow-auto h-max-450px">
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h5">{currentUser}'s follow-up task</BlockTitle>
              <BlockDes>{/* <p>Basic info.</p> */}</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-end">
              {/* <Link to={CLIENT_MANAGEMENT_EDIT_PAGE.replace(":clientId", currentSubTask.id)}> */}
              {/* <Button color="danger"
                                onClick={() => {
                                    handleDeleteConfirmation(currentSubTask.id)
                                }}>
                                <Icon name="na" />
                                <span>Delete</span>
                            </Button>
                            <Button color="primary"
                                className={'ml-2'}
                                onClick={() => {
                                    editTask(currentSubTask.id)
                                }}>
                                <Icon name="edit" />
                                <span>Edit</span>
                            </Button> */}
            </BlockHeadContent>
            <BlockHeadContent className="align-self-start d-lg-none">
              <Button
                className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            {/* <div className="data-head">
                            <h6 className="overline-title">Basics</h6>
                        </div> */}
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Ticket Name</span>
                <span className="data-value">{currentSubTask?.title || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Status</span>
                <span className="data-value">{status[currentSubTask?.status] || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Plant Name</span>
                <span className="data-value">{currentSubTask?.plant?.name || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Start Date</span>
                <span className="data-value">
                  {currentSubTask?.startedAt ? format(new Date(currentSubTask?.startedAt || null), "dd/MM/yyyy") : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Resolved Date</span>
                <span className="data-value">
                  {currentSubTask?.resolvedAt
                    ? format(new Date(currentSubTask.resolvedAt ? currentSubTask.resolvedAt : null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Priority</span>
                <span className="data-value">{currentSubTask ? priority[currentSubTask?.priority] : "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">SLA</span>
                <span className="data-value">{currentSubTask ? slas[currentSubTask.slaId] : "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Asset Category</span>
                <span className="data-value">
                  {currentSubTask ? assetCategories[currentSubTask.assetCategoryId] : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Assigned Engineer</span>
                <span className="data-value">
                  {currentSubTask?.assignedEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Field Engineer</span>
                <span className="data-value">
                  {currentSubTask?.fieldEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Labour Hours</span>
                <span className="data-value">{currentSubTask?.labourHours || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Parent Task</span>
                <span onClick={() => toggleModal()} className="data-value">
                  {currentSubTask?.parentTask?.title || "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Description</span>
                <span className="data-value text-break">{currentSubTask?.description || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            {
              <Col lg="12 pt-2">
                <FormGroup className="form-group">
                  <div className="form-control-wrap">
                    <Attachments
                      module={TASK}
                      id={currentSubTask.id}
                      attachments={currentSubTask.attachment}
                      subModule={"view"}
                    />
                  </div>
                </FormGroup>
              </Col>
            }
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              CreatedAt: {format(new Date(currentSubTask?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              UpdatedAt: {format(new Date(currentSubTask?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
        </Block>
      </Block>
    </div>
  );
};
export default ViewCorrectiveSubTask;
