import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockHead,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../../../../components/Component";
import { confirmationSweetAlert } from "../../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../../components/toastify/Toastify";
import useBoolean from "../../../../../hooks/useBoolean";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider";
import ModalViewer from "../../../ModalViewer";
import { CorrectiveContext } from "../../CorrectiveProvider";
import { deleteSubtaskAPI, getCorrectiveSubtaskList, getSubtaskAPI } from "../../CorrectiveRepository";
import SubtaskForm from "./SubTaskForm";
import ViewCorrectiveSubTask from "./SubtaskView";
import Check from "../../../../../components/check_roles/check";

const STATUSES = {
  OPEN: "Open",
  IN_PROGRESS: "In Progress",
  READY_FOR_APPROVAL: "Ready for Approval",
  COMPLETED: "Completed",
};
const initialPaginationState = {
  page: 1,
  size: 5,
};

const VIEW = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  EDIT: "EDIT",
};
const SubtaskList = (props) => {
  const { parentId } = props;
  const { filterOptions } = useContext(MaintenanceContext);
  const { toggleModal, isOpen, setIsOpen, loadCorrectiveList } = useContext(CorrectiveContext);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [subtaskList, setSubtaskList] = useState([]);
  const [correctiveSubtaskLoading, setCorrectiveSubtaskLoading] = useBoolean();
  const [currentSubtaskLoading, setCurrentSubtaskLoading] = useBoolean();
  const updateShowListPage = (page) => setPagination({ ...pagination, page });
  const updateShowListSize = (size) => setPagination({ ...pagination, size });
  const [view, setView] = useState("");
  const [task, setTask] = useState();
  const [currentSubTask, setCurrentSubTask] = useState({});

  let engineers = {};

  const getSubtask = async () => {
    setCorrectiveSubtaskLoading.on();
    try {
      const subtask = await getCorrectiveSubtaskList({ ...pagination, parentId });
      setSubtaskList(subtask.correctiveSubtaskList);
      setTask("");
      setCorrectiveSubtaskLoading.off();
    } catch (err) {
      errorToast({ description: `${err}` });
    }
  };
  React.useEffect(() => {
    getSubtask();
  }, [parentId]);

  const getCurrentSubTask = async () => {
    try {
      setCurrentSubtaskLoading.on();
      const subtask = await getSubtaskAPI(task);
      setCurrentSubTask(subtask);
      setCurrentSubtaskLoading.off();
    } catch (err) {
      // console.log({ err })
    }
  };
  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  useEffect(() => {
    getSubtask();
  }, [pagination]);

  useEffect(() => {
    if (task) getCurrentSubTask();
  }, [task]);

  const onSuccessfulModal = () => {
    toggleModal();
    loadCorrectiveList();
    getSubtask();
  };
  const handleDeleteCorrectiveSubtask = async (taskId) => {
    deleteSubtaskAPI(taskId)
      .then((res) => {
        successToast({ description: "Corrective Sub task is deleted successfully" });
        getSubtask();
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Corrective Sub task" });
      });
  };
  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteCorrectiveSubtask,
    });
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        !currentSubtaskLoading && (
          <ViewCorrectiveSubTask
            currentUser={props.currentUser}
            taskId={task}
            setIsOpen={setIsOpen}
            currentSubTask={currentSubTask.task}
            parentId={props.parentId}
            onSuccessfulModal={onSuccessfulModal}
          />
        )
      );
    } else if (view === VIEW.CREATE) {
      return (
        <SubtaskForm
          currentUser={props.currentUser}
          onSuccessfulModal={onSuccessfulModal}
          parentId={props.parentId}
          plantId={props.plantId}
        />
      );
    } else if (view === VIEW.EDIT) {
      return (
        !currentSubtaskLoading && (
          <SubtaskForm
            currentUser={props.currentUser}
            taskId={task}
            currentSubTask={currentSubTask.task}
            parentId={props.parentId}
            onSuccessfulModal={onSuccessfulModal}
          />
        )
      );
    }
  };
  return (
    <React.Fragment>
      <BlockHead size="sm">
        <div className="d-flex justify-content-end">
          <Check permission={["manageTickets"]}>
            <Button
              color="primary"
              size="md"
              onClick={() => {
                toggleModal();
                setTask("");
                setView(VIEW.CREATE);
              }}
            >
              <Icon name="plus" />
              <span> Add Follow-up Task</span>
            </Button>
          </Check>
        </div>
      </BlockHead>

      <Block>
        <DataTable className="card-stretch">
          <DataTableBody compact className={"mt-2"}>
            <DataTableHead>
              <DataTableRow>
                <span className="sub-text">Ticket Name</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Code</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Status</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text">Start Date</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Resolved Date</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text">Assigned To</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text">Plant Name</span>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>

            {/*Head*/}
            {subtaskList?.items?.length > 0 ? (
              subtaskList?.items?.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <div
                        className="user-card dropdown-toggle"
                        onClick={() => {
                          setView(VIEW.VIEW);
                          setTask(item.id);
                          toggleModal();
                        }}
                      >
                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.title}
                            style={{
                              width: "90px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item.title || "-"}
                          </span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow id="corrective-identifier" size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.identifier}
                        style={{
                          display: "inline-block",
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.identifier || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{STATUSES[item.status] || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>{item?.startedAt ? format(new Date(item?.startedAt || null), "dd/MM/yyyy") : "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>
                        {item?.resolvedAt
                          ? format(new Date(item.resolvedAt ? item.resolvedAt : null), "dd/MM/yyyy")
                          : "-"}
                      </span>
                    </DataTableRow>

                    <DataTableRow size="md">
                      <span>
                        {item?.assignedToIds
                          ?.map((id, key) => {
                            return engineers[id];
                          })
                          .join(", ") || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{item.plant?.name || "-"}</span>
                    </DataTableRow>
                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <React.Fragment>
                                  <li /* className="nk-tb-action-hidden" */ /* onClick={() => editTask(item.id)} */>
                                    <DropdownItem
                                      tag="a"
                                      href="#edit"
                                      onClick={(ev) => {
                                        setView(VIEW.EDIT);
                                        setTask(item.id);
                                        toggleModal();
                                      }}
                                    >
                                      <Icon name="edit-alt-fill"></Icon>
                                      <span>Edit Task</span>
                                    </DropdownItem>
                                  </li>
                                  {item.status !== "SUSPENDED" && (
                                    <>
                                      <li className="divider"></li>
                                      <li /* onClick={() => handleDeleteConfirmation(item.id)} */>
                                        <DropdownItem
                                          tag="a"
                                          href="#delete"
                                          onClick={(ev) => {
                                            handleDeleteConfirmation(item.id);
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete Task</span>
                                        </DropdownItem>
                                      </li>
                                      <li className="divider"></li>
                                    </>
                                  )}
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            ) : (
              <></>
            )}
          </DataTableBody>
          <div className="card-inner">
            {subtaskList.pagination && subtaskList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={subtaskList.pagination.totalCount}
                paginate={updateShowListPage}
                currentPage={subtaskList.pagination.currentPage}
                paginatePage={updateShowListSize}
              />
            ) : (
              !correctiveSubtaskLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {correctiveSubtaskLoading && <Skeleton count={1} height={100} className="w-100" />}
      </Block>
      {
        <ModalViewer
          title="Corrective follow-up task"
          isOpen={isOpen}
          toggleModal={toggleModal}
          component={getComponent()}
        />
      }
    </React.Fragment>
  );
};

export default SubtaskList;
