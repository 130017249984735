import React, { useState } from "react";
import {
  getPreventativeMasterLists,
  unArchivePreventativeMasterListAPI,
  archivePreventativeMasterListAPI,
} from "./PreventativeRepository";
import useBoolean from "../../../hooks/useBoolean";

export const PreventativeContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};
const initialFilterParams = {
  taskText: "",
  isArchiveSelected: false,
  sortingField: "",
  users: [],
  sortingOrder: "",
  createdAt: "",
};

export const PreventativeProvider = (props) => {
  const [preventativeMasterLists, setPreventativeMasterLists] = useState([]);

  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));

  //Loaders
  const [preventativeMasterListLoading, pmlState] = useBoolean(false);
  const [loadingTasks, tlState] = useBoolean(false);

  const [showFilter, sfState] = useBoolean(false);

  const loadPreventativeMasterLists = async () => {
    pmlState.on();
    try {
      const filterParams = getQueryParams();
      const response = await getPreventativeMasterLists(filterParams);
      setPreventativeMasterLists(response.preventativeMasterList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      pmlState.off();
    }
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const getQueryParams = () => {
    const queryParams = {
      ...pagination,
      ...filterParams,
    };
    return queryParams;
  };

  //ARCHIVE PreventativeMaster TASK
  const archivePreventativeMasterList = async (id) => {
    try {
      tlState.on();
      await archivePreventativeMasterListAPI(id);
      loadPreventativeMasterLists();
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  //UNARCHIVE PreventativeMaster TASK
  const unArchivePreventativeMasterList = async (id) => {
    try {
      tlState.on();
      await unArchivePreventativeMasterListAPI(id);
      loadPreventativeMasterLists();
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  const resetListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const checkIsFilterApplied = () => {
    if (filterParams.isArchiveSelected) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    checkIsFilterApplied,
    isFilterApplied,
    showFilter,
    sfState,
    pagination,
    preventativeMasterLists,
    setPreventativeMasterLists,
    filterParams,
    preventativeMasterListLoading,
    loadPreventativeMasterLists,
    handleApplyFilter,
    getQueryParams,
    resetListFilter,
    loadingTasks,
    archivePreventativeMasterList,
    unArchivePreventativeMasterList,
    updateShowListSize: (size) => setPagination({ ...pagination, size }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <PreventativeContext.Provider value={mContext}>{props.children}</PreventativeContext.Provider>;
};

export const withPreventativeProvider = (Container, containerProps) => (props) =>
  (
    <PreventativeProvider>
      <Container {...containerProps} {...props} />
    </PreventativeProvider>
  );
