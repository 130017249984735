/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { PvPlantManagementContext, withPvPlantManagementProvider } from "../PvPlantManagementProvider";
import PvPLantList from "../components/PvPlantList";

const PvPlantManagementContainer = (props) => {
  const { loadPvPLantList, loadFilterOptions, loadAllUsers, loadAllClients } = useContext(PvPlantManagementContext);
  const { pagination, filterParams } = useContext(PvPlantManagementContext);

  useEffect(() => {
    loadPvPLantList();
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
    loadAllUsers();
    loadAllClients();
  }, []);
  return (
    <React.Fragment>
      <Head title="Brighter App | PV-Plant" />
      <Content>
        <PvPLantList />
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantManagementProvider(PvPlantManagementContainer);
