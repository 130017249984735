import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  getAssetCategoryList,
  updateAssetCategoryAPI,
  createAssetCategoryAPI,
  getAssetCategoryAPI,
  deleteAssetCategoryAPI,
  getAssetCategoryFilterListAPI,
} from "./AssetCategoryRepository";
export const AssetCategoryContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
  searchText: "",
  sortingOrder: "",
  sortingField: "",
  selecetedAssetCategories: [],
  createdAt:"",updatedAt:""
};

const initialFilterOptions = {
  assetCategories: [],
};

export const AssetCategoryProvider = (props) => {
  const [assetCategoryList, setAssetCategoryList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [currentViewAssetCategory, setCurrentViewAssetCategory] = React.useState();
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));

  //Loaders
  const [assetCategoryLoading, assetCategoryState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, sortingField, sortingOrder ,selecetedAssetCategories,createdAt,updatedAt} = filterParams;
    if (searchText) queryParams["assetCategorySearchText"] = searchText;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (selecetedAssetCategories) queryParams["assetCategory"] = selecetedAssetCategories;
    if (createdAt) queryParams["createdAt"] = createdAt;
    if (updatedAt) queryParams["updatedAt"] = updatedAt;
    return queryParams;
  };

  const loadAssetCategoryList = async () => {
    assetCategoryState.on();

    try {
      const filterParams = getListFilterParams();
      const response = await getAssetCategoryList(filterParams);
      setAssetCategoryList(response.assetCategoriesList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      assetCategoryState.off();
    }
  };

  //CREATE ASSET_CATEGORY
  const createAssetCategory = async (createParams) => {
    try {
      assetCategoryState.on();
      await createAssetCategoryAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      assetCategoryState.off();
    }
  };
  // VIEW ASSET_CATEGORY
  const loadAssetCategory = async (assetCategoryId) => {
    try {
      const response = await getAssetCategoryAPI(assetCategoryId);
      setCurrentViewAssetCategory(response.assetCategory);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  // UPDATE ASSET_CATEGORY
  const updateAssetCategory = async (assetCategoryId, updateParams) => {
    assetCategoryState.on();
    try {
      const response = await updateAssetCategoryAPI(assetCategoryId, updateParams);
      setCurrentViewAssetCategory(response.assetCategory);
    } catch (e) {
      throw e;
    } finally {
      assetCategoryState.off();
    }
  };

  // DELETE ASSET_CATEGORY
  const deleteAssetCategory = async (assetCategoryId) => {
    try {
      await deleteAssetCategoryAPI(assetCategoryId);
      loadAssetCategoryList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  //to get filter-options
  const loadFilterOptions = async () => {
    flState.on();

    try {
      const response = await getAssetCategoryFilterListAPI();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };
 //APPLY FILTER
 const checkIsFilterApplied = () => {
  if (
    filterParams.selecetedAssetCategories.length != 0 ||
    filterParams.createdAt ||
    filterParams.updatedAt 
  ) {
    return true;
  } else return false;
};

const isFilterApplied = checkIsFilterApplied();

  const resetAssetCategoryListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    assetCategoryList,
    pagination,
    loadAssetCategoryList,
    currentViewAssetCategory,
    assetCategoryLoading,
    deleteAssetCategory,
    createAssetCategory,
    updateAssetCategory,
    loadAssetCategory,
    handleApplyFilter,
    filterParams,
    filtersLoading,
    filterOptions,
    showFilter,
    sfState,
    isFilterApplied,
    loadFilterOptions,
    resetAssetCategoryListFilter,
    checkIsFilterApplied,
    updateShowListSize: (size) => setPagination({ ...pagination, size }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <AssetCategoryContext.Provider value={mContext}>{props.children}</AssetCategoryContext.Provider>;
};

export const withAssetCategoryProvider = (Container, containerProps) => (props) =>
  (
    <AssetCategoryProvider>
      <Container {...containerProps} {...props} />
    </AssetCategoryProvider>
  );
