import {
    CORRECTIVE_CREATE,
    CORRECTIVE_LIST_FILTER_OPTIONS_API,
    REMEDIAL_CREATE
} from '../constants/endpoints'
import apiService from '../services/ApiService'

export const getCorrectiveListFilterOptions = async filterParams => {
    const response = await apiService.get(CORRECTIVE_LIST_FILTER_OPTIONS_API)
    return response
}
export const createCorrectiveMaintenance = async params => {
    const response = await apiService.post(CORRECTIVE_CREATE, {
        body: params,
    })
    return response
}
export const createRemedialMaintenance = async params => {
    const response = await apiService.post(REMEDIAL_CREATE, {
        body: params,
    })
    return response
}