/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Content from "../../../layout/content/Content";
import { withClientManagementProvider } from "../ClientManagementProvider";
import ClientUserForm from "../components/ClientUserform";

const ClientManagementUserFormContainer = (props) => {
  const {
    setIsOpen,
    setUsers,
    users,
    toggleModal,
    userId,
    clientUserId,
    clientUserData,
    setClientUsers,
    setClientUserId,
    clientUsers,
    isUserEditMode,
  } = props;

  return (
    <Content>
      <ClientUserForm
        setIsOpen={setIsOpen}
        setUsers={setUsers}
        users={users}
        toggleModal={toggleModal}
        userId={userId}
        clientUserId={clientUserId}
        setClientUserId={setClientUserId}
        setClientUsers={setClientUsers}
        clientUserData={clientUserData}
        clientUsers={clientUsers}
        isUserEditMode={isUserEditMode}
      />
    </Content>
  );
};

export default withClientManagementProvider(ClientManagementUserFormContainer);
