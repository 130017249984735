/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { useParams } from "react-router-dom";
import ViewCorrective from "../components/ViewCorrective";
import { CorrectiveContext, withCorrectiveProvider } from "../CorrectiveProvider";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import { EquipmentAndSpareContext } from "../../../equipment_spare/EquipmentAndSpareProvider";

const CorrectiveViewContainer = (props) => {
  const { taskId } = useParams();
  const correctiveContext = useContext(CorrectiveContext);
  const { loadTask } = correctiveContext;
  const { loadFilterOptions } = useContext(MaintenanceContext);
  const { loadEquipment } = useContext(EquipmentAndSpareContext);

  useEffect(() => {
    if (taskId) loadTask(taskId);
  }, [taskId]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title={`${taskId} - Task`} />
      <Content>
        <ViewCorrective />
      </Content>
    </React.Fragment>
  );
};

export default withCorrectiveProvider(CorrectiveViewContainer);
