/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, BlockHeadContent, RSelect } from "../../../components/Component";
import classNames from "classnames";
import {
  PREVENTATIVE_SCHEDULE_VIEW,
  PVPLANT_MANAGEMENT_PAGE,
  PVPLANT_MANAGEMENT_PREVENTATIVE_SCHEDULE,
} from "../../../constants/routes";
import { Link, useHistory } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useContext, useEffect, useState } from "react";
import { ScheduleContext } from "../ScheduleProvider";
import { TaskTable } from "./TaskTable";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import ModalViewer from "../../maintenance/ModalViewer";
import "./datepicker.css";
import { sortOption } from "../../../utils/Utils";

const PreventativeScheduleForm = ({ plantId, preventativeScheduleId }) => {
  const history = useHistory();
  const scheduleContext = useContext(ScheduleContext);
  const {
    preventativeMasterListOptions,
    preventativeScheduleOptions,
    isLoading,
    preventativeTasks,
    setPreventativeTasks,
    getPreventativeMasterTask,
    getPreventativeScheduleTask,
    getPreventativeScheduleById,
  } = scheduleContext;

  const [currentPreventativeSchedule, setCurrentPreventativeSchedule] = useState({});
  const [isArchived, setIsArchived] = useState(false);
  const [plant, setPlant] = useState({});

  const defaultValues = {
    name: "",
    startDate: null,
    endDate: null,
    description: "",
    isArchived: false,
  };
  const createSchema = yup.object({
    name: yup.string().required(),
    startDate: yup.date().typeError("Start Date is Required").required(),
    endDate: yup
      .date()
      .typeError("End Date is Required")
      .min(yup.ref("startDate"), "End Date must be less than Start Date ")
      .required(),
    description: yup.string(),
    isArchived: yup.boolean(),
  });

  const taskFormRef = useForm({
    resolver: yupResolver(createSchema),
    defaultValues,
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = taskFormRef;
  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  useEffect(() => {
    const getSchedule = async () => {
      try {
        const res = await getPreventativeScheduleById(preventativeScheduleId);
        setCurrentPreventativeSchedule(res.preventativeSchedule);
        setPreventativeTasks(res.tasks);
        reset({
          name: res.preventativeSchedule.name || "",
          startDate: new Date(res.preventativeSchedule.startDate),
          endDate: res.preventativeSchedule.endDate ? new Date(res.preventativeSchedule.endDate) : null,
          description: res.preventativeSchedule.description || "",
          isArchived: res.preventativeSchedule.isArchived,
        });
        setIsArchived(res.preventativeSchedule.isArchived);
        setSelectedTask(res.tasks);
      } catch (err) {
        //handle err
      }
    };
    if (preventativeScheduleId) {
      getSchedule();
    }
  }, [preventativeScheduleId]);

  const getPlant = async () => {
    const res = await scheduleContext.getPlant(plantId);
    setPlant(res);
  };
  useEffect(() => {
    if (plantId) {
      getPlant();
    }
  }, [plantId]);

  const [active, setActive] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTask, setSelectedTask] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const onSubmit = async (formData) => {
    //call create preventative schedule
    let temp = selectedTask;
    if (selectedTask.some((data) => data.removeId)) {
      temp = selectedTask.map((task) => {
        if (task.removeId) {
          const { id, removeId, ...rest } = task;
          return { ...rest };
        } else {
          return { ...task };
        }
      });
    }
    const body = { ...formData, tasks: temp, plantId: plantId, isArchived };
    if (!preventativeScheduleId) {
      try {
        const response = await scheduleContext.createSchedule(body);
        if (response) {
          successToast({ description: "Preventative Schedule created" });
        }
      } catch (e) {
        //catch error
      } finally {
        // setConfirmModal(false);
      }
    } else {
      try {
        await scheduleContext.updatePreventativeScheduleById(preventativeScheduleId, body);
        successToast({ description: "Preventative Schedule updated" });
      } catch (e) {
        //catch error
      } finally {
        // setConfirmModal(false);
      }
    }
    history.push(
      preventativeScheduleId
        ? `${PREVENTATIVE_SCHEDULE_VIEW}`
            .replace(":plantId", plantId)
            .replace(":preventativeScheduleId", preventativeScheduleId)
        : `${PVPLANT_MANAGEMENT_PREVENTATIVE_SCHEDULE}`.replace(":plantId", plant.id)
    );
  };

  const getTaskList = (id) => {
    if (active === 1) {
      getPreventativeMasterTask(id);
    } else {
      getPreventativeScheduleTask(id);
    }
  };

  return (
    <>
      <Block size="lg">
        <BlockHeadContent className="mb-1"></BlockHeadContent>
        <PreviewCard>
          <div className="card-head">
            <h5 className="card-title">
              {preventativeScheduleId ? "Edit" : "Create"} Preventative Schedule for {plant.name}
            </h5>
          </div>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="4" md="4">
                <FormGroup>
                  <Label className="form-label" htmlFor="name">
                    <span style={{ color: "indianred" }}>&#42;</span>Name
                  </Label>
                  <div className="form-control-wrap">
                    <input {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && (
                    <span className="invalid" style={{ color: "red" }}>
                      Name is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="4" md="4">
                <FormGroup className="form-group">
                  <Label className="form-label" htmlFor="name">
                    <span style={{ color: "indianred" }}>&#42;</span>Start Date
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field }) => {
                        return (
                          <ReactDatePicker
                            ref={field.ref}
                            dateFormat="dd/MM/yyyy"
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control date-picker"
                          />
                        );
                      }}
                    />
                  </div>
                  {errors?.startDate && <span style={{ color: "red" }}>{errors?.startDate?.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="4" md="4">
                <FormGroup className="form-group">
                  <Label className="form-label" htmlFor="name">
                    <span style={{ color: "indianred" }}>&#42;</span>End Date
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field }) => {
                        return (
                          <ReactDatePicker
                            ref={field.ref}
                            dateFormat="dd/MM/yyyy"
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className="form-control date-picker"
                          />
                        );
                      }}
                    />
                  </div>
                  {errors?.endDate && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.endDate?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="6" lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Description
                  </Label>
                  <div className="form-control-wrap">
                    <textarea
                      {...register("description")}
                      name="description"
                      className="form-control form-control-sm"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <FormGroup style={{ zIndex: 0 }}>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="preventativeMaster"
                      name="preventativeMaster"
                      className="custom-control-input form-control"
                      onClick={(e) => {
                        if (!preventativeScheduleId) {
                          setSelectedOption(null);
                          setPreventativeTasks([]);
                          setSelectedTask([]);
                        }
                        setActive(1);
                      }}
                      checked={active === 1}
                    />
                    <label className="custom-control-label" htmlFor="preventativeMaster">
                      Preventative Master
                    </label>
                  </div>

                  <div className="custom-control custom-radio ml-md-5 mt-3 mt-md-0">
                    <input
                      type="radio"
                      id="preventativeSchedule"
                      name="preventativeSchedule"
                      className="custom-control-input form-control"
                      value="2"
                      onClick={(e) => {
                        if (!preventativeScheduleId) {
                          setSelectedOption(null);
                          setPreventativeTasks([]);
                          setSelectedTask([]);
                        }
                        setActive(2);
                      }}
                      checked={active === 2}
                    />
                    <label className="custom-control-label" htmlFor="preventativeSchedule">
                      Select from existing plant
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12}>
                <FormGroup>
                  <div className="form-control-wrap">
                    <RSelect
                      name="selectedOption"
                      id="selectedOption"
                      value={
                        selectedOption
                          ? active === 1
                            ? preventativeMasterListOptions.find((p) => p.id === selectedOption)
                            : preventativeScheduleOptions.find((p) => p.id === selectedOption)
                          : null
                      }
                      placeholder="Preventative Master List/Preventative Schedule List"
                      options={sortOption(active === 1 ? preventativeMasterListOptions : preventativeScheduleOptions)}
                      onChange={(o) => {
                        setSelectedOption(o.value);
                        getTaskList(o.value);
                        setSelectedTask([]);
                      }}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {preventativeTasks.length ? (
              <TaskTable
                preventativeTasks={preventativeTasks}
                setPreventativeTask={setPreventativeTasks}
                setSelectedTask={setSelectedTask}
                selectedTask={selectedTask}
              />
            ) : null}
            <Row className="mt-3">
              <Col size="6">
                <div className="custom-control custom-control-sm custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input form-control"
                    id="isArchived-form"
                    onChange={(e) => {
                      setIsArchived(e.target.checked);
                    }}
                    checked={isArchived}
                  />
                  <label className="custom-control-label" htmlFor="isArchived-form">
                    Archived
                  </label>
                </div>
              </Col>
            </Row>
            <Col xl="12" className="d-flex justify-content-end mt-3">
              <Link
                to={`${PVPLANT_MANAGEMENT_PREVENTATIVE_SCHEDULE}`.replace(":plantId", plant.id)}
                style={{ color: "white" }}
              >
                <Button className="mr-1" size="lg" color="danger">
                  Cancel
                </Button>
              </Link>
              <Button color="primary" size="lg" isLoading={isLoading} onClick={handleSubmit(onSubmit)}>
                Confirm
              </Button>
            </Col>
          </Form>
        </PreviewCard>
      </Block>
    </>
  );
};
export default PreventativeScheduleForm;
