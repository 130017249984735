import { useState } from "react"
import { Button, FormGroup } from "reactstrap"
import { createComment, updateComment } from "../../repositories/CommentRepository"
import { errorToast, successToast } from "../toastify/Toastify"

const CommentForm = props => {
    const { commentId, currentcomment, entityId, entityType, onSuccessfulModal } = props
   
    const [comment, setComment] = useState('')

    const handleSaveComment = async () => {
        if (commentId) {
            try {
                await updateComment(commentId, { description: comment })
                onSuccessfulModal()
                successToast({ description: "Comment Updated Successfully" })
            } catch (err) {
                errorToast({ description: "Error happened while Updating Comment" })
            }
        } else {
            try {
                await createComment({
                    description: comment,
                    entityId,
                    entityType
                })
                onSuccessfulModal()
                successToast({ description: "Comment Created Successfully" })
            } catch (err) {
                errorToast({ description: "Error happened while Creating Comment" })
            }
        }
    }
    return <>
        <FormGroup>
            <div className="form-control-wrap">
                <textarea
                    defaultValue={currentcomment?.description}
                    type="text"
                    name="name"
                    className="form-control"
                    onChange={(e) => { setComment(e.target.value) }}
                />
            </div>
        </FormGroup>
        <Button color="primary" className='ml-3' onClick={handleSaveComment}>
            {commentId ? 'Update Comment' : 'Add Comment'}
        </Button>
    </>
}
export default CommentForm