import download from "js-file-download";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Spinner } from "reactstrap";
import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { VIEW_FORM } from "../../../constants/routes";
import { FormManagementContext } from "../FormManagementProvider";
import { exportSubmission } from "../FormManagementRepository";
import FormSubmissionFilter from "./FormSubmissionFilter";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";

const ViewForm = () => {
  const formManagementContext = useContext(FormManagementContext);
  const {
    submittedForms,
    loadSubmittedForm,
    loadFormSubmissionList,
    formList,
    formLoading,
    sfState,
    showFilter,
    formtitle,
    isFilterApplied,
    handleApplyFilter,
    filterParams,
    formFilterOptions,
    pagination,
  } = formManagementContext;
  const focusSearchRef = React.useRef();

  const [sm, updateSm] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [view, setView] = useState("");
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  let columnHeader = [];
  const history = useHistory();
  const { formId } = useParams();
  useEffect(() => {
    loadFormSubmissionList();
  }, []);
  let resultData = [];

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const createExport = async () => {
    try {
      const response = await exportSubmission({ ...filterParams, formId: formId });
      download(response, "form.csv");
      successToast({ description: "Successfully Exported" });
    } catch (error) {
      errorToast({ description: "Error happened while exporting tasks" });
    }
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const toggle = () => setonSearch(!onSearch);

  let submittedFormData = [];

  if (!submittedForms?.items) return <Spinner />;

  if (submittedForms?.items && submittedForms?.items.length) {
    submittedForms?.items[0].data.map((dataValues) => {
      columnHeader = [...columnHeader, dataValues.label];
    });
  }

  if (submittedForms?.items && submittedForms?.items.length) {
    submittedForms?.items.map((dataValues) => {
      submittedFormData = [...submittedFormData, dataValues.data];
    });
  }

  if (submittedForms?.items && submittedForms?.items.length) {
    resultData = submittedForms?.items.map((submittedForm) => {
      let currentItems = [];
      if (submittedForm.data) {
        submittedForm.data.map((formData) => {
          currentItems = [...currentItems, formData.value];
        });
        currentItems.id = submittedForm?.id;
        return currentItems;
      }
    });
  }

  const selectedCreatedByOption =
    formFilterOptions.CreatedBy?.find((o) => o.value === filterParams.selectedCreatedBy) || null;
  return (
    <>
      <BlockHeadContent className="mb-1">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <div style={{ marginBottom: "20px" }}>
                <Button onClick={history.goBack} color="light" outline className="bg-white d-none d-sm-inline-flex">
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
              </div>
              <BlockTitle tag="h3" page>
                {`${formtitle?.title}'s Submissions `}
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {submittedForms.pagination.totalCount} submissions .</p>
              </BlockDes>
              <div className="d-flex flex-wrap">
                {selectedCreatedByOption?.label && (
                  <FilteredByList
                    data={selectedCreatedByOption?.label}
                    title={"Submitted By"}
                    isNotMap={true}
                    isStr={true}
                  />
                )}
                {filterParams.CreatedAt && (
                  <FilteredByList data={filterParams.CreatedAt} title={"Submitted At"} isStr={true} isNotMap={false} />
                )}
              </div>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["getTickets", "manageTickets"]}>
                      <li>
                        <Button
                          className={`btn btn-white btn-outline-light ${
                            submittedForms?.pagination?.totalCount === 0 ? "disabled" : ""
                          }`}
                          type="export"
                          isLoading={exportLoading}
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      </BlockHeadContent>
      <Card>
        <div className="card-inner position-relative card-tools-toggle ">
          <div className="card-title-group ">
            <div className="card-tools"></div>
            <div className="card-tools mr-n1 ">
              <ul className="btn-toolbar gx-1">
                <li>
                  <a
                    href="#search"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle();
                    }}
                    className="btn btn-icon search-toggle toggle-search"
                  >
                    <Icon name="search"></Icon>
                  </a>
                </li>
                <li className="btn-toolbar-sep"></li>
                <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                  {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                  <Icon name="filter-alt"></Icon>
                </div>
              </ul>
            </div>
          </div>
          <div className={`card-search search-wrap ${!onSearch && "active"}`}>
            <div className="card-body">
              <div className="search-content">
                <Button
                  className="search-back btn-icon toggle-search active"
                  onClick={() => {
                    setSearchText("");
                    handleApplyFilter({ ...filterParams, searchText: "" });
                    toggle();
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
                <input
                  type="text"
                  className="border-transparent form-focus-none form-control"
                  placeholder="Search by Submitted By. Enter to search"
                  ref={(el) => {
                    focusSearchRef.current = !onSearch ? el : null;
                  }}
                  value={onSearchText}
                  onChange={(e) => onFilterChange(e)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      onSearchAction();
                    }
                    if (e.keyCode === 27) {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }
                  }}
                />
                <Button className="search-submit btn-icon">
                  <Icon name="search"></Icon>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ marginTop: 0, marginTop: 0, color: "#c8d4e2" }} />

        {columnHeader.length > 0 && !formLoading && (
          <div class="table-responsive ">
            <table class="table">
              <thead>
                <tr>
                  {columnHeader &&
                    columnHeader.length &&
                    columnHeader.map((form, key) => {
                      return (
                        <th
                          key={`form-header ${key}`}
                          style={{ paddingBottom: "14px" }}
                          scope="col"
                          dangerouslySetInnerHTML={{ __html: form }}
                        ></th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
              {resultData && resultData.length && !formLoading ? (
                  resultData.map((result, key) => {
                    result[1] = format(new Date(result[1]), "dd/MM/yyyy HH:mm");
                    return (
                      <tr
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push(VIEW_FORM.replace(":id", result?.id).replace(":formId", formId));
                        }}
                        key={result.id + key}
                      >
                        {result.length &&
                          result.map((formContent, i) => {
                            return (
                              <td size="md" key={`form-content-id-' + ${i}`}>
                                {formContent !== null &&
                                  (formContent instanceof Array ? (
                                    <span>
                                      {formContent
                                        .map((id, key) => {
                                          return id;
                                        })
                                        .join(", ")}
                                    </span>
                                  ) : typeof formContent === "object" ? (
                                    Object.entries(formContent).length && <span>{Object.entries(formContent)}</span>
                                  ) : (
                                    <div
                                      style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        cursor: "pointer",
                                      }}
                                      data-toggle="tooltip"
                                      title={formContent}
                                    >
                                      {formContent || "-"}
                                    </div>
                                  ))}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            {/* <hr style={{ marginTop: 0, marginTop: 0, color: "blue", width: "100%" }} /> */}
          </div>
        )}
        {formLoading && <Skeleton count={10} className="w-100" />}

        <div className="card-inner">
          {submittedForms.pagination && submittedForms.pagination.totalCount > 0 ? (
            <PaginationComponent
              itemPerPage={pagination?.size}
              totalItems={submittedForms.pagination.totalCount}
              paginate={formManagementContext.updateShowListPage}
              currentPage={submittedForms.pagination.currentPage}
              paginatePage={formManagementContext.updateShowListSize}
            />
          ) : (
            !formLoading && (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )
          )}
        </div>
      </Card>

      <Sidebar toggleState={showFilter}>
        <FormSubmissionFilter />
      </Sidebar>
    </>
  );
};

export default ViewForm;
