/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../components/Component";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { MONTH, statusColorTheme } from "../../../constants/constants";
import { PREVENTATIVE_GENERATOR_PAGE } from "../../../constants/routes";
import ModalViewer from "../../maintenance/ModalViewer";
import { MODE } from "../../maintenance/preventative/constants";
import { PreventativeTaskContext } from "../../maintenance/preventative/preventativeTasks/PreventativeTaskProvider";
import { deletePreventativeTask } from "../../maintenance/preventative/preventativeTasks/PreventativeTaskRepository";
import ExportForm from "../../maintenance/preventative/preventativeTasks/components/ExportForm";
import PreventativeListFilter from "../../maintenance/preventative/preventativeTasks/components/PreventativeListFilter";
import PreventativeTaskForm from "../../maintenance/preventative/preventativeTasks/components/PreventativeTaskForm";
import Check from "../../../components/check_roles/check";

const PlantPreventiveList = ({ plantId, plantStatus, isArchived }) => {
  const location = useLocation().search;
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");

  const preventativeContext = useContext(PreventativeTaskContext);
  const {
    pagination,
    filterParams,
    preventativeTasks,
    plants,
    engineers,
    preventativeTaskLoading,
    handleApplyFilter,
    loadPreventativeTasks,
    showFilter,
    sfState,
    isFilterApplied,
    isSubFilterApplied,
    archiveTask,
    unarchiveTask,
  } = preventativeContext;
  const [sm, updateSm] = useState(false);
  const [mode, setMode] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const STATUSES = {
    OPEN: "Open",
    IN_PROGRESS: "In Progress",
    READY_FOR_APPROVAL: "Ready for Approval",
    COMPLETED: "Completed",
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const toggle = () => setonSearch(!onSearch);

  const focusSearchRef = useRef();

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });
  useEffect(() => {
    if (plantId && !status) {
      handleApplyFilter({ ...filterParams, selectedPlant: [parseInt(plantId)] });
    }
    if (status && plantId) {
      handleApplyFilter({
        ...filterParams,
        selectedTaskStatus: [status],
        selectedPlant: [parseInt(plantId)],
        dashboard,
      });
    }
  }, [status, plantId]);
  useEffect(() => {
    if (plantId) {
      loadPreventativeTasks(plantId);
    }
    if (plantId && !status) {
      handleApplyFilter({ ...filterParams, selectedPlant: [parseInt(plantId)] });
    }
    if (status && plantId) {
      handleApplyFilter({
        ...filterParams,
        selectedTaskStatus: [status],
        selectedPlant: [parseInt(plantId)],
        dashboard,
      });
    }
  }, [plantId, status]);

  const onSuccessfulModal = () => {
    loadPreventativeTasks(plantId, status);
  };
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, taskText: onSearchText });
  };

  const currentItems = preventativeTasks?.items || [];

  const viewTask = (id) => {
    setMode(MODE.VIEW);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const editTask = (id) => {
    setMode(MODE.EDIT);
    setIsOpen(true);
    setCurrentTaskId(id);
  };
  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeTask(taskId);
      onSuccessfulModal();
      setIsOpen(false);
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Task" });
    }
  };
  const getEngineerName = (plantId) => {
    const engineer = engineers.find((p) => p.value === plantId);
    return engineer?.label || "";
  };
  const handleDeleteConfirmation = (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };
  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Preventative Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Preventative Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId,plantId)
      .then((res) => {
        successToast({ description: "Preventative Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Preventative Task" });
      });
  };
  const getComponent = () => {
    return (
      <PreventativeTaskForm
        mode={mode}
        setMode={setMode}
        currentTaskId={currentTaskId}
        handleDeleteConfirmation={handleDeleteConfirmation}
        setIsOpen={setIsOpen}
      />
    );
  };
  const getExportComponent = () => {
    return <ExportForm plantId={plantId} onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const createExport = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page className="ml-md-4">
                Preventative Task Lists
              </BlockTitle>
              <BlockDes className="text-soft ml-md-4">
                <p>You have a total of {preventativeTasks?.pagination?.totalCount || 0} preventative tasks.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content w-auto" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {plantStatus === "ACTIVE" && isArchived === false && (
                      <>
                        <Check permission={["getTickets", "manageTickets"]}>
                          <li>
                            <Button
                              disabled={preventativeTasks?.pagination?.totalCount === 0}
                              className={`btn btn-white btn-outline-light`}
                              type="export"
                              isLoading={exportLoading}
                              onClick={createExport}
                            >
                              <Icon name="download-cloud"> </Icon>
                              <span>Export</span>
                            </Button>
                          </li>
                        </Check>
                        <Check permission={["manageTickets"]}>
                          <li>
                            <a
                              id="add-plantpreventative"
                              href={`${PREVENTATIVE_GENERATOR_PAGE}?plant=${plantId}`}
                              // onClick={(ev) => {
                              //     ev.preventDefault();
                              // }}
                              className="btn btn-primary mr-2"
                            >
                              <Icon name="plus"></Icon>
                              <span>Generate Preventative Task</span>
                            </a>
                          </li>
                        </Check>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            {
              <div className="card-inner position-relative card-tools-toggle ">
                <div className="card-title-group ">
                  <div className="card-tools"></div>
                  <div className="card-tools mr-n1 ">
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <a
                          id="search-plantpreventative"
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <div
                        id="filter-plantpreventative"
                        tag="a"
                        className="btn btn-icon "
                        onClick={() => sfState.toggle()}
                      >
                        {isSubFilterApplied ? <div className="dot dot-primary"></div> : null}
                        <Icon name="filter-alt"></Icon>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        id="cancel-search-plantpreventative"
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, taskText: "" });
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        id="input-search-plantpreventative"
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by Ticket Name and Status.enter to search"
                        ref={(el) => {
                          focusSearchRef.current = !onSearch ? el : null;
                        }}
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            onSearchAction();
                          }
                        }}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search" onClick={onSearchAction}></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            }
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Ticket Name</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Code</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Start Date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Resolved Date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Assigned To</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Month</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Year</span>
                </DataTableRow>
                <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
              </DataTableHead>

              <Sidebar toggleState={showFilter}>
                <PreventativeListFilter subList={true} plants={plants} engineers={engineers} isPlantName={true} />
              </Sidebar>

              {preventativeTaskLoading ? (
                <></>
              ) : (
                currentItems.map((item, i) => {
                  return (
                    <DataTableItem key={`${item.id}-preventative-masters`}>
                      <DataTableRow>
                        <div
                          id="plantpreventative-view"
                          className="user-card dropdown-toggle"
                          onClick={() => {
                            viewTask(item.id);
                          }}
                        >
                          <div className="user-name">
                            <span
                              data-toggle="tooltip"
                              title={item.title}
                              style={{
                                display: "inline-block",
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="tb-lead"
                            >
                              {item.title || "-"}
                            </span>
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span
                          data-toggle="tooltip"
                          title={item.code}
                          style={{
                            width: "90px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                        >
                          {item?.code || "-"}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>
                          <Badge className="text-capitalize" style={statusColorTheme[item.status]} pill>
                            {STATUSES[item.status] || "-"}
                          </Badge>
                        </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.startedAt ? format(new Date(item?.startedAt || null), "dd/MM/yyyy") : "-"} </span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        {item?.resolvedAt
                          ? format(new Date(item.resolvedAt ? item.resolvedAt : null), "dd/MM/yyyy")
                          : "-"}
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>
                          {item?.assignedToIds
                            ?.map((id, key) => {
                              return getEngineerName(id);
                            })
                            .join(", ") || "-"}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span>{MONTH[item.months] || "-"}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span>{item.year || "-"}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <Check permission={["manageTickets"]}>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  id="plantpreventative-dropdown"
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <React.Fragment>
                                      <li
                                        /* className="nk-tb-action-hidden" */ onClick={() => {
                                          editTask(item.id);
                                        }}
                                      >
                                        <DropdownItem
                                          id="edit-plantpreventative"
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit-alt-fill"></Icon>
                                          <span>Edit Task</span>
                                        </DropdownItem>
                                      </li>
                                      {item.isArchived ? (
                                        <React.Fragment>
                                          <li className="divider"></li>
                                          <li onClick={() => handleUnArchive(item.id)}>
                                            <DropdownItem
                                              tag="a"
                                              href="#unarchive"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="unarchive"></Icon>
                                              <span>UnArchive Task</span>
                                            </DropdownItem>
                                          </li>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <li className="divider"></li>
                                          <li onClick={() => handleArchive(item.id)}>
                                            <DropdownItem
                                              tag="a"
                                              href="#archive"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="archive"></Icon>
                                              <span>Archive Task</span>
                                            </DropdownItem>
                                          </li>
                                        </React.Fragment>
                                      )}
                                      <li className="divider"></li>
                                      <li
                                        /* className="nk-tb-action-hidden" */ onClick={() =>
                                          handleDeleteConfirmation(item.id)
                                        }
                                      >
                                        <DropdownItem
                                          id="delete-plantpreventative"
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete Task</span>
                                        </DropdownItem>
                                      </li>
                                    </React.Fragment>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Check>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              )}
            </DataTableBody>
            {preventativeTaskLoading && <Skeleton count={10} className="w-100" />}

            <div className="card-inner">
              {preventativeTasks.pagination && preventativeTasks.pagination.totalCount > 0 ? (
                <PaginationComponent
                  itemPerPage={pagination.size}
                  totalItems={preventativeTasks.pagination.totalCount}
                  paginate={preventativeContext.updateShowListPage}
                  currentPage={preventativeTasks.pagination.currentPage}
                  paginatePage={preventativeContext.updateShowListSize}
                />
              ) : (
                !preventativeTaskLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
        </Block>
      </Block>
      <ModalViewer title="Preventative Task" isOpen={isOpen} toggleModal={toggleModal} component={getComponent()} />
      <ModalViewer
        title="Preventative Maintenance - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        Month
        component={getExportComponent()}
      />
    </>
  );
};
export default PlantPreventiveList;
