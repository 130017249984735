import React, { useState } from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  getFormList,
  updateFormAPI,
  createFormAPI,
  getFormAPI,
  deleteFormAPI,
  getSubmittedFormList,
  createFormSubmissionAPI,
  getSubmittedFormApi,
  getFormListFilterOptions,
  formFilterOption,
  getFormsAPI,
} from "./FormManagementRepository";
import { useHistory } from "react-router-dom";
import { FORM_CREATE_PAGE } from "../../constants/routes";

export const FormManagementContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterOptions = {
  formStatusses: [],
  formRoles: [],
};
const initialFormFilterOptions = {
  CreatedAt: [],
  CreatedBy: [],
};

const initialFilterParams = {
  searchText: "",
  isArchiveSelected: false,
  selectedFormStatus: [],
  selectedFormRole: [],
  selectedCreatedAt: [],
  selectedCreatedBy: [],
};

export const FormManagementProvider = (props) => {
  const [formList, setFormList] = React.useState([]);
  const [forms, setForms] = React.useState([]);
  const [multiData, setMultidata] = React.useState([]);
  const [submittedForms, setSubmittedForms] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [currentViewForm, setCurrentViewForm] = React.useState();
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  const [formFilterOptions, setFormFilterOptions] = React.useState(Object.assign({}, initialFormFilterOptions));
  const [compressedFile, setCompressedFile] = React.useState();
  const [singleFileUpload, setSingleFileUploads] = React.useState({});
  const [formtitle, setFormtitle] = useState();
  const [formItems, setFormItems] = React.useState([]);

  //Loaders

  const [formLoading, formState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);

  const history = useHistory();

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, isArchiveSelected, selectedFormStatus, selectedFormRole, CreatedAt, selectedCreatedBy } =
      filterParams;
    if (searchText) queryParams["formSearchText"] = searchText;
    if (isArchiveSelected != null) queryParams["formArchived"] = isArchiveSelected;
    if (selectedFormStatus) queryParams["formStatus"] = selectedFormStatus;
    if (selectedFormRole) queryParams["formRoles"] = selectedFormRole;
    if (CreatedAt) queryParams["createdAt"] = CreatedAt;
    if (selectedCreatedBy) queryParams["createdBy"] = selectedCreatedBy;
    return queryParams;
  };
  const loadFormList = async () => {
    formState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getFormList(filterParams);
      setFormList(response.formsList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      formState.off();
    }
  };

  const loadFormSubmissionList = async () => {
    formState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getFormsAPI(filterParams);
      setFormItems(response.formsList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      formState.off();
    }
  };
  //LOAD FILTER OPTIONS
  const loadFilterOptions = async () => {
    formState.on();
    try {
      const response = await getFormListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      formState.off();
    }
  };

  const loadFormFilterOption = async () => {
    try {
      const response = await formFilterOption();
      setFormFilterOptions(response);
    } catch (error) {}
  };

  //CREATE FORM

  const createForm = async (createParams) => {
    try {
      formState.on();
      const res = await createFormAPI(createParams);
      history.push(FORM_CREATE_PAGE.replace(":formId", res.form.id));
    } catch (e) {
      throw e;
    } finally {
      formState.off();
    }
  };

  //CREATE FORM

  const createFormSubmission = async (createParams) => {
    try {
      formState.on();
      await createFormSubmissionAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      formState.off();
    }
  };

  //LOAD FORM
  const loadForm = async (formId) => {
    formState.on();
    try {
      const response = await getFormAPI(formId);
      setCurrentViewForm(response.form);
    } catch (e) {
      throw e;
    } finally {
      formState.off();
    }
  };

  //LOAD SUBMITTED FORMS
  const loadSubmittedForm = async (formId, submissionId) => {
    formState.on();
    try {
      const filterParams = getListFilterParams();
      if(submissionId){
        filterParams["submissionId"] = submissionId
      }
      const response = await getSubmittedFormApi(formId, filterParams);
      setSubmittedForms(response.submittedForms);
      setFormtitle(response?.formData);
    } catch (e) {
      throw e;
    } finally {
      formState.off();
    }
  };

  // UPDATE FORM

  const updateForm = async (formId, updateParams) => {
    try {
      const response = await updateFormAPI(formId, updateParams);
      setCurrentViewForm(response.asset);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // DELETE FORM

  const deleteForm = async (formId) => {
    try {
      await deleteFormAPI(formId);
      loadFormList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  const loadSubmittedFormList = async () => {
    formState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getSubmittedFormList(filterParams);
      setForms(response.formsList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      formState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const checkIsFilterApplied = () => {
    if (filterParams.selectedFormRole.length != 0 || filterParams.selectedFormStatus != 0) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();

  const resetFormListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    pagination,
    formList,
    formItems,
    loadFormList,
    currentViewForm,
    isFilterApplied,
    checkIsFilterApplied,
    formLoading,
    deleteForm,
    createForm,
    updateForm,
    loadForm,
    handleApplyFilter,
    filterParams,
    loadSubmittedFormList,
    forms,
    submittedForms,
    setMultidata,
    multiData,
    loadSubmittedForm,
    loadFilterOptions,
    createFormSubmission,
    compressedFile,
    setCompressedFile,
    setSingleFileUploads,
    singleFileUpload,
    formtitle,
    formLoading,
    filterOptions,
    loadFormFilterOption,
    formFilterOptions,
    setFilterOptions,
    sfState,
    showFilter,
    resetFormListFilter,
    formLoading,
    isFilterApplied,
    loadFormSubmissionList,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <FormManagementContext.Provider value={mContext}>{props.children}</FormManagementContext.Provider>;
};

export const withFormManagementProvider = (Container, containerProps) => (props) =>
  (
    <FormManagementProvider>
      <Container {...containerProps} {...props} />
    </FormManagementProvider>
  );
