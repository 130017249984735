import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  getHealthAndSafetyList,
  updateHealthAndSafetyAPI,
  createHealthAndSafetyAPI,
  getHealthAndSafetyAPI,
  deleteHealthAndSafetyAPI,
  getHealthAndSafetyFilterListAPI,
} from "./HealthAndSafetyRepository";

export const HealthAndSafetyContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterOptions = {
  medicalCenters: [],
  address:[],
  phoneNumbers:[]
};

const initialFilterParams = {
  selectedMedicalCenter:[],
  selectedAddress:[],
  selectedPhoneNumber:[],
  searchText: "",
  sortingField: "",
  sortingOrder: "",
};

export const HealthAndSafetyProvider = (props) => {
  const [healthAndSafetyList, setHealthAndSafetyList] = React.useState([]);
  const [currentViewHealthAndSafety, setCurrentViewHealthAndSafety] = React.useState();
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  //Loaders

  const [healthAndSafetyLoading, healthAndSafetyState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, sortingOrder, sortingField ,selectedAddress,selectedMedicalCenter,selectedPhoneNumber} = filterParams;
    if (searchText) queryParams["healthAndSafetySearchText"] = searchText;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (selectedAddress) queryParams["address"] = selectedAddress;
    if (selectedMedicalCenter) queryParams["medicalCenter"] = selectedMedicalCenter;
    if (selectedPhoneNumber) queryParams["phoneNumber"] = selectedPhoneNumber;
    return queryParams;
  };

  const loadHealthAndSafetyList = async () => {
    healthAndSafetyState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getHealthAndSafetyList(filterParams);
      setHealthAndSafetyList(response.medicalCentersList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      healthAndSafetyState.off();
    }
  };

  //CREATE HOME_AND_SAFETY

  const createHealthAndSafety = async (createParams) => {
    try {
      healthAndSafetyState.on();
      const data = await createHealthAndSafetyAPI(createParams);
      return data;
    } catch (e) {
      throw e;
    } finally {
      healthAndSafetyState.off();
    }
  };

  // VIEW HOME_AND_SAFETY

  const loadHealthAndSafety = async (healthAndSafetyId) => {
    try {
      const response = await getHealthAndSafetyAPI(healthAndSafetyId);
      setCurrentViewHealthAndSafety(response.medicalCenter);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // UPDATE HOME_AND_SAFETY

  const updateHealthAndSafety = async (healthAndSafetyId, updateParams) => {
    try {
      const response = await updateHealthAndSafetyAPI(healthAndSafetyId, updateParams);
      setCurrentViewHealthAndSafety(response.medicalCenterId);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // DELETE HOME_AND_SAFETY

  const deleteHealthAndSafety = async (healthAndSafetyId) => {
    try {
      await deleteHealthAndSafetyAPI(healthAndSafetyId);
      loadHealthAndSafetyList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  //to get filter-options
  const loadFilterOptions = async () => {
    flState.on();

    try {
      const response = await getHealthAndSafetyFilterListAPI();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };
 //APPLY FILTER
 const checkIsFilterApplied = () => {
  if (
    filterParams.selectedMedicalCenter.length != 0 ||
    filterParams.selectedAddress != 0 ||
    filterParams.selectedPhoneNumber != 0
  ) {
    return true;
  } else return false;
};

const isFilterApplied = checkIsFilterApplied();
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const resetHealthAndSafetyListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    pagination,
    healthAndSafetyList,
    loadHealthAndSafetyList,
    currentViewHealthAndSafety,
    healthAndSafetyLoading,
    deleteHealthAndSafety,
    createHealthAndSafety,
    updateHealthAndSafety,
    loadHealthAndSafety,
    handleApplyFilter,
    loadFilterOptions,
    filterOptions,
    filtersLoading,
    showFilter,
    sfState,
    filterParams,
    resetHealthAndSafetyListFilter,
    isFilterApplied,
    checkIsFilterApplied,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <HealthAndSafetyContext.Provider value={mContext}>{props.children}</HealthAndSafetyContext.Provider>;
};

export const withHealthAndSafetyProvider = (Container, containerProps) => (props) =>
  (
    <HealthAndSafetyProvider>
      <Container {...containerProps} {...props} />
    </HealthAndSafetyProvider>
  );
