import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import { getDnoList, updateDnoAPI, createDnoAPI, getDnoAPI, deleteDnoAPI, getDnoOptions } from "./DnoRepository";

export const DnoContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterOption = {
  dnos: [],
  users: [],
};

const initialFilterParams = {
  searchText: "",
  sortingField: "",
  sortingOrder: "",
  dnos: [],
  users: [],
  createdAt: "",
  updatedAt: "",
};

export const DnoProvider = (props) => {
  const [dnoList, setDnoList] = React.useState([]);
  const [filterOption, setFilterOption] = React.useState([Object.assign({}, initialFilterOption)]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [currentViewDno, setCurrentViewDno] = React.useState();
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [dnoData, setDnoData] = React.useState({});

  //Loaders

  const [dnoLoading, dnoState] = useBoolean(false);

  //close  modal
  const [showFilter, sfState] = useBoolean(false);

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, sortingOrder, sortingField, dnos, users, createdAt, updatedAt } = filterParams;
    if (searchText) queryParams["dnoSearchText"] = searchText;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (dnos) queryParams["dnos"] = dnos;
    if (users) queryParams["users"] = users;
    if (createdAt) queryParams["createdAt"] = createdAt;
    if (updatedAt) queryParams["updatedAt"] = updatedAt;

    return queryParams;
  };

  //LIST ALL DNO
  const loadDnoList = async () => {
    dnoState.on();
    try {
      const filterParams = getListFilterParams();
      const response = await getDnoList(filterParams);
      setDnoList(response.dnosList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      dnoState.off();
    }
  };

  //CREATE DNO

  const createDno = async (createParams) => {
    try {
      dnoState.on();
      const data = await createDnoAPI(createParams);
      const dnos = { label: `${data.dno.name}`, value: `${data.dno.id}` };
      setDnoData(dnos);
      return data;
    } catch (e) {
      throw e;
    } finally {
      dnoState.off();
    }
  };

  //DNO_FILTER_OPTION

  const getDnoFilterOption = async () => {
    dnoState.on();
    try {
      const response = await getDnoOptions();
      setFilterOption(response);

      return response;
    } catch (error) {
      throw error;
    } finally {
      dnoState.off();
    }
  };

  //APPLY FILTER
  const checkIsFilterApplied = () => {
    if (filterParams.dnos != 0 || filterParams.users != 0 || filterParams.createdAt || filterParams.updatedAt) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();

  // VIEW SLA

  const loadDno = async (dnoId) => {
    try {
      const response = await getDnoAPI(dnoId);
      setCurrentViewDno(response.dno);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  // UPDATE SLA

  const updateDno = async (dnoId, updateParams) => {
    dnoState.on();
    try {
      const response = await updateDnoAPI(dnoId, updateParams);
      setCurrentViewDno(response.dno);
    } catch (e) {
      throw e;
    } finally {
      dnoState.off();
    }
  };

  // DELETE DNO

  const deleteDno = async (dnoId) => {
    try {
      await deleteDnoAPI(dnoId);
      loadDnoList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const handleDnoResetFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    filterOption,
    pagination,
    dnoList,
    sfState,
    showFilter,
    checkIsFilterApplied,
    isFilterApplied,
    loadDnoList,
    currentViewDno,
    dnoLoading,
    deleteDno,
    getDnoFilterOption,
    createDno,
    updateDno,
    handleDnoResetFilter,
    loadDno,
    handleApplyFilter,
    filterParams,
    dnoData,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <DnoContext.Provider value={mContext}>{props.children}</DnoContext.Provider>;
};

export const withDnoProvider = (Container, containerProps) => (props) =>
  (
    <DnoProvider>
      <Container {...containerProps} {...props} />
    </DnoProvider>
  );
