/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import EquipmentAndSpareList from "../components/PvPlantEquipmentList";
import {
  EquipmentAndSpareContext,
  withEquipmentAndSpareProvider,
} from "../../equipment_spare/EquipmentAndSpareProvider";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";

const EquipmentAndSpareListContainer = (props) => {
  const { loadEquipmentList, loadFilterOptions, filterParams, pagination } = useContext(EquipmentAndSpareContext);
  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant } = pvPlantManagementContext;
  const { id } = currentViewPvPlant;

  useEffect(() => {
    if (id) {
      loadEquipmentList(id);
    }
  }, [id, filterParams, pagination]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <Content>
        <EquipmentAndSpareList plantId={id} />
      </Content>
    </React.Fragment>
  );
};

export default withEquipmentAndSpareProvider(EquipmentAndSpareListContainer);
