/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import {
  Block,
  PreviewCard,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTable,
  DataTableItem,
  Icon,
  TooltipComponent,
  RSelect,
  BlockTitle,
} from "../../../components/Component";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../hooks/useBoolean";
import { useHistory } from "react-router-dom";
import { ClientManagementContext } from "../ClientManagementProvider";
import { UserManagementContext } from "../../user_management/UserManagementProvider";
import { CLIENT_MANAGEMENT_PAGE } from "../../../constants/routes";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import Attachments from "../../../components/attachments/Attachment";
import ModalViewer from "../../entity_management/ModalViewer";
import ClientManagementPlantFormContainer from "../containers/ClientManagementPlantFormContainer";
import ClientManagementUserFormContainer from "../containers/ClientManagementUserFormContainer";
import { format } from "date-fns";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { phoneCode } from "../../../constants/country";
import { CLIENT } from "../../../constants/PreferenceKeys";
import { sortOption } from "../../../utils/Utils";
import Head from "../../../layout/head/Head";
import { updatePasswordSchema } from "../../user_management/validation";
import Check from "../../../components/check_roles/check";
import LogoAttachment from "../../../components/attachments/LogoAttachment";
import { deleteFile } from "../../../repositories/FileRepository";

const createSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    phoneNumber: yup.number().notRequired().default(),
    addressLine1: yup.string().notRequired(),
    mobileNumber: yup.number().notRequired().default(),
    phoneCode: yup.string().when("phoneNumber", {
      is: "phone",
      then: yup.string().notRequired(),
    }),
    mobileCode: yup.string().when("mobileNumber", {
      is: "mobile",
      then: yup.string().notRequired(),
    }),
    email: yup.string().email().nullable(true).default(null),
    // email:yup.string().nullable(true).default(null).transform(( function(value, originalValue) { if (!value) { return null; } return originalValue; })),
    city: yup.string().notRequired(),
    postalCode: yup.string().notRequired().max(10, "Invalid postal code"),
    website: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        { message: "Website is not valid eg(google.com)", excludeEmptyString: true }
      )
      .optional(),
    taskVisibility: yup.array().of(yup.string()).default(["COMPLETED"]),
    plantIds: yup.array().of(yup.number()).notRequired().default([]),
  })
  .required();

const ClientForm = (props) => {
  const history = useHistory();

  const clientManagementContext = useContext(ClientManagementContext);
  const {
    currentViewClient,
    clientUsers,
    plantsList,
    clientPlantIds,
    loadingClientView,
    logoAttachment,
    logoId,
    logoKey,
  } = clientManagementContext;

  const userManagementContext = useContext(UserManagementContext);
  const { inlineLoading } = userManagementContext;
  const [plants, setPlants] = useState([]);
  const [plantId, setPlantId] = useState("");
  const [users, setUsers] = useState([]);
  const [clientUserData, setClientUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [clientUserId, setClientUserId] = useState("");
  const [isEditMode, updateEditMode] = useBoolean(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isuserOpen, setIsuserOpen] = useState(false);
  const [modalTab, setModalTab] = useState("");
  const [passwordState, setPasswordState] = useState(false);
  const [modal, setModal] = useState(false);
  const [attachment, setAttachment] = useState();
  const [isUserEditMode, updateUserEditMode] = useBoolean(false);

  const userFormRef = useForm({
    resolver: yupResolver(props.clientId ? createSchema : createSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = userFormRef;

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleUserModal = () => {
    setIsuserOpen(!isuserOpen);
  };

  const updatePasswordFormRef = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  const {
    reset: resetChangePassword,
    formState: { errors: updatePasswordError },
    register: updatePasswordRegister,
    handleSubmit: handleUpdatePassword,
  } = updatePasswordFormRef;

  useEffect(() => {
    if (!modal) resetChangePassword({ newPassword: "", confirmPassword: "" });
  }, [modal]);

  useEffect(() => {
    if (props.clientId) {
      clientManagementContext.loadClient(props.clientId).catch((e) => {
        errorToast({ description: "Error happened while loading Clients list" });
      });
    }
    clientManagementContext.loadPvPlantList();
  }, [props.clientId]);

  useEffect(() => {
    if (clientUsers.length) {
      setClientUsers(clientUsers);
    }
  }, [clientUsers]);

  useEffect(() => {
    if (!currentViewClient) {
      reset({ phoneCode: "+44", mobileCode: "+44" });
    }
    if (currentViewClient) {
      updateEditMode.on();
      const { attachment, deletedAt, ...clientData } = currentViewClient;
      reset({
        ...clientData,
        plantIds: clientPlantIds,
        phoneCode: clientData.phoneNumber.split(" ")[0],
        phoneNumber: clientData.phoneNumber.split(" ")[1],
        mobileCode: clientData.mobileNumber.split(" ")[0],
        mobileNumber: clientData.mobileNumber.split(" ")[1],
      });
    }
  }, [currentViewClient, clientPlantIds]);

  const handleFormSubmit = (formData) => {
    if (isEditMode) {
      if (logoId && !logoAttachment) {
        deleteFile(logoKey);
      }
      clientManagementContext
        .updateClient(currentViewClient.id, {
          ...formData,
          users,
          logoAttachment: attachment,
          phoneNumber: formData.phoneNumber ? `${formData.phoneCode} ${formData.phoneNumber}` : "",
          mobileNumber: formData.mobileNumber ? `${formData.mobileCode} ${formData.mobileNumber}` : "",
        })
        .then((res) => {
          history.replace(CLIENT_MANAGEMENT_PAGE);
          successToast({ description: "Client details updated successfully" });
        })
        .catch((e) => {
          errorToast({ description: `Error ${e.message}` });
        });
    } else {
      clientManagementContext
        .createClient({
          ...formData,
          plants,
          users,
          logoAttachment: attachment,
          phoneNumber: formData.phoneNumber ? `${formData.phoneCode} ${formData.phoneNumber}` : "",
          mobileNumber: formData.mobileNumber ? `${formData.mobileCode} ${formData.mobileNumber}` : "",
        })
        .then((res) => {
          history.replace(CLIENT_MANAGEMENT_PAGE);
          successToast({ description: "Client created successfully" });
        })
        .catch((e) => {
          if (e.message === "Validation error") {
            errorToast({ description: "Identifier already exists" });
          } else {
            errorToast({ description: `Error ${e.message}` });
          }
        });
    }
  };

  const handlePasswordSubmit = (formData) => {
    const password = {
      password: formData.newPassword,
    };
    userManagementContext
      .userPassword(clientUserId, password)
      .then((res) => {
        setModal(false);
        successToast({ description: "Password Updated Successfully " });
      })
      .catch((e) => {
        errorToast({ description: `${e}` });
      });
  };

  const onClientUserEditClick = (userId) => {
    updateUserEditMode.on()
    setClientUserId(userId);
    toggleUserModal();
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="client-create-btn"
        isLoading={loadingClientView}
        color="primary"
        size="lg"
        onClick={loadingClientView ? null : handleSubmit(handleFormSubmit)}
      >
        Create Client
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <>
        <Button
          id="client-cancel-btn"
          size="lg"
          className="mr-1"
          onClick={() => history.replace(CLIENT_MANAGEMENT_PAGE)}
          color="outline-primary"
        >
          Cancel
        </Button>
        <Button
          id="client-save-info-btn"
          color="primary"
          isLoading={loadingClientView}
          size="lg"
          onClick={loadingClientView ? null : handleSubmit(handleFormSubmit)}
        >
          Save Information
        </Button>
      </>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  const getComponent = () => {
    return (
      <>
        <ClientManagementPlantFormContainer
          setIsOpen={setIsOpen}
          setPlants={setPlants}
          plants={plants}
          toggleModal={toggleModal}
          plantId={plantId}
        />
      </>
    );
  };

  const getUserComponent = () => {
    return (
      <>
        <ClientManagementUserFormContainer
          setIsOpen={setIsuserOpen}
          setUsers={setUsers}
          users={users}
          toggleModal={toggleUserModal}
          userId={userId}
          clientUserId={clientUserId}
          setClientUsers={setClientUsers}
          clientUserData={clientUserData}
          setClientUserId={setClientUserId}
          clientUsers={clientUsers}
          isUserEditMode={isUserEditMode}
        />
      </>
    );
  };
  const handlePlantEdit = (id) => {
    setPlantId(id);
    toggleModal();
  };

  const handlePlantDelete = (id) => {
    plants.splice(id, 1);
    setPlants([...plants]);
  };

  const handleUserEdit = (id) => {
    setUserId(id);
    toggleUserModal();
  };

  const handleUserDelete = (id) => {
    users.splice(id, 1);
    setUsers([...users]);
  };

  const handleConfirmationDeleteClient = async (id) => {
    confirmationSweetAlert({
      id,
      handleConfirmation: handlePlantDelete,
    });
  };
  const handleConfirmationDeleteClientUser = async (id) => {
    confirmationSweetAlert({
      id,
      handleConfirmation: handleUserDelete,
    });
  };
  const TASK_STATUS = [
    { value: "OPEN", label: "Open" },
    { value: "IN_PROGRESS", label: "In progress" },
    { value: "READY_FOR_APPROVAL", label: "Ready for Approval" },
    { value: "COMPLETED", label: "Completed" },
  ];

  const attachmentFiles = (logo) => {
    if (logo) {
      setAttachment(logo);
    } else {
      setAttachment();
    }
  };

  return (
    <>
      <Block size="lg">
        <Head title={isEditMode ? "Brighter App | Client | Edit" : "Brighter App | Client | Create"} />
        <PreviewCard>
          <div className="card-head">
            <h5 className="card-title">Client Information</h5>
          </div>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Name
                  </Label>
                  <div id="client-name-input" className="form-control-wrap">
                    <input {...register("name")} type="text" name="name" className="form-control" />
                  </div>
                  {errors.name && (
                    <span className="invalid" style={{ color: "red" }}>
                      Name is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Phone Number
                  </Label>
                  <div className="d-flex">
                    <Col xs="4" md="2" lg="3" className="p-0">
                      <Controller
                        control={control}
                        name="phoneCode"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = phoneCode;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              id="client-phoneCode-input"
                              {...field}
                              ref={ref}
                              value={selectedValue ? selectedValue : phoneCode[0]}
                              options={options}
                              onChange={(o) => setValue("phoneCode", o.value)}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col xs="8" md="10" lg="9" className="p-0">
                      <input
                        id="client-phonenumber-input"
                        {...register("phoneNumber")}
                        type="text"
                        name="phoneNumber"
                        className="form-control"
                      />
                    </Col>
                  </div>
                  {errors.phoneNumber && (
                    <span className="invalid" style={{ color: "red" }}>
                      phoneNumber is required`
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Address
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="client-address-input"
                      {...register("addressLine1")}
                      type="text"
                      name="addressLine1"
                      className="form-control"
                    />
                  </div>
                  {errors.addressLine1 && (
                    <span className="invalid" style={{ color: "red" }}>
                      Address is required
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup className="form-group">
                  <label className="form-label">Mobile Number</label>
                  <div className="form-control-wrap d-flex">
                    <Col xs="4" md="2" lg="3" className="p-0">
                      <Controller
                        control={control}
                        name="mobileCode"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = phoneCode;
                          const selectedValue = options.find((e) => e.value === field.value);

                          return (
                            <RSelect
                              id="client-mobilecode-select"
                              {...field}
                              ref={ref}
                              value={selectedValue ? selectedValue : phoneCode[0]}
                              options={options}
                              onChange={(o) => setValue("mobileCode", o.value)}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col xs="8" md="10" lg="9" className="p-0">
                      <input
                        id="client-mobilenumber-input"
                        type="text"
                        name="mobileNumber"
                        className="form-control"
                        {...register("mobileNumber")}
                      />
                    </Col>
                  </div>
                  {errors.mobileNumber && (
                    <span className="invalid" style={{ color: "red" }}>
                      Mobile Number is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-group">
                  <label className="form-label">City</label>
                  <div className="form-control-wrap">
                    <input id="client-city-input" type="text" className="form-control" {...register("city")} />
                  </div>
                  {errors.city && (
                    <span className="invalid" style={{ color: "red" }}>
                      City is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Postal Code
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="client-postalcode-input"
                      {...register("postalCode")}
                      type="text"
                      name="postalCode"
                      className="form-control"
                    />
                  </div>
                  {errors?.postalCode && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.postalCode?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Email address
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="client-email-input"
                      {...register("email")}
                      type="text"
                      name="email"
                      className="form-control"
                    />
                  </div>
                  {errors.email && (
                    <span className="invalid" style={{ color: "red" }}>
                      Email is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-group">
                  <label className="form-label">Website</label>
                  <div className="form-control-wrap">
                    <input id="client-web-input" type="text" className="form-control" {...register("website")} />
                  </div>
                  {errors?.website && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.website.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-group">
                  <label className="form-label">Task Visibility</label>
                  <Controller
                    control={control}
                    name="taskVisibility"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = TASK_STATUS;
                      const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                      return (
                        <RSelect
                          id="client-task-select"
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          isMulti
                          options={options}
                          onChange={(o) =>
                            setValue(
                              "taskVisibility",
                              o.map((item) => item.value)
                            )
                          }
                          // isDisabled={mode === MODE.VIEW}
                        />
                      );
                    }}
                  />
                  {errors?.taskVisibility && (
                    <span className="invalid" style={{ color: "red" }}>
                      TaskVisibility is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="plantIds">
                    Plant
                  </Label>
                  <div className="form-control-wrap ">
                    <Controller
                      control={control}
                      name="plantIds"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = plantsList;
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                        return (
                          <RSelect
                            id="client-plant-input"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            placeholder="Select the Plant "
                            isMulti
                            options={sortOption(options)}
                            onChange={(o) =>
                              setValue(
                                "plantIds",
                                o.map((item) => item.value)
                              )
                            }
                          />
                        );
                      }}
                    />
                  </div>
                  {errors.plantId && (
                    <span className="invalid" style={{ color: "red" }}>
                      Dno is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup className="form-group">
                  <div className="d-flex align-items-center">
                    <BlockTitle tag="h6" className="mb-1">
                      Update your client logo
                    </BlockTitle>
                  </div>
                </FormGroup>
              </Col>
              <Check permission={["createClient", "updateClient"]}>
                <Col lg="12" md="12">
                  <LogoAttachment attachmentFiles={attachmentFiles} logoAttachment={logoAttachment} />
                </Col>
              </Check>

              {!isEditMode && (
                <>
                  <div className="card-head col-xl-12">
                    <div>
                      <h5 className="card-title">Plants Owned</h5>
                    </div>
                    <div>
                      <Button
                        id="client-add-plant"
                        color="primary"
                        size="md"
                        onClick={(e) => {
                          setPlantId("");
                          toggleModal();
                        }}
                      >
                        <Icon name="plus" />
                        <span> Add Plant</span>
                      </Button>
                    </div>
                  </div>
                  {(!plants.length && <></>) || (
                    <Row className="col-xl-12">
                      <Col lg="12">
                        <Block className="border border-light">
                          <DataTable className="card-stretch">
                            <DataTableBody compact>
                              <DataTableHead>
                                <DataTableRow>
                                  <span className="sub-text">PV plant Name</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span className="sub-text">On Boarding Date</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span className="sub-text">Status</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span className="sub-text">Size</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span className="sub-text">Acronym</span>
                                </DataTableRow>
                                <DataTableRow>
                                  <span className="sub-text" style={{ textAlign: "end" }}>
                                    Actions
                                  </span>
                                </DataTableRow>
                              </DataTableHead>
                              {plants.map((item, key) => {
                                return (
                                  <DataTableItem key={key}>
                                    <DataTableRow>
                                      <div className="user-card">
                                        <div className="user-name">
                                          <span className="tb-lead">{item.name || "-"}</span>
                                        </div>
                                      </div>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <div className="user-card">
                                        <div className="user-name">
                                          <span className="tb-lead">
                                            {item.onboardedAt === null
                                              ? "-"
                                              : format(new Date(item.onboardedAt), "dd/MM/yyyy")}
                                          </span>
                                        </div>
                                      </div>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <div className="user-card">
                                        <div className="user-name">
                                          <span className="tb-lead">{item.status || "-"}</span>
                                        </div>
                                      </div>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <div className="user-card">
                                        <div className="user-name">
                                          <span className="tb-lead">{item.size || "-"}</span>
                                        </div>
                                      </div>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <div className="user-card">
                                        <div className="user-name">
                                          <span className="tb-lead">{item.identifier || "-"}</span>
                                        </div>
                                      </div>
                                    </DataTableRow>
                                    <DataTableRow className="nk-tb-col-tools">
                                      <ul className="nk-tb-actions gx-1">
                                        <li className="nk-tb-action-hidden" onClick={() => handlePlantEdit(key)}>
                                          <TooltipComponent
                                            tag="a"
                                            containerClassName="btn btn-trigger btn-icon"
                                            id={"edit" + item.id}
                                            icon="edit-alt-fill"
                                            direction="top"
                                            text="Edit"
                                          />
                                        </li>
                                        <li
                                          className="nk-tb-action-hidden"
                                          onClick={() => handleConfirmationDeleteClient(key)}
                                        >
                                          <TooltipComponent
                                            tag="a"
                                            containerClassName="btn btn-trigger btn-icon"
                                            id={"delete-" + item.id}
                                            icon="na"
                                            direction="top"
                                            text="Delete"
                                          />
                                        </li>
                                      </ul>
                                    </DataTableRow>
                                  </DataTableItem>
                                );
                              })}
                            </DataTableBody>
                          </DataTable>
                        </Block>
                      </Col>
                    </Row>
                  )}
                </>
              )}

              <>
                <div className="card-head col-xl-12">
                  <div>
                    <h5 className="card-title">Client Users</h5>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      size="md"
                      onClick={(e) => {
                        setUserId("");
                        toggleUserModal();
                        updateUserEditMode.off()
                      }}
                    >
                      <Icon name="plus" />
                      <span> Add User</span>
                    </Button>
                  </div>
                </div>
                {(!users.length && <></>) || (
                  <Row className="col-xl-12">
                    <Col lg="12">
                      <Block className="border border-light">
                        <DataTable className="card-stretch">
                          <DataTableBody compact>
                            <DataTableHead>
                              <DataTableRow>
                                <span className="sub-text">First Name</span>
                              </DataTableRow>
                              <DataTableRow>
                                <span className="sub-text">Last Name</span>
                              </DataTableRow>
                              <DataTableRow>
                                <span className="sub-text">Email</span>
                              </DataTableRow>
                              <DataTableRow>
                                <span className="sub-text" style={{ textAlign: "end" }}>
                                  Actions
                                </span>
                              </DataTableRow>
                            </DataTableHead>
                            {users.map((item, key) => {
                              return (
                                <DataTableItem key={key}>
                                  <DataTableRow>
                                    <div className="user-card">
                                      <div className="user-name">
                                        <span className="tb-lead">{item.firstName || "-"}</span>
                                      </div>
                                    </div>
                                  </DataTableRow>
                                  <DataTableRow>
                                    <div className="user-card">
                                      <div className="user-name">
                                        <span className="tb-lead">{item.lastName || "-"}</span>
                                      </div>
                                    </div>
                                  </DataTableRow>
                                  <DataTableRow>
                                    <div className="user-card">
                                      <div className="user-name">
                                        <span className="tb-lead">{item.email || "-"}</span>
                                      </div>
                                    </div>
                                  </DataTableRow>

                                  <DataTableRow className="nk-tb-col-tools">
                                    <ul className="nk-tb-actions gx-1">
                                      <li /*className="nk-tb-action-hidden"*/ onClick={() => handleUserEdit(key)}>
                                        <TooltipComponent
                                          tag="a"
                                          containerClassName="btn btn-trigger btn-icon"
                                          id={"edit" + item.id}
                                          icon="edit-alt-fill"
                                          direction="top"
                                          text="Edit"
                                        />
                                      </li>
                                      <li
                                        className="nk-tb-action-hidden"
                                        onClick={() => handleConfirmationDeleteClientUser(key)}
                                      >
                                        <TooltipComponent
                                          tag="a"
                                          containerClassName="btn btn-trigger btn-icon"
                                          id={"delete-" + item.id}
                                          icon="na"
                                          direction="top"
                                          text="Delete"
                                        />
                                      </li>
                                    </ul>
                                  </DataTableRow>
                                </DataTableItem>
                              );
                            })}
                          </DataTableBody>
                        </DataTable>
                      </Block>
                    </Col>
                  </Row>
                )}
              </>

              {isEditMode && (
                <Row className="col-xl-12">
                  <Col lg="12">
                    <Block>
                      {clientUserData.length ? (
                        <Row className="col-xl-12 py-3 m-0">
                          <Col lg="12">
                            <Block className="border border-light">
                              <DataTable className="card-stretch">
                                <DataTableBody compact>
                                  <DataTableHead>
                                    <DataTableRow>
                                      <span className="sub-text">Full Name</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <span className="sub-text">email</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <span className="sub-text">Status</span>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <span className="sub-text" style={{ textAlign: "end" }}>
                                        Actions
                                      </span>
                                    </DataTableRow>
                                  </DataTableHead>
                                  {clientUsers.map((item, key) => {
                                    return (
                                      <DataTableItem key={key}>
                                        <DataTableRow>
                                          <div className="user-card">
                                            <div className="user-name">
                                              {item.clientUser?.firstName || item.clientUser?.lastName ? (
                                                <span className="tb-lead">
                                                  {item.clientUser?.firstName} {item.clientUser?.lastName}
                                                </span>
                                              ) : (
                                                <span>{"-"}</span>
                                              )}
                                            </div>
                                          </div>
                                        </DataTableRow>
                                        <DataTableRow>
                                          <div className="user-card">
                                            <div className="user-name">
                                              <span className="tb-lead">{item.clientUser?.email || "-"}</span>
                                            </div>
                                          </div>
                                        </DataTableRow>
                                        <DataTableRow>
                                          <div className="user-card">
                                            <div className="user-name">
                                              <span className="tb-lead">{item.clientUser?.status || "-"}</span>
                                            </div>
                                          </div>
                                        </DataTableRow>
                                        <DataTableRow className="nk-tb-col-tools">
                                          <ul className="nk-tb-actions gx-1">
                                            <li>
                                              <UncontrolledDropdown>
                                                <DropdownToggle
                                                  tag="a"
                                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                                >
                                                  <Icon name="more-h"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                  <ul className="link-list-opt no-bdr">
                                                    <React.Fragment>
                                                      <li
                                                        className="nk-tb-action-hidden"
                                                        onClick={() => onClientUserEditClick(item.clientUser.id)}
                                                      >
                                                        <DropdownItem
                                                          tag="a"
                                                          href="#edit"
                                                          onClick={(ev) => {
                                                            ev.preventDefault();
                                                          }}
                                                        >
                                                          <Icon name="edit-alt-fill"></Icon>
                                                          <span>Edit User</span>
                                                        </DropdownItem>
                                                      </li>
                                                      <>
                                                        <li className="divider"></li>
                                                        <li
                                                          onClick={() => {
                                                            setModal(true);
                                                            setClientUserId(item.clientUser.id);
                                                          }}
                                                        >
                                                          <DropdownItem
                                                            tag="a"
                                                            href="#delete"
                                                            onClick={(ev) => {
                                                              setModalTab("1");
                                                              ev.preventDefault();
                                                            }}
                                                          >
                                                            <Icon name="edit"></Icon>
                                                            <span>Reset Password</span>
                                                          </DropdownItem>
                                                        </li>
                                                      </>
                                                    </React.Fragment>
                                                  </ul>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </li>
                                          </ul>
                                        </DataTableRow>
                                      </DataTableItem>
                                    );
                                  })}
                                </DataTableBody>
                              </DataTable>
                            </Block>
                          </Col>
                        </Row>
                      ) : (
                        !users.length && <div className="pl-4 pb-3">No Client Users</div>
                      )}
                    </Block>
                  </Col>
                </Row>
              )}
              {isEditMode && currentViewClient && (
                <Col lg="12">
                  <FormGroup className="form-group">
                    <div className="form-control-wrap">
                      <Attachments
                        module={CLIENT}
                        id={currentViewClient.id}
                        attachments={currentViewClient.attachment}
                        subModule={""}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
        <ModalViewer
          size="md"
          title="Plant Creation"
          isOpen={isOpen}
          toggleModal={toggleModal}
          component={getComponent()}
        />
        <ModalViewer
          size="md"
          title="User Creation"
          isOpen={isuserOpen}
          toggleModal={toggleUserModal}
          component={getUserComponent()}
        />

        <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
          <ModalBody>
            <a
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                setModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title mb-4">Change Password</h5>

              <div className="tab-content">
                <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                  <Row className="gy-4">
                    <Col md="6">
                      <FormGroup>
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="new-password">
                            New Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="#new-password"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setPasswordState(!passwordState);
                            }}
                            className={`form-icon lg form-icon-right passcode-switch ${
                              passwordState ? "is-hidden" : "is-shown"
                            }`}
                          >
                            <Icon name="eye" className="passcode-icon icon-show"></Icon>

                            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                          </a>
                          <input
                            {...updatePasswordRegister("newPassword", { required: true, validate: true })}
                            type={passwordState ? "text" : "password"}
                            id="new-password"
                            autoComplete="new-password"
                            placeholder="New password"
                            className="form-control"
                          />
                        </div>

                        {updatePasswordError.newPassword?.message && (
                          <span style={{ color: "red" }} className="invalid">
                            {updatePasswordError.newPassword?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="confirm-password">
                            Confirm Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <input
                            {...updatePasswordRegister("confirmPassword", { required: true, validate: true })}
                            type="password"
                            id="confirm-password"
                            placeholder="confirm password"
                            className="form-control"
                          />
                        </div>
                        {updatePasswordError.confirmPassword?.message && (
                          <span style={{ color: "red" }} className="invalid">
                            {updatePasswordError.confirmPassword?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            isLoading={inlineLoading}
                            color="primary"
                            size="lg"
                            onClick={handleUpdatePassword(handlePasswordSubmit)}
                          >
                            Reset Password
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal(false);
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Block>
    </>
  );
};

export default ClientForm;
