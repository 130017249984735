import React, { useContext } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../providers/AuthContext.provider";
import { ResetPasswordContext, withResetPasswordProvider } from "./providers/ResetPasswordContext.provider";
import { ADMIN_LOGIN_PAGE } from "../../constants/routes";
import Swal from "sweetalert2";
import { errorToast } from "../../components/toastify/Toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CompanyLogo } from "../../constants/constants";

const createSchema = yup.object({
  newPassword: yup
    .string()
    .required("new password is required")
    .min(8, "Passwords should fall within the range of 8 to 32 characters")
    .default(""),
  confirmPassword: yup.string().required("confirm password is required").default(""),
});

const ResetPassword = () => {
  const history = useHistory();

  const { resetPasswordToken } = useParams();

  const authContext = useContext(AuthContext);
  const resetPasswordContext = useContext(ResetPasswordContext);

  const { loading, setLoading } = resetPasswordContext;
  const { passState, setPassState } = resetPasswordContext;
  const { errorVal } = resetPasswordContext;

  const userFormRef = useForm({
    resolver: yupResolver(createSchema),
  });

  const confirmationSweetAlert = () => {
    Swal.fire({
      text: "Reset Password is successfull",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "Redirect to Login Page",
    }).then((result) => {
      if (result.isConfirmed) {
        history.push(ADMIN_LOGIN_PAGE);
      }
    });
  };
  const onFormSubmit = async (formData) => {
    setLoading(true);
    try {
      if (formData.newPassword === formData.confirmPassword) {
        await authContext.onResetPassword(resetPasswordToken, formData);
        confirmationSweetAlert();
      } else if (formData.newPassword !== formData.confirmPassword) {
        errorToast({ description: "Both password should be same" });
      }
    } catch (e) {
      errorToast({ description: `${e}` });
    } finally {
      setLoading(false);
    }
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = userFormRef;

  return (
    <React.Fragment>
      <Head title="Brighter App  | Reset Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={CompanyLogo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={CompanyLogo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Create New Password</BlockTitle>
                <BlockDes>
                  <p>Your new password must be different from your previous password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter">
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    New Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#newPassword"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="newPassword"
                    name="newPassword"
                    {...register("newPassword")}
                    placeholder="new password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                </div>
                {errors.newPassword?.message && (
                  <span style={{ color: "red" }} className="invalid">
                    {errors.newPassword?.message}
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="confirmPassword" name="Confirm Password" />
                </div>
                <div className="form-control-wrap">
                  <input
                    type={"password"}
                    id="confirmPassword"
                    name="confirmPassword"
                    {...register("confirmPassword", { required: true })}
                    placeholder="confirm password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                </div>
                {errors.confirmPassword?.message && (
                  <span style={{ color: "red" }} className="invalid">
                    {errors.confirmPassword?.message}
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" onClick={handleSubmit(onFormSubmit)} color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Reset Password"}
                </Button>
              </FormGroup>
            </Form>
            {/* {renderFormCardFooter()} */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};

export default withResetPasswordProvider(ResetPassword);
