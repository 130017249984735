/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import EditForm from "../components/EditForm";
import { withFormManagementProvider, FormManagementContext } from "../FormManagementProvider";

const FormEditManagementContainer = (props) => {
  const params = useParams();
  const { formId } = params;

  const { loadForm } = useContext(FormManagementContext);

  const title = `Brighter App | Form | Edit Layout`;

  useEffect(() => {
    if (formId) {
      loadForm(formId);
    }
  }, [formId]);

  return (
    <React.Fragment>
      <Head title={title} />
      <Content>
        <EditForm formId={formId} />
      </Content>
    </React.Fragment>
  );
};

export default withFormManagementProvider(FormEditManagementContainer);
