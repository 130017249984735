import {
    COMMENT_LIST_API,
    CREATE_COMMENT_API,
    DELETE_COMMENT_API,
    GET_COMMENT_API,
    UPDATE_COMMENT_API
} from "../constants/endpoints";
import apiService from "../services/ApiService";


export const getCommentsList = async filterParams => {
    const response = await apiService.get(COMMENT_LIST_API, {
        queryParams: filterParams,
    })
    return response
}

export const getComment = async commentId => {
    const response = await apiService.get(GET_COMMENT_API, {
        pathParams: {
            commentId
        }
    })
    return response
}

export const updateComment = async (commentId, params) => {
    const response = await apiService.put(UPDATE_COMMENT_API, {
        pathParams: {
            commentId,
        },
        body: params
    })
    return response
}

export const createComment = async (params) => {
    const response = await apiService.post(CREATE_COMMENT_API, {
        body: params
    })
    return response
}

export const deleteComment = async commentId => {
    const response = await apiService.delete(DELETE_COMMENT_API, {
        pathParams: {
            commentId
        }
    })
    return response
}
