import { format } from "date-fns";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Col, FormGroup, Row, Spinner } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../../components/Component";
import Attachments from "../../../components/attachments/Attachment";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { CLIENT } from "../../../constants/PreferenceKeys";
import { CLIENT_MANAGEMENT_EDIT_PAGE, CLIENT_MANAGEMENT_PAGE } from "../../../constants/routes";
import Head from "../../../layout/head/Head";
import { ClientManagementContext } from "../ClientManagementProvider";

const ClientView = ({ sm, updateSm }) => {
  const clientManagementContext = useContext(ClientManagementContext);
  const { currentViewClient, deleteClient, clientUsers, logoAttachment } = clientManagementContext;

  if (!currentViewClient) return <Spinner />;

  const handleDeleteClient = async (clientId) => {
    deleteClient(clientId)
      .then((res) => {
        successToast({ description: "Client deleted successfully" });
        window.location.href = "/clients";
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting client" });
      });
  };
  const handleConfirmationDeleteClient = async (clientId) => {
    confirmationSweetAlert({
      id: clientId,
      handleConfirmation: handleDeleteClient,
    });
  };
  return (
    <>
      <BlockHeadContent className="mb-1">
        <Head title="Brighter App | Client | View" />
        <Link to={CLIENT_MANAGEMENT_PAGE}>
          <Button id="client-back-btn" color="light" outline className="bg-white d-none d-sm-inline-flex">
            <Icon name="arrow-left"></Icon>
            <span>Back</span>
          </Button>
        </Link>
      </BlockHeadContent>
      <Block className={"bg-white p-4"}>
        <BlockHead size="md">
          <div className={"d-md-flex d-block justify-content-between"}>
            <BlockHead>
              <BlockTitle tag="h5">
                {logoAttachment && <img className="logo-small logo-img logo-img-small mr-1" src={logoAttachment} />}
                {currentViewClient.name}'s Information
              </BlockTitle>
              <p>Basic info, like your name and address, that you use on Platform.</p>
            </BlockHead>
            <BlockHeadContent>
              <Check permission={["updateClient"]}>
                <Button
                  id="client-del-btn"
                  color="danger"
                  className={"mr-2"}
                  onClick={() => {
                    handleConfirmationDeleteClient(currentViewClient.id);
                  }}
                >
                  <Icon name="trash" />
                  <span>Delete</span>
                </Button>
              </Check>
              <Check permission={["updateClient"]}>
                <Link to={CLIENT_MANAGEMENT_EDIT_PAGE.replace(":clientId", currentViewClient.id)}>
                  <Button id="client-edit-btn" color="primary">
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Button>
                </Link>
              </Check>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block size="lg">
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Client Name</span>
                <span className="profile-ud-value">{currentViewClient.name || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Phone Number</span>
                <span className="profile-ud-value">{currentViewClient.phoneNumber || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Address</span>
                <span className="profile-ud-value">{currentViewClient.addressLine1 || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Mobile Number</span>
                <span className="profile-ud-value">{currentViewClient.mobileNumber || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">City</span>
                <span className="profile-ud-value">{currentViewClient.city || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Postal Code</span>
                <span className="profile-ud-value">{currentViewClient.postalCode || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"> Email</span>
                <span className="profile-ud-value">{currentViewClient.email || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Website</span>
                <span className="profile-ud-value">
                  {currentViewClient.website ? (
                    <a
                      target={"_blank"}
                      href={`${
                        currentViewClient.website.includes("https://")
                          ? currentViewClient.website
                          : "https://" + currentViewClient.website
                      }`}
                      rel="noreferrer"
                    >
                      {currentViewClient.website}
                    </a>
                  ) : (
                    "-"
                  )}
                </span>
              </div>
            </div>

            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Created At</span>
                <span className="profile-ud-value">
                  {format(new Date(currentViewClient?.created_at || null), "dd/MM/yyyy HH:mm")}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Updated At</span>
                <span className="profile-ud-value">
                  {format(new Date(currentViewClient?.updated_at || null), "dd/MM/yyyy HH:mm")}
                </span>
              </div>
            </div>
          </div>
        </Block>
      </Block>
      <Block className={"bg-white mt-3"}>
        {
          <Col lg="12" className=" px-4">
            <FormGroup className="form-group">
              <div className="form-control-wrap">
                <Attachments
                  module={CLIENT}
                  id={currentViewClient.id}
                  attachments={currentViewClient.attachment}
                  subModule={"view"}
                />
              </div>
            </FormGroup>
          </Col>
        }
      </Block>
      <Block className={"bg-white my-3"}>
        <div className="d-flex align-items-center pl-4 pb-1">
          <BlockTitle tag="h6">Plants</BlockTitle>
        </div>
        {(!currentViewClient.plants.length && (
          <>
            <div className="pl-4 pb-3">NA</div>
          </>
        )) || (
          <Row className="col-xl-12 py-3 m-0">
            <Col lg="12">
              <Block className="border border-light">
                <DataTable className="card-stretch">
                  <DataTableBody compact>
                    <DataTableHead>
                      <DataTableRow>
                        <span className="sub-text">PV plant Name</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="sub-text">On Boarding Date</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Status</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="sub-text">Size</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="sub-text">Acronym</span>
                      </DataTableRow>
                    </DataTableHead>
                    {currentViewClient.plants.map((item, key) => {
                      return (
                        <DataTableItem key={key}>
                          <DataTableRow>
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">{item.name || "-"}</span>
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">
                                  {item.onboardedAt ? format(new Date(item.onboardedAt), "dd/MM/yyyy") : "-"}
                                </span>
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">{item.status || "-"}</span>
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">{item.size || "-"}</span>
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">{item.identifier || "-"}</span>
                              </div>
                            </div>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })}
                  </DataTableBody>
                </DataTable>
              </Block>
            </Col>
          </Row>
        )}
      </Block>
      <Block className={"bg-white my-3"}>
        <div className="d-flex align-items-center pl-4 pb-1">
          <BlockTitle tag="h6">Client Users</BlockTitle>
        </div>
        {(!clientUsers.length && (
          <>
            <div className="pl-4 pb-3">NA</div>
          </>
        )) || (
          <Row className="col-xl-12 py-3 m-0">
            <Col lg="12">
              <Block className="border border-light">
                <DataTable className="card-stretch">
                  <DataTableBody compact>
                    <DataTableHead>
                      <DataTableRow>
                        <span className="sub-text">Full Name</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">email</span>
                      </DataTableRow>
                      <DataTableRow>
                        <span className="sub-text">Status</span>
                      </DataTableRow>
                    </DataTableHead>
                    {clientUsers.map((item, key) => {
                      return (
                        <DataTableItem key={key}>
                          <DataTableRow>
                            <div className="user-card">
                              <div className="user-name">
                                {item.clientUser?.firstName || item.clientUser?.lastName ? (
                                  <span className="tb-lead">
                                    {item.clientUser?.firstName} {item.clientUser?.lastName}
                                  </span>
                                ) : (
                                  <span>{"-"}</span>
                                )}
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">{item.clientUser?.email || "-"}</span>
                              </div>
                            </div>
                          </DataTableRow>
                          <DataTableRow>
                            <div className="user-card">
                              <div className="user-name">
                                <span className="tb-lead">{item.clientUser?.status || "-"}</span>
                              </div>
                            </div>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })}
                  </DataTableBody>
                </DataTable>
              </Block>
            </Col>
          </Row>
        )}
      </Block>
    </>
  );
};
export default ClientView;
