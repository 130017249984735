import { format } from "date-fns";
import React, { useContext } from "react";
import { Col, FormGroup, Spinner } from "reactstrap";
import { Block, BlockBetween, BlockHead, BlockHeadContent, Button, Icon } from "../../../../components/Component";
import Attachments from "../../../../components/attachments/Attachment";
import Check from "../../../../components/check_roles/check";
import { ASSET } from "../../../../constants/PreferenceKeys";
import Head from "../../../../layout/head/Head";
import { AssetContext } from "../AssetProvider";

const ViewAsset = ({ sm, updateSm, editAsset }) => {
  const assetContext = useContext(AssetContext);
  const { currentViewAsset } = assetContext;

  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter App | Asset | View" />
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md" className="d-flex justify-content-end">
          <BlockBetween>
            <BlockHeadContent className="align-self-end">
              <Check permission={["manageEntity"]}>
                <Button
                  size="sm"
                  color="primary"
                  className="m-2"
                  onClick={() => {
                    editAsset(currentViewAsset.id);
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
              </Check>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {!currentViewAsset ? (
          <div className="m-3" style={{ display: "flex", justifyContent: "center" }}>
            <Spinner />
          </div>
        ) : (
          <Block>
            <div className="nk-data data-list">
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Model</span>
                  <span className="data-value">{currentViewAsset.model || "-"} </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Rating</span>
                  <span className="data-value">{currentViewAsset?.rating || "-"} </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Description</span>
                  <span className="data-value text-break">{currentViewAsset?.description || "-"} </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Factory Barcode</span>
                  <span className="data-value">{currentViewAsset?.factoryBarcode || "-"} </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Category</span>
                  <span className="data-value">{currentViewAsset.assetCategories?.name || "-"} </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Manufacturer</span>
                  <span className="data-value">{currentViewAsset.manufacturers?.name || "-"} </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">ProductId</span>
                  <span className="data-value">{currentViewAsset?.identifier || "-"} </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Weblink</span>
                  <span className="data-value">
                    {" "}
                    {currentViewAsset.weblink ? (
                      <a
                        target={"_blank"}
                        href={`${
                          currentViewAsset.weblink.includes("https://")
                            ? currentViewAsset.weblink
                            : "https://" + currentViewAsset.weblink
                        }`}
                        rel="noreferrer"
                      >
                        {currentViewAsset.weblink}
                      </a>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              </div>
              {
                <Col lg="12 pt-2">
                  <FormGroup className="form-group">
                    <div className="form-control-wrap">
                      <Attachments
                        module={ASSET}
                        id={currentViewAsset.id}
                        attachments={currentViewAsset.attachment}
                        subModule={"view"}
                      />
                    </div>
                  </FormGroup>
                </Col>
              }
              <div className="m-2 ml-3">
                <small class="text-muted">
                  CreatedAt: {format(new Date(currentViewAsset?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
                </small>
              </div>
              <div className="m-2 ml-3">
                <small class="text-muted">
                  UpdatedAt: {format(new Date(currentViewAsset?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
                </small>
              </div>
            </div>
          </Block>
        )}
      </Block>
    </div>
  );
};
export default ViewAsset;
