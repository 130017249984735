import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  getFailureReasonAPI,
  updateFailureReasonAPI,
  createFailureReasonAPI,
  deleteFailureReasonAPI,
  getFailureReasonList,
  getFailureReasonFilterListAPI,
} from "./FailureReasonRepository";
export const FailureReasonContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterOptions = {
  failureReasons: [],
};

const initialFilterParams = {
  searchText: "",
  sortingOrder: "",
  sortingField: "",
  selectedFailureReasons: [],
  createdAt: "",
  updatedAt: "",
};

export const FailureReasonProvider = (props) => {
  const [failureReasonList, setFailureReasonList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [currentViewFailureReason, setCurrentViewFailureReason] = React.useState();
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));

  //Loaders
  const [failureReasonLoading, frState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [showFilter, sfState] = useBoolean(false);

  const getListFilterParams = () => {
    const queryParams = { ...pagination };
    const { searchText, sortingField, sortingOrder, selectedFailureReasons, createdAt, updatedAt } = filterParams;
    if (searchText) queryParams["searchText"] = searchText;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (selectedFailureReasons) queryParams["category"] = selectedFailureReasons;
    if (createdAt) queryParams["createdAt"] = createdAt;
    if (updatedAt) queryParams["updatedAt"] = updatedAt;
    return queryParams;
  };

  const loadFailureReasonList = async () => {
    frState.on();

    try {
      const filterParams = getListFilterParams();
      const response = await getFailureReasonList(filterParams);
      setFailureReasonList(response.failureReasonList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      frState.off();
    }
  };

  //CREATE ASSET_CATEGORY
  const createFailureReason = async (createParams) => {
    try {
      frState.on();
      await createFailureReasonAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      frState.off();
    }
  };
  // VIEW ASSET_CATEGORY
  const loadFailureReason = async (failureReasonId) => {
    try {
      const response = await getFailureReasonAPI(failureReasonId);
      setCurrentViewFailureReason(response.failureReason);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  // UPDATE ASSET_CATEGORY
  const updateFailureReason = async (failureReasonId, updateParams) => {
    frState.on();
    try {
      const response = await updateFailureReasonAPI(failureReasonId, updateParams);
      setCurrentViewFailureReason(response.failureReason);
    } catch (e) {
      throw e;
    } finally {
      frState.off();
    }
  };

  // DELETE ASSET_CATEGORY
  const deleteFailureReason = async (failureReasonId) => {
    try {
      await deleteFailureReasonAPI(failureReasonId);
      loadFailureReasonList();
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getFailureReasonFilterListAPI();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const checkIsFilterApplied = () => {
    if (filterParams.selectedFailureReasons != 0 || filterParams.createdAt || filterParams.updatedAt) {
      return true;
    } else return false;
  };

  const isFilterApplied = checkIsFilterApplied();

  const resetFailureReasonListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const mContext = {
    failureReasonList,
    pagination,
    loadFailureReasonList,
    currentViewFailureReason,
    failureReasonLoading,
    deleteFailureReason,
    createFailureReason,
    updateFailureReason,
    loadFailureReason,
    handleApplyFilter,
    filterParams,
    resetFailureReasonListFilter,
    loadFilterOptions,
    filtersLoading,
    filterOptions,
    showFilter,
    sfState,
    isFilterApplied,
    checkIsFilterApplied,
    updateShowListSize: (size) => setPagination({ ...pagination, size }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <FailureReasonContext.Provider value={mContext}>{props.children}</FailureReasonContext.Provider>;
};

export const withFailureReasonProvider = (Container, containerProps) => (props) =>
  (
    <FailureReasonProvider>
      <Container {...containerProps} {...props} />
    </FailureReasonProvider>
  );
