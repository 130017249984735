/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";
import { AssetContext } from "../AssetProvider";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Attachments from "../../../../components/attachments/Attachment";
import { ASSET } from "../../../../constants/PreferenceKeys";
import CreateFormAttachments from "../../../../components/attachments/CreateFormAttachment";
import { sortOption } from "../../../../utils/Utils";
import Head from "../../../../layout/head/Head";

const createSchema = yup.object({
  model: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "model should be atleast one character"),
  description: yup.string().notRequired().default(""),
  assetCategoryId: yup.number().required(),
  manufacturerId: yup.number().required(),
  factoryBarcode: yup.string().notRequired().default(""),
  rating: yup.string().notRequired().default(""),
  identifier: yup.string().notRequired().default(""),
  weblink: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      { message: "Website is not valid eg(google.com)", excludeEmptyString: true }
    )
    .nullable()
    .optional(),
});

const updateSchema = yup.object({
  model: yup
    .string()
    .required()
    .matches(/^(\s*\S.*)/g, "model should be atleast one character", "only blank spaces are not allowed"),
  description: yup.string().notRequired().default(""),
  assetCategoryId: yup.number().required(),
  manufacturerId: yup.number().required(),
  factoryBarcode: yup.string().notRequired().default(""),
  rating: yup.string().notRequired().default(""),
  identifier: yup.string().notRequired().default(""),
  weblink: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      { message: "Website is not valid eg(google.com)", excludeEmptyString: true }
    )
    .nullable()

    .optional(),
});

const AssetForm = (props) => {
  const { createAsset, manufacutrerList, assetCategoryList, identifier, assetLoading } = useContext(AssetContext);

  const [isEditMode, updateEditMode] = useBoolean(false);

  const [attachments, setAttachments] = useState([]);

  const assetFormRef = useForm({
    resolver: yupResolver(props.assetId ? updateSchema : createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = assetFormRef;

  useEffect(() => {
    reset(props.currentViewAsset);
    if (props.currentViewAsset) updateEditMode.on();
  }, [props.currentViewAsset]);

  const handleFormSubmit = (formData) => {
    if (props.assetId) {
      props
        .updateAsset(props.currentViewAsset.id, { ...formData })
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
        });
    } else {
      createAsset({ ...formData, identifier, attachments })
        .then((res) => {
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `${e.message}` });
        });
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="add-assetForm"
        color="primary"
        size="lg"
        isLoading={assetLoading}
        onClick={assetLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return isDirty ? (
      <Button
        id="save-assetForm"
        color="primary"
        isLoading={assetLoading}
        size="lg"
        onClick={assetLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Save Information
      </Button>
    ) : (
      <Button id="cancel-assetForm" size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
        Cancel
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  const attachmentFiles = (files) => {
    if (files) {
      setAttachments(files);
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head title={props.assetId ? "Brighter App | Asset | Edit" : "Brighter App | Asset | Create"} />
      <Block size="lg">
        <PreviewCard>
          <Form id="submit-assetForm" className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="model">
                    <span style={{ color: "indianred" }}>&#42;</span>Model
                  </Label>
                  <div className="form-control-wrap">
                    <input {...register("model")} type="text" name="model" className="form-control" />
                  </div>
                  {errors.model && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.model?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="rating">
                    Rating
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="rating-input-assetForm"
                      {...register("rating")}
                      type="text"
                      name="rating"
                      className="form-control"
                    />
                  </div>
                  {errors.rating && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.rating?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              {isEditMode && props.currentViewAsset && (
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="identifier">
                      ProductId
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        id="productid-input-assetForm"
                        {...register("identifier")}
                        disabled
                        type="text"
                        name="identifier"
                        className="form-control"
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}
              {!isEditMode && (
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="identifier">
                      ProductId
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        id="productid-input-assetForm"
                        {...register("identifier")}
                        type="text"
                        name="identifier"
                        value={identifier}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}
              <Col lg="6">
                <FormGroup className="form-group">
                  <label className="form-label" htmlFor="factoryBarcode">
                    Factory Barcode
                  </label>
                  <div className="form-control-wrap">
                    <input
                      id="factroy-input-assetForm"
                      {...register("factoryBarcode")}
                      type="text"
                      name="factoryBarcode"
                      className="form-control"
                    />
                  </div>
                  {errors.factoryBarcode && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.factoryBarcode?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="assetCategoryId">
                    <span style={{ color: "indianred" }}>&#42;</span>Category
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="assetCategoryId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = assetCategoryList;
                        const selectedValue = options.find((e) => e.value === field.value);

                        return (
                          <RSelect
                            id="category-select-assetForm"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            placeholder="Select the Category "
                            options={sortOption(options)}
                            onChange={(o) => setValue("assetCategoryId", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors.assetCategoryId && (
                      <span className="invalid" style={{ color: "red" }}>
                        assetCategory is required
                      </span>
                    )}
                  </div>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="manufacturerId">
                    <span style={{ color: "indianred" }}>&#42;</span>Manufacturer
                  </Label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="manufacturerId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = manufacutrerList;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            id="manufacture-select-assetForm"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            placeholder="Select the Manufacturer "
                            options={sortOption(options)}
                            onChange={(o) => setValue("manufacturerId", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors.manufacturerId && (
                      <span className="invalid" style={{ color: "red" }}>
                        manufacturer is required
                      </span>
                    )}
                  </div>
                </FormGroup>
              </Col>
              <Col lg="12" md="12">
                <FormGroup className="form-group">
                  <label className="form-label">Weblink</label>
                  <div className="form-control-wrap">
                    <input type="text" className="form-control" {...register("weblink")} />
                  </div>
                  {errors.weblink && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.weblink?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup className="form-group">
                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field, ref }) => {
                        return (
                          <textarea
                            id="description-input-assetForm"
                            name="description"
                            value={field.value}
                            {...register("description")}
                            className="form-control"
                          />
                        );
                      }}
                    />
                  </div>
                  {errors.description && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors.description?.message}
                    </span>
                  )}
                </FormGroup>
              </Col>

              {isEditMode && props.currentViewAsset && (
                <Col lg="12">
                  <FormGroup className="form-group">
                    <div id="asset-attachment-assetForm" className="form-control-wrap">
                      <Attachments
                        module={ASSET}
                        id={props.currentViewAsset.id}
                        attachments={props.currentViewAsset.attachment}
                        subModule={""}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}
              {!isEditMode && (
                <Col lg="12">
                  <FormGroup className="form-group">
                    <div className="form-control-wrap">
                      <CreateFormAttachments attachmentFiles={attachmentFiles} />
                    </div>
                  </FormGroup>
                </Col>
              )}
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default AssetForm;
