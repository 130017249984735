/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";

import "react-datepicker/dist/react-datepicker.css";
import { SelfOwnedInventoryContext } from "../SelfOwnedProvider";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Attachments from "../../../../components/attachments/Attachment";
import { STOCK } from "../../../../constants/PreferenceKeys";

const createSchema = yup.object({
  contractualQuantity: yup
    .number()
    .typeError("Specify a Number")
    .integer("Invalid Number")
    .max(10000, "Max value 10000.")
    .default(0),
  quantity: yup
    .number()
    .required("This field is required")
    .typeError("Specify a Number")
    .min(0, "Min value 0.")
    .integer("Invalid Number")
    .max(10000, "Max value 10000."),
  description: yup.string().trim(),
  isKeyComponent: yup.boolean().required().default(false),
  category: yup.string().required(),
  equipmentName: yup.string().required(),
});

const SelfOwnedForm = (props) => {
  const { filterOptions, createInventory, updateInventory } = useContext(SelfOwnedInventoryContext);
  const [isCategory, updateIsCategory] = useState("");
  const [isEditMode, updateEditMode] = useBoolean(false);
  const [supplier, setSupplier] = useState();
  const [loading, setLoading] = useBoolean();

  const userFormRef = useForm({
    resolver: yupResolver(props.taskId ? createSchema : createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = userFormRef;
  let manufacturer = {};

  useEffect(() => {
    reset(props.currentViewInventory);
    if (props.currentViewInventory) {
      setSupplier(props.currentViewInventory.assets.manufacturerId);
      reset({
        contractualQuantity: props.currentViewInventory.contractualQuantity,
        quantity: props.currentViewInventory.quantity,
        description: props.currentViewInventory.description,
        isKeyComponent: props.currentViewInventory.isKeyComponent,
        category: props.currentViewInventory.assets.assetCategoryId,
        equipmentName: props.currentViewInventory.assets.manufacturerId,
      });

      updateEditMode.on();
    }
  }, [props.currentViewInventory]);

  for (let i = 0; i < filterOptions?.manufacturer?.length; i++) {
    manufacturer[filterOptions?.manufacturer[i].value] = filterOptions?.manufacturer[i].label;
  }
  const handleFormSubmit = (formData) => {
    if (props.inventoryId) {
      const { equipmentName, category, ...rest } = formData;
      const data = { ...rest, assetId: parseInt(category) };

      updateInventory(props.inventoryId, { ...data })
        .then((res) => {
          successToast({ description: "Successfully Updated" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `Error, ${e.message}` });
        });
    } else {
      const { equipmentName, category, ...rest } = formData;
      const data = { ...rest, assetId: parseInt(category) };

      createInventory({ ...data })
        .then((res) => {
          successToast({ description: "Successfully Created" });
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: `Error, ${e.message}` });
        });
    }
  };
  let asset = {};
  filterOptions.asset?.map((item) => {
    if (asset[item.assetCategoryId]) {
      asset[item.assetCategoryId].push({
        value: item.value,
        label: item.label,
        assetCategoryId: item.assetCategoryId,
        manufacturerId: item.manufacturerId,
      });
    } else {
      asset[item.assetCategoryId] = [
        {
          value: item.value,
          label: item.label,
          assetCategoryId: item.assetCategoryId,
          manufacturerId: item.manufacturerId,
        },
      ];
    }
  });

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button color="primary" size="lg" disabled={loading} onClick={handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <Button color="primary" size="lg" disabled={loading} onClick={handleSubmit(handleFormSubmit)}>
        Update
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Category
                  </Label>
                  <Controller
                    control={control}
                    name="category"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = filterOptions?.assetCategory;
                      const selectedValue = options?.find((e) => e.value === field.value);

                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={options}
                          onChange={(o) => {
                            setValue("category", o.value);
                            updateIsCategory(getValues("category"));
                            setValue("equipmentName", null);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.category && <span className="invalid">Category is required</span>}
                </FormGroup>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Equipment Name
                  </Label>
                  <Controller
                    control={control}
                    name="equipmentName"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options =
                        asset[isCategory] || asset[props.currentViewInventory?.assets?.assetCategoryId] || [];
                      const selectedValue = options?.find((e) => e.value === field.value);

                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={options}
                          onChange={(o) => {
                            setSupplier(o.manufacturerId);
                            setValue("equipmentName", o.value);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.equipmentName && <span className="invalid">Equipment Name is required</span>}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-group">
                  <label className="form-label">Contractual Quantity</label>
                  <div className="form-control-wrap">
                    <input type="text" className="form-control" {...register("contractualQuantity")} />
                  </div>
                  {errors?.contractualQuantity && (
                    <span className="invalid">{errors?.contractualQuantity.message}</span>
                  )}
                </FormGroup>
                <FormGroup className="form-group">
                  <label className="form-label">Quantity</label>
                  <div className="form-control-wrap">
                    <input type="text" className="form-control" {...register("quantity")} />
                  </div>
                  {errors?.quantity && <span className="invalid">{errors?.quantity.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Supplier
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled="true"
                      type="text"
                      name="addressLine1"
                      className="form-control"
                      value={manufacturer[supplier]}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="form-group">
                  <label className="form-label">Key Component</label>
                  <div className="form-control-wrap">
                    <div class="custom-control custom-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="isKeyComponent"
                        {...register("isKeyComponent")}
                      />
                      <label class="custom-control-label" for="isKeyComponent">
                        Yes
                      </label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-group">
                  <label className="form-label">Details</label>
                  <div className="form-control-wrap">
                    <textarea className="form-control" {...register("description")} />
                  </div>
                  {errors?.description && <span className="invalid">{errors?.description.message}</span>}
                </FormGroup>
              </Col>
              {isEditMode && props.currentViewInventory && (
                <Col lg="12">
                  <FormGroup className="form-group">
                    <div className="form-control-wrap">
                      <Attachments
                        module={STOCK}
                        id={props.currentViewInventory.id}
                        attachments={props.currentViewInventory.attachment}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}
              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default SelfOwnedForm;
