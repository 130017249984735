import { format } from "date-fns";
import download from "js-file-download";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { CORRECTIVE_FILTERS } from "../../../../constants/PreferenceKeys";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import ModalViewer from "../../ModalViewer";
import { CorrectiveContext } from "../CorrectiveProvider";
import { exportParticularTask } from "../CorrectiveRepository";
import CorrectiveMaintenanceFormContainer from "../containers/CorrectiveMaintenanceFormContainer";
import CorrectiveMaintenanceViewContainer from "../containers/CorrectiveMaintenanceViewContianer";
import CorrectiveListFilter from "./CorrectiveListFilter";
import ExportForm from "./ExportForm";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { statusColorTheme } from "../../../../constants/constants";

const CorrectiveListCompact = (props) => {
  // REFS

  const focusSearchRef = React.useRef();

  const location = useLocation().search;

  const TaskId = JSON.parse(new URLSearchParams(location).get("id"));

  const correctiveContext = useContext(CorrectiveContext);
  const { filterOptions } = useContext(MaintenanceContext);
  const { correctiveList, pagination, unarchiveTask, archiveTask } = correctiveContext;
  const {
    filterParams,
    handleApplyFilter,
    loadCorrectiveList,
    deleteTask,
    correctiveLoading,
    isFilterApplied,
    sfState,
    showFilter,
  } = correctiveContext;

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [task, setTask] = useState("");
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  let engineers = {};
  const STATUSES = {
    OPEN: "Open",
    IN_PROGRESS: "In Progress",
    READY_FOR_APPROVAL: "Ready for Approval",
    COMPLETED: "Completed",
  };
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  useEffect(() => {
    const filters = localStorage.getItem(CORRECTIVE_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
        startDate: parseFilters.startDate ? new Date(parseFilters.startDate) : "",
        resolvedDate: parseFilters.resolvedDate ? new Date(parseFilters.resolvedDate) : "",
      });
    }
  }, []);

  const handleNameSort = (sortOrder, field, table) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field, table: table });
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Corrective Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Corrective Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId)
      .then((res) => {
        successToast({ description: "Corrective Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Corrective Task" });
      });
  };

  const handleDeleteCorrective = async (taskId) => {
    deleteTask(taskId)
      .then((res) => {
        successToast({ description: "Corrective task is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Corrective task" });
      });
  };
  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteCorrective,
    });
  };
  const exportParticularTaskFuntion = async (taskId) => {
    try {
      setExportLoading(true);
      const response = await exportParticularTask({ taskId, category: "corrective" });
      download(response, `task.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };
  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };
  const currentItems = correctiveList?.items || [];
  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }

  const viewTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.VIEW);
    setIsOpen(true);
  };

  useMemo(() => {
    if (TaskId) {
      viewTask(TaskId);
    }
  }, []);

  const editTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createTask = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const onSuccessfulModal = () => {
    toggleModal();
    loadCorrectiveList();
  };
  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <CorrectiveMaintenanceViewContainer mode={VIEW.VIEW} taskId={task} setIsOpen={setIsOpen} editTask={editTask} />
      );
    } else if (view === VIEW.CREATE) {
      return (
        <CorrectiveMaintenanceFormContainer
          mode={VIEW.CREATE}
          onSuccessfulModal={onSuccessfulModal}
          viewTask={viewTask}
        />
      );
    } else if (view === VIEW.EDIT) {
      return (
        <CorrectiveMaintenanceFormContainer
          mode={VIEW.EDIT}
          taskId={task}
          onSuccessfulModal={onSuccessfulModal}
          viewTask={viewTask}
        />
      );
    }
  };
  const getExportComponent = () => {
    if (view === VIEW.CREATE) {
      return (
        <ExportForm
          onSuccessfulModal={onSuccessfulModal}
          filterParams={filterParams}
          setIsExportModalOpen={setIsExportModalOpen}
        />
      );
    }
  };
  const createExport = () => {
    setView(VIEW.CREATE);
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };

  const assignedEngineerData = filterParams.selectedAssignedEngineer.map((item) => {
    return filterOptions.assignedEngineer?.[
      filterOptions.assignedEngineer?.findIndex((status) => status.value === item)
    ];
  });

  const plantSelectedData = filterParams.selectedPlant.map((item) => {
    return filterOptions.plants?.[filterOptions.plants?.findIndex((status) => status.value === item)];
  });

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Corrective Maintenance Tickets
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {correctiveList?.pagination?.totalCount || 0} corrective maintenance tickets.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedTaskStatus} title={"Status"} isMap={false} isStr={false} />
              <FilteredByList data={assignedEngineerData} title={"Assigned Engineer"} isMap={true} isStr={false} />
              <FilteredByList data={plantSelectedData} title={"Plant"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.startDate} title={"Start Date"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.resolvedDate} title={"Resolved Date"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.searchText} title={"Searched by"} isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getTickets", "manageTickets"]}>
                    <li>
                      <Button
                        disabled={correctiveList?.pagination?.totalCount === 0}
                        className={`btn btn-white btn-outline-light`}
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <Check permission={["manageTickets"]}>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={createTask}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                    {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                    <Icon name="filter-alt"></Icon>
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Ticket Name, status and Plant Name.enter to search"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow style={{ display: "flex" }}>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                        ? handleNameSort("DESC", "title")
                        : handleNameSort("ASC", "title");
                    }}
                  >
                    Ticket Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "title");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "title"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "title");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "title"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Ticket Identifier
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "identifier"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "identifier"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    Status
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "startedAt"
                        ? handleNameSort("DESC", "startedAt")
                        : handleNameSort("ASC", "startedAt");
                    }}
                  >
                    Start Date
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "startedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "startedAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "startedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "startedAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "resolvedAt"
                        ? handleNameSort("DESC", "resolvedAt")
                        : handleNameSort("ASC", "resolvedAt");
                    }}
                  >
                    Resolved Date
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "resolvedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "resolvedAt"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "resolvedAt");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "resolvedAt"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "assignedToIds"
                        ? handleNameSort("DESC", "assignedToIds")
                        : handleNameSort("ASC", "assignedToIds");
                    }}
                  >
                    Assigned To
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "assignedToIds");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "assignedToIds"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "assignedToIds");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "assignedToIds"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>

              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                        ? handleNameSort("DESC", "name", "plants")
                        : handleNameSort("ASC", "name", "plants");
                    }}
                  >
                    Plant Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px", cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("ASC", "name", "plants");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "name"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleNameSort("DESC", "name", "plants");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "name"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>
            <Sidebar toggleState={showFilter}>
              <CorrectiveListFilter isPlantName={false} filterOptions={filterOptions} />
            </Sidebar>

            {/*Head*/}
            <div></div>
            {correctiveLoading ? (
              <></>
            ) : (
              currentItems.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <div
                        className="user-card "
                        onClick={() => {
                          viewTask(item.id);
                        }}
                      >
                        {/* data-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" */}
                        <div className="user-name">
                          <span
                            data-toggle="tooltip"
                            title={item.title}
                            style={{
                              width: "90px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            className="tb-lead"
                          >
                            {item.title || "-"}
                          </span>
                          {/* height: 18px; width: 140px; padding: 0; overflow: hidden; position: relative; display:
                          inline-block; margin: 0 5px 0 5px; text-align: center; text-decoration: none; text-overflow:
                          ellipsis; white-space: nowrap; color: #000; */}
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow id="corrective-identifier" size="md">
                      <span>
                        <span
                          data-toggle="tooltip"
                          title={item.identifier}
                          style={{
                            display: "inline-block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.identifier || "-"}
                        </span>
                      </span>
                    </DataTableRow>
                    <DataTableRow id="corrective-status" size="md">
                      <span>
                        <Badge className="text-capitalize" style={statusColorTheme[item.status]} pill>
                          {STATUSES[item.status] || "-"}
                        </Badge>
                      </span>
                    </DataTableRow>
                    <DataTableRow id="corrective-startat" size="sm">
                      <span>
                        {item?.startedAt ? format(new Date(item.startedAt ? item.startedAt : null), "dd/MM/yyyy") : "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow id="corrective-resolvedat" size="sm">
                      <span>
                        {item?.resolvedAt
                          ? format(new Date(item.resolvedAt ? item.resolvedAt : null), "dd/MM/yyyy")
                          : "-"}
                      </span>
                    </DataTableRow>

                    <DataTableRow id="corrective-assignedto" size="md">
                      <span>
                        {item?.assignedToIds
                          ?.map((id, key) => {
                            return engineers[id];
                          })
                          .join(", ") || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow id="corrective-plant-name" size="lg">
                      <span>{item.plants?.name || "-"}</span>
                    </DataTableRow>

                    <DataTableRow id="corrective-menu" className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <React.Fragment>
                                  <Check permission={["manageTickets"]}>
                                    <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit Task</span>
                                      </DropdownItem>
                                    </li>
                                  </Check>
                                  <Check permission={["manageTickets"]}>
                                    {item.isArchived ? (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => handleUnArchive(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#unarchive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="unarchive"></Icon>
                                            <span>UnArchive Task</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => handleArchive(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#archive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="archive"></Icon>
                                            <span>Archive Task</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </Check>
                                  <Check permission={["manageTickets"]}>
                                    {item.status !== "SUSPENDED" && (
                                      <>
                                        <li className="divider"></li>
                                        <li onClick={() => handleDeleteConfirmation(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#delete"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete Task</span>
                                          </DropdownItem>
                                        </li>
                                        <li className="divider"></li>
                                      </>
                                    )}
                                  </Check>
                                  <Check permission={["getTickets", "manageTickets"]}>
                                    <li isLoading={exportLoading} onClick={() => exportParticularTaskFuntion(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#export"
                                        type="export"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="download"></Icon>
                                        <span>Export</span>
                                      </DropdownItem>
                                    </li>
                                  </Check>
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {correctiveLoading && <Skeleton count={10} className="w-100" />}

          <div className="card-inner">
            {correctiveList.pagination && correctiveList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={correctiveList.pagination.totalCount}
                paginate={correctiveContext.updateShowListPage}
                currentPage={correctiveList.pagination.currentPage}
                paginatePage={correctiveContext.updateShowListSize}
              />
            ) : (
              !correctiveLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="Corrective Maintainance Ticket"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Corrective Maintenance - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default CorrectiveListCompact;
