import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation, useParams } from "react-router-dom";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
} from "../../../components/Component";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { CORRECTIVE_FILTERS } from "../../../constants/PreferenceKeys";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";
import ModalViewer from "../../maintenance/ModalViewer";
import { CorrectiveContext } from "../../maintenance/corrective/CorrectiveProvider";
import CorrectiveListFilter from "../../maintenance/corrective/components/CorrectiveListFilter";
import ExportForm from "../../maintenance/corrective/components/ExportForm";
import CorrectiveMaintenanceFormContainer from "../../maintenance/corrective/containers/CorrectiveMaintenanceFormContainer";
import CorrectiveMaintenanceViewContainer from "../../maintenance/corrective/containers/CorrectiveMaintenanceViewContianer";
import Check from "../../../components/check_roles/check";
import { statusColorTheme } from "../../../constants/constants";

const PlantCorrectiveList = (props) => {
  const location = useLocation().search;
  const status = new URLSearchParams(location).get("status");
  const tab = new URLSearchParams(location).get("tab");

  const { plantStatus, isArchived } = props;

  const params = useParams();
  const { plantId } = params;
  // REFS

  const focusSearchRef = React.useRef();
  const { deleteTask, correctiveList, pagination } = useContext(CorrectiveContext);
  const {
    filterParams,
    handleApplyFilter,
    loadCorrectiveList,
    correctiveLoading,
    isFilterApplied,
    sfState,
    showFilter,
    archiveTask,
    isSubFilterApplied,
    unarchiveTask,
  } = useContext(CorrectiveContext);
  const { filterOptions } = useContext(MaintenanceContext);
  const correctiveContext = useContext(CorrectiveContext);

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [task, setTask] = useState("");
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };
  let engineers = {};
  const currentCorrectiveItems = correctiveList?.items || [];

  const STATUSES = {
    OPEN: "Open",
    IN_PROGRESS: "In Progress",
    READY_FOR_APPROVAL: "Ready for Approval",
    COMPLETED: "Completed",
  };

  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };
  useEffect(() => {
    const filters = localStorage.getItem(CORRECTIVE_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
        startDate: parseFilters.startDate ? new Date(parseFilters.startDate) : "",
        resolvedDate: parseFilters.resolvedDate ? new Date(parseFilters.resolvedDate) : "",
      });
    }
    if (plantId && !status) {
      handleApplyFilter({ ...filterParams, selectedPlant: [parseInt(plantId)] });
    }
    if (status && plantId) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status], selectedPlant: [parseInt(plantId)] });
    }
  }, [status, plantId]);
  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  useEffect(() => {
    if (!isOpen) {
      loadCorrectiveList(plantId);
    }
  }, [isOpen]);

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Corrective Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Corrective Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId)
      .then((res) => {
        successToast({ description: "Corrective Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Corrective Task" });
      });
  };

  const handleDeleteCorrective = async (taskId) => {
    deleteTask(taskId, plantId)
      .then((res) => {
        successToast({ description: "Corrective task is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Corrective task" });
      });
  };
  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteCorrective,
    });
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  const viewTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createTask = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const onSuccessfulModal = () => {
    toggleModal();
    loadCorrectiveList(plantId, status);
  };
  const getExportComponent = () => {
    if (view === VIEW.CREATE) {
      return <ExportForm plantId={plantId} onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
    }
  };

  const createExport = () => {
    setView(VIEW.CREATE);
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };
  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return <CorrectiveMaintenanceViewContainer taskId={task} setIsOpen={setIsOpen} editTask={editTask} />;
    } else if (view === VIEW.CREATE) {
      return (
        <CorrectiveMaintenanceFormContainer
          mode={VIEW.CREATE}
          onSuccessfulModal={onSuccessfulModal}
          plantId={plantId}
          viewTask={viewTask}
        />
      );
    } else if (view === VIEW.EDIT) {
      return (
        <CorrectiveMaintenanceFormContainer
          mode={VIEW.EDIT}
          taskId={task}
          onSuccessfulModal={onSuccessfulModal}
          plantId={plantId}
          viewTask={viewTask}
        />
      );
    }
  };
  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page className="ml-md-4">
                Corrective Task Lists
              </BlockTitle>
              <BlockDes className="text-soft ml-md-4">
                <p>You have a total of {correctiveList?.pagination?.totalCount || 0} corrective maintenance.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>

                <div className="toggle-expand-content w-auto" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Check permission={["manageTickets", "getTickets"]}>
                        <Button
                          disabled={correctiveList?.pagination?.totalCount === 0}
                          className={`btn btn-white btn-outline-light`}
                          type="export"
                          isLoading={exportLoading}
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </Check>
                    </li>
                    <Check permission={["manageTickets"]}>
                      {plantStatus === "ACTIVE" && isArchived === false && (
                        <li className="nk-block-tools-opt">
                          <Button
                            id="add-corrective-btn"
                            color="primary"
                            className="btn-icon"
                            onClick={() => createTask()}
                          >
                            <Icon name="plus"></Icon>
                          </Button>
                        </li>
                      )}
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable className="card-stretch">
            {
              <div className="card-inner position-relative card-tools-toggle ">
                <div className="card-title-group ">
                  <div className="card-tools"></div>
                  <div className="card-tools mr-n1 ">
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <a
                          id="search-corrective-btn"
                          href="#search"
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle();
                          }}
                          className="btn btn-icon search-toggle toggle-search"
                        >
                          <Icon name="search"></Icon>
                        </a>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <div id="filter-corrective" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                        {isSubFilterApplied ? <div className="dot dot-primary"></div> : null}
                        <Icon name="filter-alt"></Icon>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        id="cancel-search-btn"
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          handleApplyFilter({ ...filterParams, searchText: "" });
                          toggle();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        id="search-input-corrective"
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by Ticket Name and Status.enter to search"
                        // ref={(el) => {
                        //   focusSearchRef.current = !onSearch ? el : null;
                        // }}
                        value={onSearchText}
                        onChange={(e) => onFilterChange(e)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            onSearchAction();
                          }
                        }}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon id="search-btn-corrective" name="search" onClick={onSearchAction}></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            }
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Ticket Name</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">Start Date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Resolved Date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Assigned To</span>
                </DataTableRow>
                <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
              </DataTableHead>

              <Sidebar toggleState={showFilter}>
                <CorrectiveListFilter isPlantName={true} subList={true} filterOptions={filterOptions} />
              </Sidebar>

              {/*Head*/}
              {currentCorrectiveItems.length > 0 ? (
                currentCorrectiveItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow>
                        <div
                          id="view-corrective"
                          className="user-card dropdown-toggle"
                          onClick={() => {
                            viewTask(item.id);
                          }}
                        >
                          <div className="user-name">
                            <span
                              data-toggle="tooltip"
                              title={item.title}
                              style={{
                                display: "inline-block",
                                width: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              className="tb-lead"
                            >
                              {item.title || "-"}
                            </span>
                          </div>
                        </div>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <Badge className="text-capitalize" style={statusColorTheme[item.status]} pill>
                          <span>{STATUSES[item.status] || "-"}</span>
                        </Badge>
                      </DataTableRow>
                      <DataTableRow size="sm">
                        <span>{item?.startedAt ? format(new Date(item?.startedAt || null), "dd/MM/yyyy") : "-"}</span>
                      </DataTableRow>
                      <DataTableRow size="sm">
                        {item?.resolvedAt
                          ? format(new Date(item.resolvedAt ? item.resolvedAt : null), "dd/MM/yyyy")
                          : "-"}
                      </DataTableRow>

                      <DataTableRow size="md">
                        <span>
                          {item?.assignedToIds
                            ?.map((id, key) => {
                              return engineers[id];
                            })
                            .join(", ") || "-"}
                        </span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <Check permission={["manageTickets"]}>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  id="menu-drop-corrective"
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <React.Fragment>
                                      <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                                        <DropdownItem
                                          id="edit-corrective"
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit-alt-fill"></Icon>
                                          <span>Edit Task</span>
                                        </DropdownItem>
                                      </li>

                                      {item.isArchived ? (
                                        <React.Fragment>
                                          <li className="divider"></li>
                                          <li onClick={() => handleUnArchive(item.id)}>
                                            <DropdownItem
                                              id="unarchive-corrective"
                                              tag="a"
                                              href="#unarchive"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="unarchive"></Icon>
                                              <span>UnArchive Task</span>
                                            </DropdownItem>
                                          </li>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <li className="divider"></li>
                                          <li onClick={() => handleArchive(item.id)}>
                                            <DropdownItem
                                              id="archive-corrective"
                                              tag="a"
                                              href="#archive"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="archive"></Icon>
                                              <span>Archive Task</span>
                                            </DropdownItem>
                                          </li>
                                        </React.Fragment>
                                      )}
                                      {item.status !== "SUSPENDED" && (
                                        <>
                                          <li className="divider"></li>
                                          <li onClick={() => handleDeleteConfirmation(item.id)}>
                                            <DropdownItem
                                              id="delete-corrective"
                                              tag="a"
                                              href="#delete"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                              }}
                                            >
                                              <Icon name="na"></Icon>
                                              <span>Delete Task</span>
                                            </DropdownItem>
                                          </li>
                                          <li className="divider"></li>
                                        </>
                                      )}
                                    </React.Fragment>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Check>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              ) : (
                <></>
              )}
            </DataTableBody>
            <div className="card-inner">
              {correctiveList.pagination && correctiveList.pagination.totalCount > 0 ? (
                <PaginationComponent
                  itemPerPage={pagination.size}
                  totalItems={correctiveList.pagination.totalCount}
                  paginate={correctiveContext.updateShowListPage}
                  currentPage={correctiveList.pagination.currentPage}
                  paginatePage={correctiveContext.updateShowListSize}
                />
              ) : (
                !correctiveLoading && (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )
              )}
            </div>
          </DataTable>
          {correctiveLoading && <Skeleton count={10} className="w-100" />}
        </Block>
      </Block>
      <ModalViewer
        title="Corrective Maintenance"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Corrective Maintenance - Select the Export Fields"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </>
  );
};

export default PlantCorrectiveList;
