import React, { useState } from "react";
import useBoolean from "../../../../hooks/useBoolean";
import { getPvPlantAPI } from "../../../pv_plant_management/PvPlantManagementRepository";
import {
  getAdminpvPlantsList,
  getPreventativeTaskOptions,
  getPreventativeTasks,
  unarchiveTaskAPI,
  archiveTaskAPI,
  getPreventativeTask,
  deletePreventativeTask
} from "./PreventativeTaskRepository";

export const PreventativeTaskContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedMonthFilter: [],
  selectedPlant: [],
  isArchiveSelected: false,
  taskText: "",
  startDate: "",
  resolvedDate: "",
  taskYear: "",
  sortingOrder: "",
  sortingField: "",
  dashboard: "",
};

export const PreventativeTaskProvider = (props) => {
  const [preventativeTasks, setPreventativeTasks] = useState([]);
  const [plants, setPlants] = useState([]);
  const [slas, setSlas] = useState([]);
  const [assetCategories, setAssetCategories] = useState([]);

  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));
  const [engineers, setEngineers] = useState([]);
  const [plantsList, setPlantsList] = React.useState([]);
  const [currentViewTask, setCurrentViewTask] = useState({});
  const [plantId, setPlantId] = useState("");

  //Loaders
  const [preventativeTaskLoading, preventativeTaskState] = useBoolean(false);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);
  const [plantLoading, plantState] = useBoolean(false);
  const [currentPlant, setCurrentPlant] = useState({});

  const [loadingTasks, tlState] = useBoolean(false);

  //CLOSE FILTER
  const [showFilter, sfState] = useBoolean(false);

  const date = new Date();
  const startDateOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const getQueryParams = () => {
    const queryParams = {
      ...pagination,
      ...filterParams,
    };
    return queryParams;
  };
  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));
  const checkIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedPlant.length != 0 ||
      filterParams.selectedAssignedEngineer.length != 0 ||
      filterParams.selectedMonthFilter.length != 0 ||
      filterParams.taskYear ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();
  const subCheckIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedAssignedEngineer.length != 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isSubFilterApplied = subCheckIsFilterApplied();

  const loadPreventativeTasks = async (plantId, status, dashboard) => {
    preventativeTaskState.on();
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] });
    }
    if (dashboard) {
      filterParams.dashboard = "yes";
    }
    try {
      const filterParams = getQueryParams();
      if (status) {
        filterParams.selectedTaskStatus = status;
      }
      if (plantId) {
        filterParams["selectedPlant"] = plantId;
        // filterParams.startDate = startDateOfMonth;
      }
      const response = await getPreventativeTasks(filterParams);
      setPreventativeTasks(response.preventativeTasks);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      preventativeTaskState.off();
    }
  };

  const loadPvPlant = async (plantId) => {
    try {
      const response = await getPvPlantAPI(plantId);
      setCurrentPlant(response.plant);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const loadOptions = async () => {
    try {
      const response = await getPreventativeTaskOptions();
      setEngineers(response.assignedEngineer);
      setPlants(response.plants);
      setSlas(response.slas);
      setAssetCategories(response.assetCategories);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    }
  };
  const loadPvPlantList = async () => {
    plantState.on();

    try {
      const response = await getAdminpvPlantsList();
      setPlantsList(response);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      plantState.off();
    }
  };

  //ARCHIVE TASK
  const archiveTask = async (taskId) => {
    try {
      tlState.on();
      await archiveTaskAPI(taskId);
      loadPreventativeTasks();
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  //UNARCHIVE TASK
  const unarchiveTask = async (taskId,plantId) => {
    
    try {
      tlState.on();
      await unarchiveTaskAPI(taskId);
      loadPreventativeTasks(plantId);
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  const loadCurrentTask = async (currentTaskId) => {
    preventativeTaskState.on();
    try {
      const response = await getPreventativeTask(currentTaskId);
      if (Object.keys(response).length) {
        setCurrentViewTask(response.task);
        setPlantId(response.task.plantId);
      }
    }
    catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      preventativeTaskState.off();
    }
  };

   // DELETE TASK
   const deleteTask = async (taskId, plantId) => {
    try {
      await deletePreventativeTask(taskId);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const mContext = {
    pagination,
    preventativeTasks,
    setPreventativeTasks,
    plants,
    engineers,
    isFilterApplied,
    showFilter,
    checkIsFilterApplied,
    sfState,
    filterParams,
    slas,
    assetCategories,
    preventativeTaskLoading,
    loadPreventativeTasks,
    loadOptions,
    handleApplyFilter,
    getQueryParams,
    isSubFilterApplied,
    subCheckIsFilterApplied,
    resetClientListFilter,
    setDashboardFilterStatus,
    dashboardFilterStatus,
    plantLoading,
    loadPvPlantList,
    plantsList,
    loadPvPlant,
    currentPlant,
    loadingTasks,
    archiveTask,
    unarchiveTask,
    loadCurrentTask,
    currentViewTask,
    deleteTask,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <PreventativeTaskContext.Provider value={mContext}>{props.children}</PreventativeTaskContext.Provider>;
};

export const withPreventativeTaskProvider = (Container, containerProps) => (props) =>
(
  <PreventativeTaskProvider>
    <Container {...containerProps} {...props} />
  </PreventativeTaskProvider>
);
