import React, { useState } from "react";
import { ACCESS_TOKEN, CURRENT_USER, DEVICE_ID, REFRESH_TOKEN } from "../constants/PreferenceKeys";
import { ADMIN_LOGIN_PAGE, HOME_PAGE, PUBLIC_PATH } from "../constants/routes";
import {
  loginUser,
  userLookup,
  forgotPassword,
  resetPassword,
  getForm,
  createFormSubmissionapi,
} from "../repositories/AuthRepository";
import md5 from "md5";
import useBoolean from "../hooks/useBoolean";
import Swal from "sweetalert2";

export const AuthContext = React.createContext({
  formView: async (formContext) => {},
  formData: [],
  isFetchingForm: false,
});

const handleWarningSweetAlert = (errorMessage) => {
  Swal.fire({
    icon: "warning",
    title: "Password Reset",
    text: { errorMessage },
    focusConfirm: false,
  });
};
export const AuthProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userAbilty, setUserAbility] = useState([]);
  const [isDetermined, setIsDetermined] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [formData, setFormData] = useState([]);
  const [createForm, fscState] = useBoolean(false);

  const [isFetchingForm, iffState] = useBoolean(false);

  const getUserName = () => {
    if (!currentUser) return "N/A";

    let userName = "";
    if (currentUser.firstName) userName += currentUser.firstName;
    if (currentUser.lastName) userName += " " + currentUser.lastName;

    return userName;
  };

  const handleWarningSweetAlert = (e) => {
    Swal.fire({
      icon: "warning",
      title: "Reset Password Error",
      text: `${e}`,
      focusConfirm: false,
    });
  };
  const onLogin = async (payload) => {
    const deviceId = localStorage.getItem(DEVICE_ID);
    if (!deviceId) {
      const bytes = md5(`${payload.email + new Date()}`);
      localStorage.setItem(DEVICE_ID, bytes);
    }
    try {
      const response = await loginUser({
        ...payload,

        // TODO: GET AUTH DEVICE
        authDevice: {
          deviceId: localStorage.getItem(DEVICE_ID),
          pushToken: "push-token",
          ip: "127.0.0.1",
        },
      });
      localStorage.setItem(ACCESS_TOKEN, response.tokens.auth);
      localStorage.setItem(REFRESH_TOKEN, response.tokens.refresh);
      localStorage.setItem(CURRENT_USER, JSON.stringify(response.user));
      window.history.pushState(
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
        "auth-login",
        `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
      );

      setCurrentUser(response.user);
      window.location.reload();
    } catch (e) {
      throw e.message;
    }
  };

  const onRegister = (payload) => {
    // CALL REGISTER API
  };

  const onLogout = () => {
    // CALL LOGOUT API
    // CLEAR LOCAL STATE IF AVAILABLE

    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(CURRENT_USER);
    localStorage.setItem(REFRESH_TOKEN);

    setCurrentUser(null);

    window.location.replace(ADMIN_LOGIN_PAGE);
  };

  const lookupUser = async () => {
    try {
      const response = await userLookup();
      setCurrentUser(response.user);
      setUserRoles(response.roleActions);
      const abilityValue = response.roleActions.map((role) => {
        if (role.includes("create")) {
          const a = role.split("create");
          return { action: "create", subject: a[1] };
        }
        if (role.includes("update")) {
          const a = role.split("update");
          return { action: "update", subject: a[1] };
        }
        if (role.includes("delete")) {
          const a = role.split("delete");
          return { action: "delete", subject: a[1] };
        }
        if (role.includes("get")) {
          const a = role.split("get");
          return { action: "view", subject: a[1] };
        }
      });
      setUserAbility(abilityValue);
      if (PUBLIC_PATH.includes(window.location.pathname)) {
        window.location.replace(HOME_PAGE);
      }
    } catch (e) {
      console.log("e :>> ", e);
    } finally {
      setIsDetermined(true);
    }
  };

  const onForgotPassword = async (params) => {
    try {
      await forgotPassword({
        ...params,
        // TODO: GET AUTH DEVICE
        authDevice: {
          deviceId: localStorage.getItem(DEVICE_ID),
          pushToken: "push-token",
          ip: "127.0.0.1",
        },
      });
    } catch (e) {
      throw e.message;
    }
  };

  const formView = async (params) => {
    try {
      iffState.on();
      const res = await getForm({
        ...params,
      });
      setFormData(res);
      return res;
    } catch (e) {
      alert(`Error: ${e}`);
    } finally {
      setIsDetermined(true);
      iffState.off();
    }
  };

  const onResetPassword = async (resetPasswordToken, params) => {
    try {
      await resetPassword(resetPasswordToken, params);
    } catch (e) {
      handleWarningSweetAlert(e);
      throw e;
    } finally {
      setIsDetermined(true);
    }
  };

  const createFormSubmission = async (createParams) => {
    try {
      fscState.on();
      await createFormSubmissionapi(createParams);
      fscState.off();
    } catch (e) {
      throw e;
    } finally {
      fscState.off();
    }
  };

  const mContext = {
    currentUser,
    userAbilty,
    onLogin,
    onRegister,
    onLogout,
    lookupUser,
    isAuthenticated: currentUser !== null,
    isDetermined,
    getUserName,
    userRoles,
    onForgotPassword,
    onResetPassword,
    formView,
    formData,
    createFormSubmission,
    createForm,
    getShortName: () => {
      if (!currentUser) return "N/A";
      if (currentUser.firstName) return currentUser.firstName;
      if (currentUser.lastName) return currentUser.lastName;

      return "N/A";
    },
    isFetchingForm,
  };

  return <AuthContext.Provider value={mContext}>{props.children}</AuthContext.Provider>;
};

export const withAuthProvider = (Container, containerProps = {}) => {
  return () => (
    <AuthProvider>
      <Container {...containerProps} />
    </AuthProvider>
  );
};
