import {
  REMEDIAL_ALL_TASKS_API,
  REMEDIAL_DELETE,
  REMEDIAL_LIST_API,
  REMEDIAL_LIST_VIEW_API,
  REMEDIAL_UPDATE,
  EXPORT_TASK_API,
  EXPORT_PARTICULAR_TASK_API,
  ADMIN_TASK_ARCHIVE_API,
  ADMIN_TASK_UNARCHIVE_API
} from "../../../constants/endpoints";
import apiService from "../../../services/ApiService";

export const getRemedialList = async (filterParams) => {
  const response = await apiService.get(REMEDIAL_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const getTaskAPI = async (taskId) => {
  const response = await apiService.get(REMEDIAL_LIST_VIEW_API, {
    pathParams: { taskId },
  });
  return response;
};

export const updateTaskAPI = async (taskId, params) => {
  const response = await apiService.put(REMEDIAL_UPDATE, {
    pathParams: { taskId },
    body: params,
  });
  return response;
};
export const deleteTaskAPI = async (taskId) => {
  const response = await apiService.delete(REMEDIAL_DELETE, {
    pathParams: { taskId },
  });
  return response;
};
export const getAllRemedialtasks = async (filter) => {
  const response = await apiService.get(REMEDIAL_ALL_TASKS_API, { queryParams: filter });
  return response;
};
export const exportTask = async (filterParams) => {
  const response = await apiService.get(EXPORT_TASK_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};
export const exportParticularTask = async (filterParams) => {
  const taskId = filterParams.taskId
  const response = await apiService.get(EXPORT_PARTICULAR_TASK_API, {
      queryParams: {category:filterParams.category},
      pathParams: {taskId} ,
      responseType: 'blob',
      headers: {
          'Content-Type': 'application/pdf',
      }
  })
  return response
}
export const archiveTaskAPI = async (taskId) => {
  const response = await apiService.put(ADMIN_TASK_ARCHIVE_API, {
      pathParams: { taskId }
  })
  return response
}
export const unarchiveTaskAPI = async (taskId) => {
  const response = await apiService.put(ADMIN_TASK_UNARCHIVE_API, {
      pathParams: { taskId }
  })
  return response
}