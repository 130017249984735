import BgeueLogo from "../images/bgue_logo.png";
import BgeLogo from "../images/bge_logo.png";

export const statusNames = (status) => {
  const statuses = {
    ACTIVE: "Active",
    SUSPENDED: "Suspended",
    INACTIVE: "In-Active",
    ACTIVE: "Active",
  };

  return statuses[status] || "-";
};

export const MONTH = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const statusColorTheme = {
  OPEN: { backgroundColor: "#ff0000", color: "#ffffff", borderColor: "#ff0000" },
  IN_PROGRESS: { backgroundColor: "#008000", color: "#ffffff", borderColor: "#008000" },
  READY_FOR_APPROVAL: { backgroundColor: "#ff1493", color: "#ffffff", borderColor: "#ff1493" },
  COMPLETED: { backgroundColor: "#0000ff", color: "#ffffff", borderColor: "#0000ff" },
};

export const COMPANY_NAME = {
  BGE: "BGE",
  BGUE: "BGUE",
};

export let CompanyLogo;

if (process.env.REACT_APP_COMPANY_NAME === COMPANY_NAME.BGE) {
  CompanyLogo = BgeLogo;
} else {
  CompanyLogo = BgeueLogo;
}
