/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import classnames from "classnames";
import { getYear } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
} from "../../../../../components/Component";
import { errorToast, successToast } from "../../../../../components/toastify/Toastify";
import { PREVENTATIVE_TASKS_PAGE, PVPLANT_MANAGEMENT_VIEW_PAGE } from "../../../../../constants/routes";
import Head from "../../../../../layout/head/Head";
import { sortOption } from "../../../../../utils/Utils";
import ModalViewer from "../../../ModalViewer";
import { MONTHS, PRIORITY, STATUS } from "../../constants";
import { PreventativeTaskContext } from "../PreventativeTaskProvider";
import { createPreventativeTask, getPreventativeMasterByMonthPlantId } from "../PreventativeTaskRepository";
import "../styles.css";

const PreventativeGenerator = () => {
  const history = useHistory();
  const listData = window.location.search;
  const plant = listData.replace("?plant=", "");

  const currentYear = new Date().getFullYear();
  const yearOptions = [
    { label: currentYear - 3, value: currentYear - 3 },
    { label: currentYear - 2, value: currentYear - 2 },
    { label: currentYear - 1, value: currentYear - 1 },
    { label: currentYear, value: currentYear },
    { label: currentYear + 1, value: currentYear + 1 },
    { label: currentYear + 2, value: currentYear + 2 },
    { label: currentYear + 3, value: currentYear + 3 },
  ];
  const preventativeContext = useContext(PreventativeTaskContext);
  const { plants, engineers, loadPreventativeTasks, loadPvPlant } = preventativeContext;

  const [preventativeTasks, setPreventativeTask] = useState([]);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [plantIds, setPlantIds] = useState([]);
  const [defaultPlant, setDefaultPlant] = useState([]);

  const [generatedPlantIds, setGeneratedPlantIds] = useState(-1);
  const [, setGeneratedMonth] = useState([]);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [currentPlantId, setCurrentPlantId] = useState("");
  const [formData, setFormData] = useState({});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [generateLoading, setGenerateLoading] = useState(false);

  useEffect(() => {
    if (plant && plants.length) {
      loadPvPlant(plant);
      const option = plants.find((p) => p.value === Number(plant));
      setDefaultPlant([option]);
      setPlantIds([Number(plant)]);
    }
  }, [plants]);

  const onSubmit = () => {
    try {
      let check = true;
      let i;
      for (i = 0; i < preventativeTasks.length; i++) {
        if (!preventativeTasks[i].assignedTo.length) {
          check = false;
          break;
        }
      }
      if (check) {
        setGenerateLoading(true);

        const response = createPreventativeTask(preventativeTasks).then(() => {
          if (Object.keys(response)) {
            loadPreventativeTasks();
            successToast({ description: "Successfully Created" });
            setGenerateLoading(false);
            setConfirmModal(false);

            plant
              ? history.push(PVPLANT_MANAGEMENT_VIEW_PAGE.replace(":plantId", plant) + `?tab=preventative`)
              : history.push(PREVENTATIVE_TASKS_PAGE);
          }
        });
      } else {
        errorToast({
          description: `Select Assigned to for Plant ${getPlantName(preventativeTasks[i].plantId)}, ${
            preventativeTasks[i].title
          } Task`,
        });
      }
    } catch (error) {
      errorToast({ description: "Error happened while creating Preventative Tasks" });
    }
  };
  const deleteTask = (obj) => {
    const temp = preventativeTasks.filter((p) => {
      if (p.index !== undefined && p.index >= 0 && obj.index !== undefined && obj.index >= 0) {
        return p.index !== obj.index || p.plantId !== obj.plantId;
      } else if (p.parentTaskId && obj.parentTaskId) {
        return p.parentTaskId !== obj.parentTaskId || p.plantId !== obj.plantId;
      } else {
        return true;
      }
    });
    setPreventativeTask([...temp]);
  };

  const onGenerateClick = async () => {
    setErrors({});
    let err = {};
    if (month === null) {
      err = { ...err, month: "Required" };
    }
    if (year === null) {
      err = { ...err, year: "Required" };
    }
    if (!plantIds.length) {
      err = { ...err, plantIds: "Required" };
    }
    setErrors({ ...errors, ...err });

    if (plantIds.length && month !== null && year !== null) {
      try {
        const response = await getPreventativeMasterByMonthPlantId({ month, plantIds, year });
        setGeneratedMonth(month);
        setGeneratedPlantIds(plantIds);
        if (Object.keys(response).length) {
          const { preventativeSchedules } = response;
          let createBody = [];

          preventativeSchedules.map((task) => {
            const currentPlant = plants.find((p) => p.value === task.plantId);
            let assignedTo = currentPlant ? currentPlant.fieldEngineerIds : [];
            createBody.push({
              title: task.title,
              priority: task.priority,
              status: task.status,
              assignedTo: assignedTo,
              description: task.description,
              plantId: task.plantId,
              months: [month],
              year: year,
              code: task.code,
              parentTaskId: task.id,
              category: "preventative",
              startedAt: task.months.length ? new Date(getYear(new Date()), task.months[0], 1) : null,
              frequency: task.frequency,
            });
          });
          setActiveTab(plantIds[0]);
          setPreventativeTask(createBody);
        }
      } catch (error) {}
    }
  };

  const month_options = MONTHS.map((m, i) => {
    return { label: m, value: i };
  });
  const onChange = (o) => {
    setPlantIds(o.map((item) => item.value));
  };

  const getPlantName = (plantId) => {
    const plantData = plants.find((p) => p.value === plantId);
    return plantData.label;
  };
  const onBlurFunc = (key, value, obj) => {
    const index = preventativeTasks.findIndex((p, i) => {
      if (p.parentTaskId) {
        return p.parentTaskId === obj.parentTaskId && p.plantId === obj.plantId;
      } else {
        return i === obj.index && p.plantId === obj.plantId;
      }
    });
    switch (key) {
      case "title":
      case "code":
      case "frequency":
      case "priority":
      case "status":
      case "description":
        preventativeTasks[index][key] = value;
        setPreventativeTask([...preventativeTasks]);
        break;
      case "assignedTo":
        preventativeTasks[index][key] = value.map((item) => item.value);
        setPreventativeTask([...preventativeTasks]);
        break;
      default:
        break;
    }
  };

  const handleSubmitAddModal = () => {
    if (Object.keys(formData).length >= 4) {
      setPreventativeTask([
        ...preventativeTasks,
        {
          ...formData,
          assignedTo: formData.assignedTo.map((e) => (e.value ? e.value : e)),
          category: "preventative",
          plantId: currentPlantId,
          index: preventativeTasks.length,
          months: [month],
          year: [year],
          startedAt: new Date(year, month, 1),
        },
      ]);
      setAddModal(false);
      setFormData({});
    } else {
      setFormDataErrors({});
      let errors = {};
      if (!formData.title) {
        errors = { title: "Required" };
      }
      if (!formData.code) {
        errors = { code: "Required" };
      }
      if (!formData.status) {
        errors = { ...errors, status: "Required" };
      }
      if (!formData.priority) {
        errors = { ...errors, priority: "Required" };
      }
      if (!formData.assignedTo) {
        errors = { ...errors, assignedTo: "Required" };
      }
      setFormDataErrors({ ...formDataErrors, ...errors });
    }
  };
  const getAddModalComponent = () => {
    let engineer_options = [];

    plants?.map((item) => {
      if (item.value === currentPlantId) {
        for (let i = 0; i < engineers.length; i++) {
          if (item.fieldEngineerIds?.includes(engineers[i].value)) {
            engineer_options.push(engineers[i]);
          }
        }
      }
    });
    formData["assignedTo"] = engineer_options;
    const changeFunc = (key, value) => {
      switch (key) {
        case "title":
        case "priority":
        case "code":
        case "status":
        case "description":
          formData[key] = value;
          setFormData(formData);
          break;
        case "assignedTo":
          formData[key] = value.map((item) => item.value);
          setFormData(formData);
        default:
          break;
      }
    };
    return (
      <>
        <Row className="mb-2">
          <Col lg="6">
            <FormGroup>
              <Label className="form-label" htmlFor="fv-full-name">
                <span style={{ color: "indianred" }}>&#42;</span>Title of the Task
              </Label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control "
                  onChange={(e) => changeFunc("title", e.target.value)}
                />
                {formDataErrors.title && <span style={{ color: "red" }}>Title is required</span>}
              </div>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label className="form-label" htmlFor="fv-full-name">
                <span style={{ color: "indianred" }}>&#42;</span>Code
              </Label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="form-control "
                  onChange={(e) => changeFunc("code", e.target.value)}
                />
                {formDataErrors.code && <span style={{ color: "red" }}>Code is required</span>}
              </div>
            </FormGroup>
          </Col>
          <Col lg="6" className="mt-2 mt-md-0">
            <FormGroup>
              <Label className="form-label" htmlFor="fv-full-name">
                <span style={{ color: "indianred" }}>&#42;</span>Choose the Priority
              </Label>
              <div className="form-control-wrap">
                <RSelect
                  id={`priority`}
                  name="priority"
                  options={PRIORITY}
                  onChange={(o) => changeFunc("priority", o.value)}
                  placeholder="choose priority"
                />
                {formDataErrors.priority && (
                  <span className="invalid" style={{ color: "red" }}>
                    Priority is required
                  </span>
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label className="form-label" htmlFor="fv-full-name">
                Description
              </Label>
              <div className="form-control-wrap">
                <textarea
                  // type="text"
                  name="description"
                  id={`description`}
                  className="form-control"
                  onBlur={(e) => changeFunc("description", e.target.value)}
                />
                {formDataErrors.description && <span style={{ color: "red" }}>Description is required</span>}
              </div>
            </FormGroup>
          </Col>
          <Col lg="6" className="mt-2 mt-md-0">
            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Choose the Status
                  </Label>
                  <div className="form-control-wrap">
                    <RSelect
                      id={`status`}
                      name="status"
                      options={STATUS}
                      onChange={(o) => changeFunc("status", o.value)}
                      placeholder="choose status"
                    />
                    {formDataErrors.status && (
                      <span className="invalid" style={{ color: "red" }}>
                        Status is required
                      </span>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2 mt-md-0">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span>Choose the Assignee
                  </Label>
                  <div className="form-control-wrap">
                    <RSelect
                      isMulti
                      name="assignedTo"
                      id={`assignedTo`}
                      value={engineer_options.filter((d) => !formData.assignedTo.includes(Number(d.id)))}
                      options={engineer_options}
                      onChange={(o) => changeFunc("assignedTo", o)}
                      placeholder="choose assignee"
                    />
                    {formDataErrors.assignedTo && (
                      <span className="invalid" style={{ color: "red" }}>
                        Assigned to is required
                      </span>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className=" mt-5 d-flex justify-content-center">
          <Col md="6" className="text-center">
            <Button className="mx-2" color="danger" onClick={() => setAddModal(false)}>
              Cancel
            </Button>
            <Button className="mx-2" color="primary" onClick={() => handleSubmitAddModal()}>
              Create
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  // const getComponent = () => {
  //   return (
  //     <>
  //       <Row className="d-flex justify-content-center">
  //         <p>{`There are ${preventativeTasks.length} tasks created across ${generatedPlantIds.length} plants`}</p>
  //       </Row>
  //       <Row className="d-flex justify-content-center">
  //         <p>Are you sure want to confirm?</p>
  //       </Row>
  //       <Row className=" mt-5 d-flex justify-content-center">
  //         <Col lg="3">
  //           <Button color="danger" onClick={() => {
  //             setConfirmModal(false)
  //           }}>
  //             Cancel
  //           </Button>
  //         </Col>
  //         <Col lg="3">
  //           <Button color="primary" onClick={() => onSubmit()} isLoading={generateLoading}>
  //             Confirm
  //           </Button>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // };

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative | Generator" />
      <BlockHead size="sm">
        <BlockTitle tag="h4">Preventative Generator</BlockTitle>
      </BlockHead>
      <Block size="lg" className={"bg-white p-4"}>
        <Row>
          <Col md="3">
            <div style={{ borderWidth: 5, borderColor: "black" }}>
              <Label className="form-label" htmlFor="fv-full-name">
                Month
              </Label>
              <RSelect
                id="month"
                defaultValue={month}
                options={month_options}
                onChange={(o) => {
                  setMonth(o.value);
                  delete errors.month;
                  setErrors({ ...errors });
                }}
                placeholder="choose month"
              />

              {errors?.month && <span style={{ color: "red" }}>Month is required</span>}
            </div>
          </Col>
          <Col md="3" className="my-2 my-md-0">
            <div style={{ borderWidth: 5, borderColor: "black" }}>
              <Label className="form-label" htmlFor="fv-full-name">
                Year
              </Label>
              <RSelect
                id="year"
                defaultValue={currentYear}
                options={yearOptions}
                onChange={(o) => {
                  setYear(o.value);
                  delete errors.year;
                  setErrors({ ...errors });
                }}
                placeholder="choose year"
              />

              {errors?.year && <span style={{ color: "red" }}>Year is required</span>}
            </div>
          </Col>
          <Col md="3">
            <Label className="form-label" htmlFor="fv-full-name">
              Plants
            </Label>
            <RSelect
              value={defaultPlant}
              isMulti
              options={sortOption(plants)}
              onChange={(o) => {
                onChange(o);
                setDefaultPlant(o);
                delete errors.plantIds;
                setErrors({ ...errors });
              }}
              isDisabled={plant}
              placeholder="Choose plants"
            />
            {errors?.plantIds && <span style={{ color: "red" }}>Plants are required</span>}
          </Col>
          <Col style={{ marginTop: 32 }} md="3">
            <Button color="primary" size="md" onClick={() => onGenerateClick()}>
              Generate
            </Button>
          </Col>
        </Row>
        {generatedPlantIds.length && month !== -1 ? (
          <div className="mt-3">
            {" "}
            <Nav tabs className="mt-n3">
              {generatedPlantIds.map((plant, index) => {
                return (
                  <NavItem>
                    <NavLink
                      key={`${index}-nav`}
                      tag="a"
                      href="#tab"
                      style={{ marginLeft: index === 0 ? 25 : 0 }}
                      className={classnames({ active: activeTab === plant })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        setActiveTab(plant);
                      }}
                    >
                      {getPlantName(plant)}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {generatedPlantIds.map((plant, index) => {
                let engineer_options = [];
                plants?.map((item) => {
                  if (item.value === plant) {
                    for (let i = 0; i < engineers.length; i++) {
                      if (item.fieldEngineerIds?.includes(engineers[i].value)) {
                        engineer_options.push(engineers[i]);
                      }
                    }
                  }
                });
                return (
                  <TabPane tabId={plant}>
                    <DataTable className="card-stretch">
                      <DataTableBody compact>
                        <DataTableHead className="text-left">
                          <DataTableRow size="sm">
                            <span className="sub-text">Code</span>
                          </DataTableRow>
                          <DataTableRow size="sm">
                            <span className="sub-text">Task</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span className="sub-text">Priority</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span className="sub-text">Status</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span className="sub-text">Assigned To</span>
                          </DataTableRow>
                          <DataTableRow size="md">
                            <span className="sub-text">Description</span>
                          </DataTableRow>
                        </DataTableHead>
                        {preventativeTasks.length > 0
                          ? preventativeTasks.map((item, i) => {
                              if (item.plantId === plant) {
                                return (
                                  <DataTableItem key={`${i}-${index}-preventative-tasks`}>
                                    <DataTableRow>
                                      <small>{item.frequency}</small>
                                      <input
                                        style={{ width: 130 }}
                                        type="text"
                                        name="code"
                                        id={`${i}-${index}-code`}
                                        className="form-control "
                                        value={item.code}
                                        onChange={(e) => onBlurFunc("code", e.target.value, item)}
                                      />
                                    </DataTableRow>
                                    <DataTableRow>
                                      <input
                                        style={{ minWidth: 130 }}
                                        type="text"
                                        name="title"
                                        id={`${i}-${index}-plant`}
                                        className="form-control "
                                        value={item.title}
                                        onChange={(e) => onBlurFunc("title", e.target.value, item)}
                                      />
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                      <RSelect
                                        id={`${i}-${index}-priority`}
                                        name="priority"
                                        value={PRIORITY.find((d) => d.value === item.priority)}
                                        options={PRIORITY}
                                        onChange={(o) => onBlurFunc("priority", o.value, item)}
                                        placeholder="choose priority"
                                        styles={{
                                          container: (provided) => ({
                                            ...provided,
                                            minWidth: 80,
                                          }),
                                        }}
                                      />
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                      <RSelect
                                        id={`${i}-${index}-status`}
                                        name="status"
                                        value={STATUS.find((d) => d.value === item.status)}
                                        options={STATUS}
                                        onChange={(o) => onBlurFunc("status", o.value, item)}
                                        placeholder="choose status"
                                        styles={{
                                          container: (provided) => ({
                                            ...provided,
                                            minWidth: 130,
                                          }),
                                        }}
                                      />
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                      <RSelect
                                        value={engineer_options.filter((d) => item.assignedTo.includes(d.value))}
                                        isMulti
                                        name="assignedTo"
                                        id={`${i}-${index}-assignedTo`}
                                        options={engineer_options}
                                        onChange={(o) => onBlurFunc("assignedTo", o, item)}
                                        placeholder="Choose assignee"
                                        styles={{
                                          container: (provided) => ({
                                            ...provided,
                                            minWidth: 130,
                                          }),
                                        }}
                                      />
                                    </DataTableRow>
                                    <DataTableRow size="md">
                                      <textarea
                                        className="form-control textBox"
                                        name={`${i}-${index}-description`}
                                        id={`${i}-${index}-description`}
                                        onBlur={(e) => onBlurFunc("description", e.target.value, item)}
                                      >
                                        {item.description}
                                      </textarea>
                                    </DataTableRow>
                                    <DataTableRow className="nk-tb-col-tools">
                                      <Button
                                        className="btn btn-icon"
                                        onClick={() => {
                                          deleteTask(item);
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                      </Button>
                                    </DataTableRow>
                                  </DataTableItem>
                                );
                              } else {
                                return null;
                              }
                            })
                          : null}
                        <DataTableItem>
                          <DataTableRow>
                            <Button
                              className="btn btn-icon"
                              color="secondary"
                              onClick={() => {
                                setAddModal(true);
                                setCurrentPlantId(plant);
                              }}
                            >
                              <Icon name="plus" />
                            </Button>
                          </DataTableRow>
                        </DataTableItem>
                      </DataTableBody>
                    </DataTable>
                  </TabPane>
                );
              })}
            </TabContent>
          </div>
        ) : null}
        {preventativeTasks.length ? (
          <Row className="d-flex justify-content-end mt-5">
            <Col className="d-flex justify-content-end">
              <Button
                size="md"
                color="danger"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="ml-3"
                isLoading={generateLoading}
                size="md"
                onClick={() => (generateLoading ? null : onSubmit())}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        ) : null}
      </Block>
      {/* <ModalViewer
        size="md"
        title="Confirm Box"
        isOpen={confirmModal}
        toggleModal={() => setConfirmModal(!confirmModal)}
        component={getComponent()}
      /> */}
      <ModalViewer
        size="md"
        title="Single Task Addition"
        isOpen={addModal}
        toggleModal={() => setAddModal(!addModal)}
        component={getAddModalComponent()}
      />
    </React.Fragment>
  );
};
export default PreventativeGenerator;
