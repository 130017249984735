import React, { useState } from "react";
import { getPreventativeMasters } from "./PreventativeRepository";
import useBoolean from "../../../hooks/useBoolean";

export const PreventativeContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};
const initialFilterParams = {
  taskText: "",
  selectedFrequency: [],
  selectedAssetCategory: [],
  isArchiveSelected: false,
  preventiveMasterListId: "",
  sortingField: "",
  sortingOrder: "",
};

export const PreventativeProvider = (props) => {
  const [preventativeMasters, setPreventativeMasters] = useState([]);
  const [plants, setPlants] = useState([]);
  const [assetCategories, setAssetCategories] = useState([]);
  const [preventativeMasterListId, setPreventativeMasterListId] = useState([]);
  const [preventativeMasterListData, setPreventativeMasterListData] = useState([]);

  const [pagination, setPagination] = useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = useState(Object.assign({}, initialFilterParams));

  //Loaders
  const [preventativeMasterLoading, preventativeMasterState] = useBoolean(false);

  const [showFilter, sfState] = useBoolean(false);

  const loadPreventativeMasters = async (id, status) => {
    preventativeMasterState.on();
    try {
      id && setPreventativeMasterListId(id);
      const filterParams = getQueryParams();
      if (status) {
        filterParams.taskStatus = status;
      }
      if (id) {
        filterParams.preventiveMasterListId = id;
      }
      const response = await getPreventativeMasters({ ...filterParams, preventativeMasterListId: id });
      setPreventativeMasters(response.preventativeMasterTasks);
      setPlants(response.plants);
      setAssetCategories(response.assetCategories);
      setPreventativeMasterListData(response.preventativeMasterList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      preventativeMasterState.off();
    }
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const getQueryParams = () => {
    const queryParams = {
      ...pagination,
      ...filterParams,
    };
    return queryParams;
  };
  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const checkIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedFrequency.length != 0 ||
      filterParams.selectedAssetCategory.length != 0
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();

  const mContext = {
    preventativeMasterListId,
    preventativeMasterListData,
    showFilter,
    sfState,
    isFilterApplied,
    checkIsFilterApplied,
    pagination,
    preventativeMasters,
    setPreventativeMasters,
    plants,
    assetCategories,
    filterParams,
    preventativeMasterLoading,
    loadPreventativeMasters,
    handleApplyFilter,
    getQueryParams,
    resetClientListFilter,
    updateShowListSize: (size) => setPagination({ ...pagination, size }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <PreventativeContext.Provider value={mContext}>{props.children}</PreventativeContext.Provider>;
};

export const withPreventativeProvider = (Container, containerProps) => (props) =>
  (
    <PreventativeProvider>
      <Container {...containerProps} {...props} />
    </PreventativeProvider>
  );
