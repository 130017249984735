import { format } from "date-fns";
import { useContext } from "react";
import { Button, Card } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon } from "../../../../components/Component";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { EquipmentAndSpareContext } from "../../EquipmentAndSpareProvider";

const ViewComponent = ({ setMode, onSuccessfulModal }) => {
  const { currentEquipment, deleteEquipment } = useContext(EquipmentAndSpareContext);
  const handleDeleteInventory = async (inventoryId) => {
    deleteEquipment(inventoryId)
      .then((res) => {
        successToast({ description: "Equipment is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting equipment" });
      });
  };

  const handleDeleteConfirmation = async (inventoryId) => {
    confirmationSweetAlert({
      id: inventoryId,
      handleConfirmation: handleDeleteInventory,
    });
  };
  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="lg">
          <div className="row">
            <div className="col-md-6">
              <BlockHeadContent>
                <BlockTitle tag="h4">{currentEquipment?.assets?.model}'s Information</BlockTitle>
              </BlockHeadContent>
            </div>
            <div className="col-md-6 text-right">
              <BlockHeadContent className="align-self-end mt-3 mt-md-0">
                <Check permission={["manageInventory"]}>
                  <Button
                    id="delete-Plants-btn"
                    color="danger"
                    className={"mr-2"}
                    onClick={() => {
                      handleDeleteConfirmation(currentEquipment.id);
                    }}
                  >
                    <Icon name="na" />
                    <span>Delete</span>
                  </Button>
                </Check>
                <Check permission={["manageInventory"]}>
                  <Button
                    color="primary"
                    onClick={() => {
                      setMode("EDIT");
                    }}
                  >
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Button>
                </Check>
                <Check permission={["manageInventory"]}>
                  <Button
                    color="primary"
                    className="ml-2"
                    onClick={() => {
                      setMode("MOVE");
                    }}
                  >
                    <Icon name="plus" />
                    <span>Add Transaction</span>
                  </Button>
                </Check>
              </BlockHeadContent>
            </div>
          </div>
        </BlockHead>
        <Block size="lg" className="mt-2">
          <Card className="card-bordered p-2">
            <div className="profile-ud-list" style={{ maxWidth: "100%" }}>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Equipment Name</span>
                  <span className="profile-ud-value">{currentEquipment?.assets?.model || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Supplier</span>
                  <span className="profile-ud-value">{currentEquipment?.assets?.manufacturers?.name || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Asset Category</span>
                  <span className="profile-ud-value">{currentEquipment?.assets?.assetCategories?.name || "-"}</span>
                </div>
              </div>
              {currentEquipment?.plants?.name ? (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Plant Name</span>
                    <span className="profile-ud-value">{currentEquipment?.plants?.name || "-"}</span>
                  </div>
                </div>
              ) : null}
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Contractual Quantity</span>
                  <span className="profile-ud-value">{currentEquipment?.contractualQuantity || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">
                    {currentEquipment?.plants?.name ? "Total Spares" : "Quantity"}
                  </span>
                  <span className="profile-ud-value">{currentEquipment?.quantity || "-"}</span>
                </div>
              </div>
              {currentEquipment?.plants?.name ? (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Installed Quantity</span>
                    <span className="profile-ud-value">{currentEquipment?.installedQuantity || "-"}</span>
                  </div>
                </div>
              ) : null}
              {currentEquipment?.plants?.name ? (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Spare Quantity</span>
                    <span className="profile-ud-value">{currentEquipment?.spareQuantity || "-"}</span>
                  </div>
                </div>
              ) : null}
              {currentEquipment?.plants?.name ? (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">External Warehouse Quantity</span>
                    <span className="profile-ud-value">{currentEquipment?.externalWarehouseQuantity || "-"}</span>
                  </div>
                </div>
              ) : null}
              {currentEquipment?.plants?.name ? (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Bge Warehouse Quantity</span>
                    <span className="profile-ud-value">{currentEquipment?.bgeWarehouseQuantity || "-"}</span>
                  </div>
                </div>
              ) : null}
              {currentEquipment?.plants?.name ? (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Supplier premises Quantity</span>
                    <span className="profile-ud-value">{currentEquipment?.supplierPremisesQuantity || "-"}</span>
                  </div>
                </div>
              ) : null}
              {currentEquipment?.plants?.name ? (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Part Number</span>
                    <span className="profile-ud-value">{currentEquipment?.partNumber || "-"}</span>
                  </div>
                </div>
              ) : null}
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Is Key Component</span>
                  <span className="profile-ud-value">{currentEquipment.isKeyComponent ? "Yes" : "No"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Details</span>
                  <span className="profile-ud-value">{currentEquipment?.description || "-"}</span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Created At</span>
                  <span className="profile-ud-value">
                    {format(new Date(currentEquipment?.created_at || null), "dd/MM/yyyy HH:mm ")}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Updated At</span>
                  <span className="profile-ud-value">
                    {format(new Date(currentEquipment?.updated_at || null), "dd/MM/yyyy HH:mm")}
                  </span>
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </Block>
    </>
  );
};
export default ViewComponent;
