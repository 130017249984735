import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import RemedialListCompact from "../components/RemedialList";
import { RemedialContext, withRemedialProvider } from "../RemedialProvider";
import { useLocation, useHistory } from "react-router-dom";

const RemedialMaintenanceContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const plantId = new URLSearchParams(location).get("plantId");

  const { loadRemedialList } = useContext(RemedialContext);
  const { pagination, filterParams, dashboardFilterStatus } = useContext(RemedialContext);
  const { loadFilterOptions } = useContext(MaintenanceContext);

  const searchtext = filterParams.searchText

  useEffect(() => {
    if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
      const queryParams = new URLSearchParams(location);
      if (queryParams.has("status")) {
        queryParams.delete("status");
        history.replace({
          search: queryParams.toString(),
        });
      }
      if (queryParams.has("plantId")) {
        queryParams.delete("plantId");
        history.replace({
          search: queryParams.toString(),
        });
      }
      loadRemedialList();
    }
  }, [pagination, filterParams]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    if (status && !dashboardFilterStatus) {
      loadRemedialList(parseInt(plantId), status);
    }
  }, [pagination,searchtext]);
  
  return (
    <React.Fragment>
      <Head title="Brighter App | Remedial Maintenance" />
      <Content>
        <RemedialListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withRemedialProvider(RemedialMaintenanceContainer);
