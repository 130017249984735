import apiService from "../../services/ApiService";
import {
  EQUIPMENT_SPARE_CREATE,
  EQUIPMENT_SPARE_DELETE,
  EQUIPMENT_SPARE_LIST_API,
  EQUIPMENT_SPARE_LIST_FILTER_OPTIONS_API,
  EQUIPMENT_SPARE_UPDATE,
  EQUIPMENT_SPARE_VIEW_API,
  EXPORT_EQUIPMENT_SPARE_API,
  GET_ALL_TASK_LIST_API,
  MOVE_TO_PLANT_API,
  STOCK_TRANSACTION_CREATE,
  STOCK_TRANSACTION_LIST_API,
  EQUIPMENT_SPARE_LIST_OPTIONS_API
} from "../../constants/endpoints";

export const getEquipmentList = async (filterParams) => {
  const response = await apiService.get(EQUIPMENT_SPARE_LIST_API, {
    queryParams: filterParams,
  });
  return response;
};

export const getEquipmentFilterOptions = async (filterParams) => {
  const response = await apiService.get(EQUIPMENT_SPARE_LIST_FILTER_OPTIONS_API);
  return response;
};

export const getEquipmentAPI = async (equipmentId) => {
  const response = await apiService.get(EQUIPMENT_SPARE_VIEW_API, {
    pathParams: { equipmentId },
  });
  return response;
};

export const createEquipmentAPI = async (params) => {
  const response = await apiService.post(EQUIPMENT_SPARE_CREATE, {
    body: params,
  });
  return response;
};

export const updateEquipmentAPI = async (equipmentId, params) => {
  const response = await apiService.put(EQUIPMENT_SPARE_UPDATE, {
    body: params,
    pathParams: { equipmentId },
  });
  return response;
};

export const moveToPlantInventoryAPI = async (inventoryId, params) => {
  const response = await apiService.put(MOVE_TO_PLANT_API, {
    body: params,
    pathParams: { inventoryId },
  });
  return response;
};
export const deleteEquipmentAPI = async (equipmentId) => {
  const response = await apiService.delete(EQUIPMENT_SPARE_DELETE, {
    pathParams: { equipmentId },
  });
  return response;
};
export const exportEquipmentAPI = async (filterParams) => {
  const response = await apiService.get(EXPORT_EQUIPMENT_SPARE_API, {
    queryParams: filterParams,
    responseType: "blob",
    headers: {
      "Content-Type": "application/pdf",
    },
  });
  return response;
};

export const getStockTransactionAPI = async (queryParams) => {
  const response = await apiService.get(STOCK_TRANSACTION_LIST_API, {
    queryParams,
  });
  return response;
};

export const getAllTask = async () => {
  const response = await apiService.get(GET_ALL_TASK_LIST_API);
  return response;
};

export const createStockTransactionAPI = async (params) => {
  const response = await apiService.post(STOCK_TRANSACTION_CREATE, {
    body: params,
  });
  return response;
};
export const getEquipmentAllOptions = async (queryParams) => {
  const response = await apiService.get(EQUIPMENT_SPARE_LIST_OPTIONS_API, { queryParams });
  return response;
};
