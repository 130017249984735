import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import {
  PVPLANT_MANAGEMENT_CREATE_PAGE,
  PVPLANT_MANAGEMENT_EDIT_PAGE,
  PVPLANT_MANAGEMENT_VIEW_PAGE,
} from "../../../constants/routes";
import { findUpper } from "../../../utils/Utils";
import ModalViewer from "../../maintenance/ModalViewer";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";
import ExportForm from "./ExportForm";
import PvPlantListFilter from "./PvPlantListFilter";

const PvPlantListCompact = () => {
  // REFS
  const focusSearchRef = React.useRef();

  const history = useHistory();

  const pvPlantManagementContext = useContext(PvPlantManagementContext);

  const { pvPlantsList, pagination } = pvPlantManagementContext;
  const { filterParams, handleApplyFilter, showFilter, isFilterApplied, sfState, clientList, userList } =
    pvPlantManagementContext;
  const { archivePvPlant, unarchivePvPlant, deletePvPlant, pvPlantLoading } = pvPlantManagementContext;
  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => {}, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    history.push(PVPLANT_MANAGEMENT_EDIT_PAGE.replace(":plantId", id));
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const handleArchive = async (plantId) => {
    archivePvPlant(plantId)
      .then((res) => {
        successToast({ description: "PV-Plant Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive PV-Plant" });
      });
  };
  const handleUnArchive = async (plantId) => {
    unarchivePvPlant(plantId)
      .then((res) => {
        successToast({ description: "PV-Plant UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive PV-Plant" });
      });
  };

  const handleDeletePvPlant = async (plantId) => {
    deletePvPlant(plantId)
      .then((res) => {
        successToast({ description: "PV-Plant is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting PV-Plant" });
      });
  };
  const handleDeleteConfirmation = async (plantId) => {
    confirmationSweetAlert({
      id: plantId,
      handleConfirmation: handleDeletePvPlant,
    });
  };

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const handleCreatePvPlant = () => {
    history.push(PVPLANT_MANAGEMENT_CREATE_PAGE);
  };
  const currentItems = pvPlantsList?.items || [];

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    return <ExportForm onSuccessfulModal={onSuccessfulModal} filterParams={filterParams} />;
  };
  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const createExport = () => {
    toggleExportModal();
  };

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
  };

  const selectedClientData = clientList[clientList.findIndex((client) => client.value === filterParams.selectedClient)];
  const selectedPlantManagerOption = userList?.find((o) => o.value === filterParams.selectedPlantManager) || null;

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              PV Plants List
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {pvPlantsList?.pagination?.totalCount} PV Plants.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedPvPlantStatus} title={"Status"} isNotMap={true} isStr={true} />
              {selectedClientData ? (
                <FilteredByList data={selectedClientData?.label} title={"Client"} isNotMap={true} isStr={true} />
              ) : null}
              {selectedPlantManagerOption ? (
                <FilteredByList
                  data={selectedPlantManagerOption?.label}
                  title={"Client"}
                  isNotMap={true}
                  isStr={true}
                />
              ) : null}
              <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getPlants", "createPlant", "updatePlant", "deletePlant"]}>
                    <li>
                      <Button
                        disabled={pvPlantsList?.pagination?.totalCount === 0}
                        id="export-pvplant"
                        className="btn btn-white btn-outline-light"
                        type="export"
                        isLoading={exportLoading}
                        onClick={createExport}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </Check>
                  <li className="nk-block-tools-opt">
                    <Check permission={["createPlant"]}>
                      <Button id="add-pvplant-btn" color="primary" className="btn-icon" onClick={handleCreatePvPlant}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </Check>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-pvplant-btn"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <div id="pvplant-filter" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>

                    {/* <Sidebar toggleState={true}><PvPlantListFilter /></Sidebar> */}
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    id="cancle-pvplant-search"
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    id="search-input-pvplant"
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by name.enter to search "
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button id="search-text" className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow style={{ display: "flex" }}>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    id="pvplant-sort"
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "name")
                        : handleNameSort("ASC", "name");
                    }}
                  >
                    PV Plant
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      id="pvplant-asc"
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "name");
                      }}
                      name={`${
                        filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      id="pvplan-desc"
                      onClick={() => {
                        handleNameSort("DESC", "name");
                      }}
                      name={`${
                        filterParams.sortingField === "name" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "identifier" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "identifier")
                        : handleNameSort("ASC", "identifier");
                    }}
                  >
                    Acronym
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingField === "identifier" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "identifier");
                      }}
                      name={`${
                        filterParams.sortingField === "identifier" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <div className="d-flex " style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "postal_code" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "postal_code")
                        : handleNameSort("ASC", "postal_code");
                    }}
                  >
                    Postal Code
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "postal_code");
                      }}
                      name={`${
                        filterParams.sortingField === "postal_code" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "postal_code");
                      }}
                      name={`${
                        filterParams.sortingField === "postal_code" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex " style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "clientId" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "clientId")
                        : handleNameSort("ASC", "clientId");
                    }}
                  >
                    Client
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "clientId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "clientId"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "clientId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "clientId"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                        ? handleNameSort("DESC", "status")
                        : handleNameSort("ASC", "status");
                    }}
                  >
                    BGE status
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "status"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "status");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "status"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "size"
                        ? handleNameSort("DESC", "size")
                        : handleNameSort("ASC", "size");
                    }}
                  >
                    Size MW
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "size");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "size"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "size");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "size"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plantManagerId"
                        ? handleNameSort("DESC", "plantManagerId")
                        : handleNameSort("ASC", "plantManagerId");
                    }}
                  >
                    Plant Manager
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "plantManagerId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "plantManagerId"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "plantManagerId");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "plantManagerId"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow>{/* <span className="sub-text">Actions</span> */}</DataTableRow>
            </DataTableHead>
            {/*Head*/}
            <div>
              <Sidebar toggleState={showFilter}>
                <div>
                  <PvPlantListFilter />
                </div>
              </Sidebar>
            </div>
            {pvPlantLoading ? (
              <></>
            ) : (
              currentItems.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      {/* {item && item.status === "ACTIVE" ? */}
                      <div onClick={() => window.location.reload(false)}>
                        <Link id="pvplant-view" to={`${PVPLANT_MANAGEMENT_VIEW_PAGE}`.replace(":plantId", item.id)}>
                          <div onClick={() => window.location.reload(false)} className="user-card">
                            <UserAvatar theme={"primary"} className="xs" text={findUpper(item.name)} />
                            <div className="user-name">
                              <span
                                data-toggle="tooltip"
                                title={item.name}
                                style={{
                                  width: "90px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                                className="tb-lead"
                              >
                                {item.name || "-"}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span
                        data-toggle="tooltip"
                        title={item.identifier}
                        style={{
                          width: "90px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        {item?.identifier || "-"}
                      </span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>{item?.postalCode || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item.clients?.name || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{item?.status || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{item?.size || "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      {item?.plantManager?.firstName || item?.plantManager?.lastName ? (
                        <span>
                          {item?.plantManager?.firstName} {item?.plantManager?.lastName}
                        </span>
                      ) : (
                        <span>{"-"}</span>
                      )}
                    </DataTableRow>

                    <DataTableRow className="nk-tb-col-tools">
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              id="menu-btn-pvplant"
                              tag="a"
                              className="dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <React.Fragment>
                                  <Check permission={["updatePlant"]}>
                                    {item && item.status === "ACTIVE" && (
                                      <>
                                        <li /* className="nk-tb-action-hidden" */ onClick={() => onEditClick(item.id)}>
                                          <DropdownItem
                                            id="edit-pvplant-btn"
                                            tag="a"
                                            href="#edit"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="edit-alt-fill"></Icon>
                                            <span>Edit Plant</span>
                                          </DropdownItem>
                                        </li>
                                        <li className="divider"></li>
                                      </>
                                    )}
                                    {item.isArchived ? (
                                      <React.Fragment>
                                        <li onClick={() => handleUnArchive(item.id)}>
                                          <DropdownItem
                                            id="unarchive-pvplant-btn"
                                            tag="a"
                                            href="#unarchive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="unarchive"></Icon>
                                            <span>UnArchive Plant</span>
                                          </DropdownItem>
                                        </li>
                                        <li className="divider"></li>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <li onClick={() => handleArchive(item.id)}>
                                          <DropdownItem
                                            id="archive-pvplant-btn"
                                            tag="a"
                                            href="#archive"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="archive"></Icon>
                                            <span>Archive Plant</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </Check>
                                  <Check permission={["deletePlant"]}>
                                    {item.status !== "SUSPENDED" && (
                                      <>
                                        <li className="divider"></li>
                                        <li onClick={() => handleDeleteConfirmation(item.id)}>
                                          <DropdownItem
                                            id="delete-pvplant-btn"
                                            tag="a"
                                            href="#delete"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Delete Plant</span>
                                          </DropdownItem>
                                        </li>
                                      </>
                                    )}
                                  </Check>
                                </React.Fragment>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </DataTableRow>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {pvPlantLoading && <Skeleton count={10} className="w-100" />}

          <div className="card-inner">
            {pvPlantsList.pagination && pvPlantsList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={pvPlantsList.pagination.totalCount}
                paginate={pvPlantManagementContext.updateShowListPage}
                currentPage={pvPlantsList.pagination.currentPage}
                paginatePage={pvPlantManagementContext.updateShowListSize}
              />
            ) : (
              !pvPlantLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        <ModalViewer
          title="PV-Plant - Select the Export Fields"
          isOpen={isExpotModalOpen}
          size="md"
          toggleModal={toggleExportModal}
          component={getExportComponent()}
        />
      </Block>
    </React.Fragment>
  );
};
export default PvPlantListCompact;
