/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { compareAsc } from "date-fns";
import download from "js-file-download";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
  PaginationComponent,
  RSelect,
  Sidebar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { STRING_FILTER } from "../../../../constants/PreferenceKeys";
import { STRING_IMPORT } from "../../../../constants/routes";
import ModalViewer from "../../ModalViewer";
import { StringContext } from "../StringProvider";
import { checkIdentifier, createStringAPI, deleteStringAPI, exportTask, updateStringAPI } from "../StringRepository";
import { ORDER_OPTION, SORT_OPTION, STATUSES } from "../constants";
import FilterComponent from "./FilterComponent";
import "./stringList.css";
import Check from "../../../../components/check_roles/check";
import { sortOption } from "../../../../utils/Utils";

const StringList = ({ currentViewPvPlantId, plantStatus, isArchived }) => {
  const {
    stringData,
    stringLoading,
    setStringData,
    sfState,
    isFilterApplied,
    stringList,
    plants,
    failureReason: failureReasons,
    handleApplyFilter,
    filterParams,
    pagination,
    updateShowListPage,
    showFilter,
    updateShowListSize,
    loadStringList,
    importStringFile,
    isImportLoading,
    checkIsFilterApplied,
    fetchImport,
    initialFilterParams,
  } = useContext(StringContext);

  const [confirmModal, setConfirmModal] = useState(false);
  const [currentChange, setCurrentChange] = useState({});
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);
  const history = useHistory();

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  const [columns] = useState(
    !currentViewPvPlantId
      ? [
          { columnId: "sno", width: 30 },
          { columnId: "identifier", width: 100 },
          { columnId: "plantId", width: 150 },
          { columnId: "addedAt", width: 120 },
          { columnId: "investigatedAt", width: 120 },
          { columnId: "repairedAt", width: 120 },
          { columnId: "status", width: 150 },
          { columnId: "failureReasonId", width: 150 },
          { columnId: "monitoringComments", width: 160 },
          { columnId: "workComments", width: 160 },
          { columnId: "noOfStringsPerDuct", width: 200 },
        ]
      : [
          { columnId: "sno", width: 30 },
          { columnId: "identifier", width: 100 },
          { columnId: "addedAt", width: 120 },
          { columnId: "investigatedAt", width: 120 },
          { columnId: "repairedAt", width: 120 },
          { columnId: "status", width: 150 },
          { columnId: "failureReasonId", width: 150 },
          { columnId: "monitoringComments", width: 160 },
          { columnId: "workComments", width: 160 },
          { columnId: "noOfStringsPerDuct", width: 150 },
        ]
  );

  const toggle = () => setonSearch(!onSearch);
  const focusSearchRef = useRef();
  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });
  useEffect(() => {
    const filters = localStorage.getItem(STRING_FILTER);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
      });
    }
  }, []);
  
  useEffect(() => {
    handleApplyFilter(selectedFilter);
    checkIsFilterApplied();
  }, [selectedFilter?.sortBy,selectedFilter?.order])
  

  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const headerRow = {
    rowId: "header",
    cells: !currentViewPvPlantId
      ? [
          { type: "header", text: "Sno." },
          { type: "header", text: "Identifier" },
          { type: "header", text: "Plant" },
          { type: "header", text: "Added At" },
          { type: "header", text: "Investigated At" },
          { type: "header", text: "Repaired At" },
          { type: "header", text: "Status" },
          { type: "header", text: "Failure Reason" },
          { type: "header", text: "Monitoring Comments" },
          { type: "header", text: "Works Comments" },
          { type: "header", text: "No. of Strings fixed per duct" },
        ]
      : [
          { type: "header", text: "Sno." },
          { type: "header", text: "Identifier" },
          { type: "header", text: "Added At" },
          { type: "header", text: "Investigated At" },
          { type: "header", text: "Repaired At" },
          { type: "header", text: "Status" },
          { type: "header", text: "Failure Reason" },
          { type: "header", text: "Monitoring Comments" },
          { type: "header", text: "Works Comments" },
          { type: "header", text: "No. of Strings fixed per duct" },
        ],
  };
  const style = {
    textAlign: "center",
  };
  const getRows = () => {
    const d = stringData.map((string, idx) => ({
      rowId: string.id ? string.id : -idx,
      cells: !currentViewPvPlantId
        ? [
            { type: "text", text: (idx + 1).toString() },
            { type: "text", text: string.identifier },
            {
              type: "dropdown",
              selectedValue: string.plantId,
              values: plants,
              isOpen: string.plantIdOpen,
            },
            { type: "date", date: string.addedAt && new Date(string.addedAt), format: Intl.DateTimeFormat("in-IN") },
            {
              type: "date",
              date: string.investigatedAt && new Date(string.investigatedAt),
              format: Intl.DateTimeFormat("in-IN"),
            },
            {
              type: "date",
              date: string.repairedAt && new Date(string.repairedAt),
              format: Intl.DateTimeFormat("in-IN"),
            },
            {
              type: "dropdown",
              selectedValue: string.status,
              values: STATUSES,
              isOpen: string.statusOpen,
            },
            {
              type: "dropdown",
              selectedValue: string.failureReason?.name,
              values: failureReasons,
              isOpen: string.failureReasonIdOpen,
            },
            { type: "text", text: string.monitoringComments || "" },
            { type: "text", text: string.workComments || "" },
            { type: "number", value: string.noOfStringsPerDuct, style: { style } },
          ]
        : [
            { type: "text", text: (idx + 1).toString() },
            { type: "text", text: string.identifier },
            {
              type: "date",
              date: string.addedAt && new Date(string.addedAt),
              format: Intl.DateTimeFormat("in-IN"),
            },
            {
              type: "date",
              date: string.investigatedAt && new Date(string.investigatedAt),
              format: Intl.DateTimeFormat("in-IN"),
            },
            {
              type: "date",
              date: string.repairedAt && new Date(string.repairedAt),
              format: Intl.DateTimeFormat("in-IN"),
            },
            {
              type: "dropdown",
              selectedValue: string.status,
              values: STATUSES,
              isOpen: string.statusOpen,
            },
            {
              type: "dropdown",
              selectedValue: string.failureReason?.name,
              values: failureReasons,
              isOpen: string.failureReasonIdOpen,
            },
            { type: "text", text: string.monitoringComments || "" },
            { type: "text", text: string.workComments || "" },
            { type: "number", value: string.noOfStringsPerDuct, style: { style } },
          ],
    }));
    return [headerRow, ...d];
  };

  const createString = async (index) => {
    try {
      const createBody = currentViewPvPlantId
        ? { plantId: currentViewPvPlantId.toString(), ...stringData[index] }
        : stringData[index];
      const response = await createStringAPI(createBody);
      if (response?.string?.id) {
        stringData[index].id = response.string.id;
        if (!stringData[index].addedAt) {
          stringData[index].addedAt = new Date();
        }
        delete stringData[index].new;
        setStringData([...stringData]);
        loadStringList(currentViewPvPlantId);
      }
    } catch (err) {}
  };

  const checkMultipleIdentifier = async (identifier, plantId, stringIds) => {
    //call api to check identifier
    try {
      const result = await checkIdentifier(identifier, plantId, stringIds);
      if (result.success) {
        return result.data;
      }
      return result;
    } catch (err) {
      return false;
    }
  };

  const updateString = async (index, column, value) => {
    try {
      await updateStringAPI(stringData[index].id, { [column]: value });
    } catch (err) {}
  };

  const deleteFunc = async (index) => {
    let rowIndex = index;

    if (rowIndex < 0) {
      rowIndex = -index;
    } else {
      rowIndex = stringData.findIndex((d) => d.id === index);
    }
    if (stringData[rowIndex].new) {
      setStringData((prev) => {
        return [...prev.filter((p, idx) => idx !== rowIndex)];
      });
    } else {
      let stringId = stringData[rowIndex].id;
      try {
        await deleteStringAPI(stringId);
        setStringData((prev) => {
          return [...prev.filter((p, idx) => p.id !== stringId)];
        });
      } catch (err) {}
    }
  };

  const handleDeleteConfirmation = (index) => {
    confirmationSweetAlert({
      id: index,
      handleConfirmation: deleteFunc,
    });
  };
  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, taskText: onSearchText });
  };

  const onSubmit = () => {
    let rowIndex = currentChange.rowId;
    if (rowIndex < 0) {
      rowIndex = -currentChange.rowId;
    } else {
      rowIndex = stringData.findIndex((d) => d.id === currentChange.rowId);
    }
    if (currentChange.type === "dropdown") {
      if (!stringData[rowIndex]["new"]) {
        updateString(rowIndex, currentChange.columnId, currentChange.newCell.selectedValue);
      } else if (stringData[rowIndex]["identifier"]) {
        createString(rowIndex);
      }
    } else {
      if (!stringData[rowIndex]["new"]) {
        updateString(rowIndex, currentChange.columnId, currentChange.newCell.text);
      } else if (stringData[rowIndex]["plantId"]) {
        createString(rowIndex);
      }
    }
    setConfirmModal(false);
  };

  const getComponent = () => {
    return (
      <>
        <Row className="d-flex justify-content-center">
          <p>{`Identifier already exist for this plants`}</p>
        </Row>
        <Row className="d-flex justify-content-center">
          <p>Are you sure want to confirm?</p>
        </Row>
        <Row className=" mt-5 d-flex justify-content-center">
          <Col lg="3">
            <Button id="confirm-plantstring" color="primary" onClick={() => onSubmit()}>
              CONFIRM
            </Button>
          </Col>
          <Col lg="3">
            <Button id="cancel-plantstring" color="primary" onClick={() => setConfirmModal(false)}>
              CANCEL
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const handleChanges = async (changes) => {
    let stringIds;
    // const change = changes[0]
    await Promise.all(
      changes.map(async (change, i) => {
        let rowIndex = change.rowId;
        if (rowIndex <= 0) {
          rowIndex = -change.rowId;
        } else {
          rowIndex = stringData.findIndex((d) => d.id === change.rowId);
        }
        switch (change.type) {
          case "date":
            const year = new Date(change["newCell"]["date"]).getFullYear();
            const data = Math.floor(year / 10000);
            if (data > 0) {
              errorToast({ description: "Year should contain only 4 digits" });
              break;
            }
            if (
              change.columnId === "repairedAt" &&
              (stringData[rowIndex]["investigatedAt"] || stringData[rowIndex]["addedAt"])
            ) {
              if (stringData[rowIndex]["investigatedAt"] && stringData[rowIndex]["addedAt"]) {
                if (
                  compareAsc(change.newCell.date, new Date(stringData[rowIndex]["investigatedAt"])) >= 0 &&
                  compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
                ) {
                  stringData[rowIndex][change.columnId] = change.newCell.value;
                  if (!stringData[rowIndex]["new"] && change.newCell.date) {
                    updateString(rowIndex, change.columnId, change.newCell.date);
                  }
                  break;
                } else {
                  errorToast({ description: "Repaired At should be greater or equal to Investigated At & Added At" });
                  break;
                }
              } else if (
                stringData[rowIndex]["addedAt"] &&
                compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                if (!stringData[rowIndex]["new"] && change.newCell.date) {
                  updateString(rowIndex, change.columnId, change.newCell.date);
                }
                break;
              } else if (
                stringData[rowIndex]["investigatedAt"] &&
                compareAsc(change.newCell.date, new Date(stringData[rowIndex]["investigatedAt"])) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                if (!stringData[rowIndex]["new"] && change.newCell.date) {
                  updateString(rowIndex, change.columnId, change.newCell.date);
                }
                break;
              } else {
                errorToast({ description: "Repaired At should be greater or equal to Investigated At & Added At" });
                break;
              }
            }
            if (
              change.columnId === "investigatedAt" &&
              (stringData[rowIndex]["repairedAt"] || stringData[rowIndex]["addedAt"])
            ) {
              if (stringData[rowIndex]["addedAt"] && stringData[rowIndex]["repairedAt"]) {
                if (
                  compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0 &&
                  compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
                ) {
                  stringData[rowIndex][change.columnId] = change.newCell.value;
                  if (!stringData[rowIndex]["new"] && change.newCell.date) {
                    updateString(rowIndex, change.columnId, change.newCell.date);
                  }
                  break;
                } else {
                  errorToast({
                    description: "Investigated At should be lesser or equal to Repaired At & greater than Added At",
                  });
                  break;
                }
              } else if (
                stringData[rowIndex]["addedAt"] &&
                compareAsc(change.newCell.date, new Date(stringData[rowIndex]["addedAt"])) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                if (!stringData[rowIndex]["new"] && change.newCell.date) {
                  updateString(rowIndex, change.columnId, change.newCell.date);
                }
                break;
              } else if (
                stringData[rowIndex]["repairedAt"] &&
                compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                if (!stringData[rowIndex]["new"] && change.newCell.date) {
                  updateString(rowIndex, change.columnId, change.newCell.date);
                }
                break;
              } else {
                errorToast({
                  description: "Investigated At should be lesser or equal to Repaired At & greater than Added At",
                });
                break;
              }
            }
            if (
              change.columnId === "addedAt" &&
              (stringData[rowIndex]["repairedAt"] || stringData[rowIndex]["investigatedAt"])
            ) {
              if (stringData[rowIndex]["investigatedAt"] && stringData[rowIndex]["repairedAt"]) {
                if (
                  compareAsc(new Date(stringData[rowIndex]["investigatedAt"]), change.newCell.date) >= 0 &&
                  compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0
                ) {
                  stringData[rowIndex][change.columnId] = change.newCell.value;
                  if (!stringData[rowIndex]["new"] && change.newCell.date) {
                    updateString(rowIndex, change.columnId, change.newCell.date);
                  }
                  break;
                } else {
                  errorToast({ description: "Added At should be lesser or equal to Repaired At & Investigated At" });
                  break;
                }
              } else if (
                stringData[rowIndex]["investigatedAt"] &&
                compareAsc(new Date(stringData[rowIndex]["investigatedAt"]), change.newCell.date) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                if (!stringData[rowIndex]["new"] && change.newCell.date) {
                  updateString(rowIndex, change.columnId, change.newCell.date);
                }
                break;
              } else if (
                stringData[rowIndex]["repairedAt"] &&
                compareAsc(new Date(stringData[rowIndex]["repairedAt"]), change.newCell.date) >= 0
              ) {
                stringData[rowIndex][change.columnId] = change.newCell.value;
                if (!stringData[rowIndex]["new"] && change.newCell.date) {
                  updateString(rowIndex, change.columnId, change.newCell.date);
                }
                break;
              } else {
                errorToast({ description: "Added At should be lesser or equal to Repaired At & Investigated At" });
                break;
              }
            }
            stringData[rowIndex][change.columnId] = change.newCell.value;
            if (!stringData[rowIndex]["new"] && change.newCell.date) {
              updateString(rowIndex, change.columnId, change.newCell.date);
            }
            break;
          case "text":
            stringData[rowIndex][change.columnId] = change.newCell.text;
            if (stringData[rowIndex].id) {
              stringIds = stringData[rowIndex].id;
            }
            if (change.columnId === "identifier" && (currentViewPvPlantId || stringData[rowIndex]["plantId"])) {
              const condition = await checkMultipleIdentifier(
                change.newCell.text,
                currentViewPvPlantId || stringData[rowIndex]["plantId"],
                stringIds
              );
              if (condition) {
                setConfirmModal(true);
                setCurrentChange(change);
              } else {
                if (!stringData[rowIndex]["new"] && change.newCell.text) {
                  updateString(rowIndex, change.columnId, change.newCell.text);
                }
                if (
                  stringData[rowIndex]["new"] &&
                  change.columnId === "identifier" &&
                  (currentViewPvPlantId || stringData[rowIndex]["plantId"])
                ) {
                  createString(rowIndex);
                }
              }
            } else {
              if (!stringData[rowIndex]["new"] && change.newCell.text) {
                updateString(rowIndex, change.columnId, change.newCell.text);
              }
            }

            break;
          case "number":
            stringData[rowIndex][change.columnId] = change.newCell.value;
            if (!stringData[rowIndex]["new"] && change.newCell.value) {
              updateString(rowIndex, change.columnId, change.newCell.value);
            }
            break;

          case "dropdown":
            stringData[rowIndex][`${change.columnId}Open`] = change.newCell.isOpen;
            if (change.newCell.selectedValue !== change.previousCell.selectedValue) {
              if (change.newCell.selectedValue) {
                stringData[rowIndex][change.columnId] = change.newCell.selectedValue;
              }
              if (stringData[rowIndex].id) {
                stringIds = stringData[rowIndex].id;
              }
              if (change.columnId === "plantId" && stringData[rowIndex]["identifier"]) {
                const condition = await checkMultipleIdentifier(
                  stringData[rowIndex]["identifier"],
                  change.newCell.selectedValue,
                  stringIds
                );
                if (condition) {
                  setConfirmModal(true);
                  setCurrentChange(change);
                } else {
                  if (!stringData[rowIndex]["new"] && change.newCell.selectedValue) {
                    updateString(rowIndex, change.columnId, change.newCell.selectedValue);
                  }
                  if (stringData[rowIndex]["new"] && stringData[rowIndex]["identifier"]) {
                    createString(rowIndex);
                  }
                }
              } else {
                if (!stringData[rowIndex]["new"] && change.newCell.selectedValue) {
                  updateString(rowIndex, change.columnId, change.newCell.text);
                }
              }
            }
            break;
          default:
            break;
        }
      })
    );
    setStringData([...stringData]);
  };

  const exportTaskFuntion = async () => {
    try {
      if (currentViewPvPlantId) {
        filterParams["selectedPlant"] = [currentViewPvPlantId];
      }
      setExportLoading(true);
      const response = await exportTask(filterParams);
      download(response, `string.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const simpleHandleContextMenu = (selectedRowIds, selectedColIds, selectionMode, menuOptions) => {
    if (selectionMode === "row") {
      menuOptions = [
        ...menuOptions,
        {
          id: "removeString",
          label: "Remove String",
          handler: () => {
            if (selectedRowIds.length) {
              selectedRowIds.forEach((index) => {
                handleDeleteConfirmation(index);
              });
            }
          },
        },
        {
          id: "addString",
          label: "Add String",
          handler: () => {
            setStringData((prev) => {
              return [
                ...prev,
                {
                  identifier: "",
                  status: "OPEN",
                  new: true,
                  statusOpen: false,
                  plantIdOpen: false,
                  failureReasonIdOpen: false,
                },
              ];
            });
          },
        },
      ];
    }
    return menuOptions;
  };

  const bulkUploadNextClick = async () => {
    try {
      await importStringFile(fileData, currentViewPvPlantId);
      successToast({ description: "File validated successfully" });
      onImportClick();
    } catch (response) {
      errorToast({ description: response.error?.message || "something went wrong" });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/doc": [".xls", ".xlsx"],
      "text/plain": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      // call upload chart here
      setFileData(acceptedFiles[0]);
    },
  });
  const getImportComponent = () => {
    return (
      <div>
        <div md="12" sm="12">
          <div className="d-flex justify-content-center">
            <p className="mr-2 p-0">Upload .csv string list</p>
            <a
              className="text-blue pb-2"
              style={{ textDecoration: "underline" }}
              href="https://tesark-wp.s3.amazonaws.com/BGE/bge-string-template.csv"
              download
            >
              Download String Template.csv
            </a>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{
              marginTop: 10,
              borderRadius: 10,
              boxShadow: "0 0 10px 0 rgba(0,0,0,0.05)",
              minHeight: "100px",
              backgroundColor: "white",
            }}
          >
            <div m={8} className="container" style={{ cursor: "pointer" }}>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Icon name="upload-cloud" style={{ fontSize: "97px" }}>
                  {" "}
                </Icon>
                <p>{fileData ? `${fileData.name} is uploaded` : "Add file or drop files here"}</p>
              </div>
            </div>
          </div>
          <Row className="d-flex justify-content-center" style={{ marginTop: 20 }}>
            <Button
              color="primary"
              disabled={fileData === null}
              isLoading={isImportLoading}
              onClick={() => {
                if (fileData) {
                  bulkUploadNextClick();
                  setImportModal(false);
                }
              }}
            >
              Validate File
            </Button>
          </Row>
        </div>
      </div>
    );
  };

  const plantSelectedData = filterParams.selectedPlant.map((item, key) => {
    return plants?.[plants?.findIndex((status) => status.value === item)];
  });

  const assignedfailureReasonSelecterData = filterParams.selectedFailureReason.map((item, key) => {
    return failureReasons?.[failureReasons?.findIndex((status) => status.value === item)];
  });
  const selectedSortByData = SORT_OPTION.find((p) => p.value === filterParams.sortBy);

  const onImportClick = async () => {
    const res = await fetchImport(currentViewPvPlantId);
    if (res.isImportId === false) {
      setImportModal(true);
    } else {
      //navigate to string import page
      history.push(STRING_IMPORT.replace(":importId", res.importId).replace(":plantId", currentViewPvPlantId));
    }
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const selectedSortBy = SORT_OPTION.find((p) => p.value === selectedFilter.sortBy)||null;
  const selectedOrder = ORDER_OPTION.find((p) => p.value === selectedFilter.order)||null;

console.log('selectedSortBy', selectedSortBy)

  const handleSortBy = (selectedOption) => {
    const result = selectedOption.value;
    handleFilterSelection({ sortBy: result });
  };
  const handleOrder = (selectedOption) => {
    const result = selectedOption.value;
    handleFilterSelection({ order: result });
  };

  console.log('selectedFilter', selectedFilter)
  return failureReasons.length && plants.length ? (
    <div>
      <BlockHead size="sm">
        <div className="justify-content-md-between d-md-flex align-items-center">
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              String Lists
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {stringList?.pagination?.totalCount || 0} Strings.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              <FilteredByList data={filterParams.selectedStatus} title={"Status"} isMap={false} isStr={false} />

              {plantSelectedData ? (
                <FilteredByList data={plantSelectedData} title={"Plant Name"} isMap={true} isStr={false} />
              ) : null}

              {assignedfailureReasonSelecterData ? (
                <FilteredByList
                  data={assignedfailureReasonSelecterData}
                  title={"failure Reason"}
                  isMap={true}
                  isStr={false}
                />
              ) : null}

              {selectedSortByData ? (
                <FilteredByList data={selectedSortByData.label} title={"Sort By"} isNotMap={true} isStr={true} />
              ) : null}

              {filterParams.investigatedAt ? (
                <FilteredByList
                  data={filterParams.investigatedAt}
                  title={"Investigated at"}
                  isNotMap={false}
                  isStr={true}
                />
              ) : null}

              {filterParams.addedAt ? (
                <FilteredByList data={filterParams.addedAt} title={"Added at"} isNotMap={false} isStr={true} />
              ) : null}
            </div>
          </BlockHeadContent>
          <BlockHeadContent className="d-flex justify-content-md-end mt-3 mt-md-0">
            {plantStatus === "ACTIVE" && isArchived === false && currentViewPvPlantId ? (
              <Check permission={["manageTickets"]}>
                <Button
                  id="import-plantstring-btn"
                  className={`btn btn-white mr-2 btn-outline-light`}
                  size="md"
                  isLoading={isImportLoading}
                  onClick={() => onImportClick()}
                >
                  <Icon name="upload-cloud"> </Icon>
                  <span>Import</span>
                </Button>
              </Check>
            ) : null}
            <Check permission={["getTickets", "manageTickets"]}>
              <a
                id="export-plantstring-btn"
                className={`btn btn-white btn-outline-light ${
                  stringList?.pagination?.totalCount === 0 ? "disabled" : ""
                }`}
                type="export"
                size="md"
                isLoading={exportLoading}
                onClick={() => exportTaskFuntion()}
              >
                <Icon name="download-cloud"> </Icon>
                <span>Export</span>
              </a>
            </Check>
            {plantStatus ? (
              plantStatus === "ACTIVE" && isArchived === false ? (
                <Check permission={["manageTickets"]}>
                  <Button
                    id="add-plantstring"
                    className="d-flex justify-content-end btn btn-icon mr-2 ml-3"
                    color="primary"
                    onClick={() => {
                      setStringData((prev) => {
                        return [
                          ...prev,
                          {
                            identifier: "",
                            status: "OPEN",
                            new: true,
                            statusOpen: false,
                            plantIdOpen: false,
                            failureReasonIdOpen: false,
                          },
                        ];
                      });
                    }}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                </Check>
              ) : null
            ) : (
              <Check permission={["manageTickets"]}>
                <Button
                  id="add-plantstring"
                  className="d-flex justify-content-end btn btn-icon mr-2 ml-3"
                  color="primary"
                  onClick={() => {
                    setStringData((prev) => {
                      return [
                        ...prev,
                        {
                          identifier: "",
                          status: "OPEN",
                          new: true,
                          statusOpen: false,
                          plantIdOpen: false,
                          failureReasonIdOpen: false,
                        },
                      ];
                    });
                  }}
                >
                  <Icon name="plus"></Icon>
                </Button>
              </Check>
            )}
          </BlockHeadContent>
        </div>
      </BlockHead>
      <BlockContent>
        <Sidebar toggleState={showFilter}>
          <FilterComponent failureReason={failureReasons} plants={plants} />
        </Sidebar>

        <DataTable  className="card-stretch">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex w-50 ">
            <div className="w-100 mx-3">
              <label className="overline-title overline-title-alt">Order</label>
              <RSelect options={ORDER_OPTION} placeholder="Order" onChange={handleOrder} value={selectedOrder} />
            </div>
            <div className="w-100">
              <label className="overline-title overline-title-alt">Sort By</label>
              <RSelect
                options={sortOption(SORT_OPTION)}
                placeholder="Sort by"
                onChange={handleSortBy}
                value={selectedSortBy}
              />
            </div>
            </div>
            </div>
            <div className="col-md-6">
            <div className="card-inner position-relative card-tools-toggle d-flex justify-content-end">
              <div className="card-title-group ">
                <div className="card-tools"></div>

                <div className="card-tools mr-n1 ">
                  <ul className="btn-toolbar gx-1">     
                    <li>
                      <a
                        id="search-plantstring"
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <div id="filter-plantstring" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      id="cancel-search-plantstring"
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, taskText: "" });
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      id="search-input-plantstring"
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by Identifier.enter to search"
                      ref={(el) => {
                        focusSearchRef.current = !onSearch ? el : null;
                      }}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                      }}
                    />
                    <Button id="tab-to-search-plantstring" className="search-submit btn-icon">
                      <Icon name="search" onClick={onSearchAction}></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="col-12 overflow-auto" style={{ paddingBottom: "15%" }}>
            {stringLoading ? (
              <></>
            ) : stringData.length ? (           
              <ReactGrid
                rows={getRows()}
                columns={columns}
                onCellsChanged={handleChanges}
                enableRowSelection
                onContextMenu={simpleHandleContextMenu}
                stickyTopRows={1}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No data found</span>
              </div>
            )}
            {stringLoading ? <Skeleton count={10} className="w-100" /> : null}
          </div>

          <div className="card-inner">
            {stringList.pagination && stringList.pagination.totalCount > 0 && (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={stringList.pagination.totalCount}
                paginate={updateShowListPage}
                currentPage={stringList.pagination.currentPage}
                pageOptions={[100, 200, 300, 400, 500]}
                paginatePage={updateShowListSize}
              />
            )}
            <Row className="d-flex justify-content-end">
              <Col className="d-flex justify-content-end mt-2">
                {plantStatus ? (
                  plantStatus === "ACTIVE" && isArchived === false ? (
                    <Check permission={["manageTickets"]}>
                      <Button
                        id="add-plantstring"
                        className="d-flex justify-content-end btn btn-icon"
                        color="primary"
                        onClick={() => {
                          setStringData((prev) => {
                            return [
                              ...prev,
                              {
                                identifier: "",
                                status: "OPEN",
                                new: true,
                                statusOpen: false,
                                plantIdOpen: false,
                                failureReasonIdOpen: false,
                              },
                            ];
                          });
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                    </Check>
                  ) : null
                ) : (
                  <Check permission={["manageTickets"]}>
                    <Button
                      id="add-plantstring"
                      className="d-flex justify-content-end btn btn-icon"
                      color="primary"
                      onClick={() => {
                        setStringData((prev) => {
                          return [
                            ...prev,
                            {
                              identifier: "",
                              status: "OPEN",
                              new: true,
                              statusOpen: false,
                              plantIdOpen: false,
                              failureReasonIdOpen: false,
                            },
                          ];
                        });
                      }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                  </Check>
                )}
              </Col>
            </Row>
          </div>
        </DataTable>
      </BlockContent>
      <ModalViewer
        size="md"
        title="Confirm Box"
        isOpen={confirmModal}
        toggleModal={() => setConfirmModal(!confirmModal)}
        component={getComponent()}
      />
      <ModalViewer
        size="md"
        title="Import String"
        isOpen={importModal}
        toggleModal={() => setImportModal(!importModal)}
        component={getImportComponent()}
      />
    </div>
  ) : null;
};
export default StringList;
