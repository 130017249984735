import React from "react";
import useBoolean from "../../hooks/useBoolean";
import {
  getAdminPvPlantList,
  getAdminPvPlantListFilterOptions,
  createPvPlantAPI,
  deletePvPlantAPI,
  archivePvPlantAPI,
  unarchivePvPlantAPI,
  getAllUsers,
  getAllClients,
  getPvPlantAPI,
  updatePvPlantAPI,
  getAllHealthAndSafeties,
  getAllDnos,
  getPlantCorrectiveTaskList,
  getPlantRemedialTaskList,
  getPvPlantTasksAPI,
  getAttributeMasterList,
  updateAttributeAPI,
  getAttributes,
} from "./PvPlantManagementRepository";

const initialPaginationState = {
  page: 1,
  size: 10,
};
const initialFilterOptions = {
  plantStatuses: [],
  users: [],
};
const initialFilterParams = {
  selectedPvPlantStatus: [],
  isArchiveSelected: false,
  searchText: "",
  sortingOrder: "",
  sortingField: "",
  selectedClient: [],
  selectedPlantManager: [],
};

export const PvPlantManagementContext = React.createContext();

export const PvPlantManagementProvider = (props) => {
  const [pvPlantsList, setPvPlantsList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [dnoList, setDnoList] = React.useState([]);
  const [correctiveTaskList, setCorrectiveTaskList] = React.useState([]);
  const [remedialTaskList, setRemedialTaskList] = React.useState([]);
  const [plantTasks, setPlantTasks] = React.useState([]);
  const [healthAndSafetyList, setHealthAndSafetyList] = React.useState([]);
  const [attributeMasterList, setAttributeMasterList] = React.useState([]);
  const [attributes, setAttributes] = React.useState([]);

  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [filterOptions, setFilterOptions] = React.useState(Object.assign({}, initialFilterOptions));
  const [currentViewPvPlant, setCurrentViewPvPlant] = React.useState(null);

  //Loaders
  const [pvPlantLoading, pvPlantState] = useBoolean(false);
  const [filtersLoading, flState] = useBoolean(false);
  const [clientLoading, clState] = useBoolean(false);
  const [loadingPvPlantView, lswState] = useBoolean(false);
  const [attibuteLoading, attributeState] = useBoolean(false);

  //state
  const [showFilter, sfState] = useBoolean(false);

  const getpvPlantListFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      selectedPvPlantStatus,
      isArchiveSelected,
      searchText,
      sortingField,
      sortingOrder,
      selectedPlantManager,
      selectedClient,
    } = filterParams;
    if (selectedPvPlantStatus) queryParams["plantStatus"] = selectedPvPlantStatus;
    if (isArchiveSelected != null) queryParams["plantIsArchived"] = isArchiveSelected;
    if (selectedPvPlantStatus) queryParams["plantSearchText"] = searchText;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (selectedPlantManager) queryParams["plantManager"] = selectedPlantManager;
    if (selectedClient) queryParams["client"] = selectedClient;
    return queryParams;
  };

  const loadFilterOptions = async () => {
    flState.on();

    try {
      const response = await getAdminPvPlantListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  const checkIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedPvPlantStatus.length != 0 ||
      filterParams.selectedClient.length != 0 ||
      filterParams.selectedPlantManager.length != 0
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();

  const loadPvPLantList = async () => {
    pvPlantState.on();
    try {
      const filterParams = getpvPlantListFilterParams();
      const response = await getAdminPvPlantList(filterParams);
      setPvPlantsList(response.plantList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      pvPlantState.off();
    }
  };

  const loadAllUsers = async () => {
    flState.on();

    try {
      const response = await getAllUsers();
      setUserList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  //get all-clients
  const loadAllClients = async () => {
    clState.on();

    try {
      const response = await getAllClients();
      setClientList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      clState.off();
    }
  };

  //get all-dnos
  const loadAllDnos = async () => {
    clState.on();

    try {
      const response = await getAllDnos();
      setDnoList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      clState.off();
    }
  };

  //get all-dnos
  const loadAllHealthAndSafeties = async () => {
    clState.on();

    try {
      const response = await getAllHealthAndSafeties();
      setHealthAndSafetyList(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      clState.off();
    }
  };
  // VIEW PLANT
  const loadPvPlant = async (plantId) => {
    try {
      const response = await getPvPlantAPI(plantId);
      setCurrentViewPvPlant(response.plant);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  const loadPvPlantTask = async (plantId) => {
    try {
      const response = await getPvPlantTasksAPI(plantId);
      setPlantTasks(response);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  //UPDATE PLANT
  const updatePvPlant = async (plantId, updateParams) => {
    try {
      lswState.on();
      await updatePvPlantAPI(plantId, updateParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };
  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };
  const resetPvPlantListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  // CREATE PLANT
  const createPvPlant = async (createParams) => {
    try {
      lswState.on();

      await createPvPlantAPI(createParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };
  //DELETE PLANT
  const deletePvPlant = async (plantId) => {
    try {
      lswState.on();
      await deletePvPlantAPI(plantId);
      loadPvPLantList();
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  //ARCHIVE PLANT
  const archivePvPlant = async (plantId) => {
    try {
      lswState.on();
      await archivePvPlantAPI(plantId);
      loadPvPLantList();
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  //UNARCHIVE PLANT
  const unarchivePvPlant = async (plantId) => {
    try {
      lswState.on();
      await unarchivePvPlantAPI(plantId);
      loadPvPLantList();
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };


  const loadAttributeMasterList = async () => {
    attributeState.on();
    try {
      const filterParams = { entityType: "PLANT" };
      const response = await getAttributeMasterList(filterParams);
      setAttributeMasterList(response.attributeMastersList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      attributeState.off();
    }
  };

  //UPDATE PLANT_ATTRIBUTE
  const updateAttribute = async (updateParams) => {
    try {
      lswState.on();
      await updateAttributeAPI(updateParams);
    } catch (e) {
      throw e;
    } finally {
      lswState.off();
    }
  };

  const loadPlantAttributes = async (params) => {
    attributeState.on();
    try {
      const filterParams = { ...params, parentType: "PLANT" };
      const response = await getAttributes(filterParams);
      setAttributes(response.attributes);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      attributeState.off();
    }
  };
  const mContext = {
    pvPlantsList,
    checkIsFilterApplied,
    showFilter,
    sfState,
    isFilterApplied,
    pagination,
    loadPvPLantList,
    loadAllUsers,
    userList,
    createPvPlant,
    updatePvPlant,
    pvPlantLoading,
    filterParams,
    loadFilterOptions,
    filtersLoading,
    clientLoading,
    resetPvPlantListFilter,
    handleApplyFilter,
    filterOptions,
    loadingPvPlantView,
    deletePvPlant,
    unarchivePvPlant,
    loadAllClients,
    clientList,
    archivePvPlant,
    currentViewPvPlant,
    loadPvPlant,
    loadAllDnos,
    dnoList,
    loadAllHealthAndSafeties,
    healthAndSafetyList,
    correctiveTaskList,
    remedialTaskList,
    loadPvPlantTask,
    plantTasks,
    attributeMasterList,
    loadAttributeMasterList,
    attibuteLoading,
    updateAttribute,
    attributes,
    loadPlantAttributes,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };
  return <PvPlantManagementContext.Provider value={mContext}>{props.children}</PvPlantManagementContext.Provider>;
};
export const withPvPlantManagementProvider = (Container, containerProps) => (props) =>
  (
    <PvPlantManagementProvider>
      <Container {...containerProps} />
    </PvPlantManagementProvider>
  );
