/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  Sidebar,
  TooltipComponent,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilteredBy";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { EQUIPMENT_SPARE_FILTERS } from "../../../constants/PreferenceKeys";
import { EquipmentAndSpareContext } from "../../equipment_spare/EquipmentAndSpareProvider";
import { deleteEquipmentAPI } from "../../equipment_spare/EquipmentAndSpareRepository";
import EquipmentAndSpareFilter from "../../equipment_spare/components/EquipmentAndSpareFilter";
import ExportComponent from "../../equipment_spare/components/ExportComponent";
import TransactionForm from "../../equipment_spare/components/Transaction";
import { STATUSES } from "../../equipment_spare/constants";
import EquipmentAndSpareForm from "../../equipment_spare/containers/EquipmentAndSpareForm";
import EquipmentAndSpareViewContainer from "../../equipment_spare/containers/EquipmentAndSpareViewContainer";
import ModalViewer from "../../maintenance/ModalViewer";
import { PvPlantManagementContext } from "../PvPlantManagementProvider";

const EquipmentAndSpareList = (props) => {
  const { plantId } = props;

  const {
    equipmentList,
    isExportLoading,
    handleApplyFilter,
    filterParams,
    filterOptions,
    isLoading,
    pagination,
    updateShowListPage,
    deleteEquipment,
    isFilterApplied,
    sfState,
    loadEquipmentList,
    equipmentListLoading,
    showFilter,
    deleteData,
    handleDeleteData,
    updateShowSizePage,
  } = useContext(EquipmentAndSpareContext);
  const pvPlantManagementContext = useContext(PvPlantManagementContext);
  const { currentViewPvPlant } = pvPlantManagementContext;
  const { id } = currentViewPvPlant;
  const focusSearchRef = React.useRef();

  const [sm, updateSm] = useState(false);
  const [view, setView] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [onSearch, setOnSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [equipmentId, setEquipmentId] = useState("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const supplier = {};
  const category = {};
  const plant = {};
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
    MOVE: "MOVE",
  };
  const KEY_COMPONENT_OPTION = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const OWNED_BY_OPTIONS = [
    { label: "BGE Owned", value: "BGE" },
    { label: "Plant Owned", value: "PLANT" },
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    loadEquipmentList(id);
  }, [isOpen, deleteData]);

  for (let i = 0; i < filterOptions.assetCategory?.length; i++) {
    category[filterOptions.assetCategory[i].value] = filterOptions.assetCategory[i].label;
  }
  for (let i = 0; i < filterOptions.manufacturer?.length; i++) {
    supplier[filterOptions.manufacturer[i].value] = filterOptions.manufacturer[i].label;
  }
  for (let i = 0; i < filterOptions.plant?.length; i++) {
    plant[filterOptions.plant[i].value] = filterOptions.plant[i].label;
  }
  const handleNameSort = (sortOrder, field, table) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field, table: table });
  };

  const createInventory = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };
  const viewInventory = (taskId) => {
    setEquipmentId(taskId);
    setView(VIEW.VIEW);
    toggleModal();
  };

  const editInventory = (taskId) => {
    setEquipmentId(taskId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const toggle = () => setOnSearch(!onSearch);
  useEffect(() => {
    const filters = localStorage.getItem(EQUIPMENT_SPARE_FILTERS);
    if (filters) handleApplyFilter(JSON.parse(filters));
  }, []);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
  };

  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDeleteInventory = async (inventoryId) => {
    await deleteEquipmentAPI(inventoryId)
      .then((res) => {
        successToast({ description: "Equipment is deleted successfully" });
        handleDeleteData(!deleteData);
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting equipment" });
      });
  };

  const handleDeleteConfirmation = async (inventoryId) => {
    confirmationSweetAlert({
      id: inventoryId,
      handleConfirmation: handleDeleteInventory,
    });
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <EquipmentAndSpareViewContainer
          mode={view}
          setMode={setView}
          onSuccessfulModal={toggleModal}
          equipmentId={equipmentId}
        />
      );
    } else if (view === VIEW.CREATE) {
      return (
        <EquipmentAndSpareForm module={"plantView"} mode={view} setMode={setView} onSuccessfulModal={toggleModal} />
      );
    } else if (view === VIEW.EDIT) {
      return (
        <EquipmentAndSpareForm
          module={"plantView"}
          mode={view}
          setMode={setView}
          onSuccessfulModal={toggleModal}
          equipmentId={equipmentId}
        />
      );
    } else if (view === VIEW.MOVE) {
      return <TransactionForm onSuccessfulModal={toggleModal} />;
    }
  };

  const getExportComponent = () => {
    return <ExportComponent plantId={[plantId]} setIsExportModalOpen={setIsExportModalOpen} />;
  };
  const manufacturerSelectedFilter = filterParams.supplier.map((item, key) => {
    return filterOptions.manufacturer?.[filterOptions.manufacturer?.findIndex((data) => data.value === item)];
  });

  const assetCategoryFilter = filterParams.category.map((item, key) => {
    return filterOptions.assetCategory?.[filterOptions.assetCategory?.findIndex((data) => data.value === item)];
  });
  const plantFilter = filterParams.plant.map((item, key) => {
    return filterOptions.plant?.[filterOptions.plant?.findIndex((data) => data.value === item)];
  });
  const selectedStatusFilter = STATUSES.find((data) => data.value === filterParams?.status);
  const keyoptionFilter = KEY_COMPONENT_OPTION.find((data) => data.value === filterParams?.isKeyComponent);

  const selectedOwnerShipFilter = OWNED_BY_OPTIONS.find((data) => data.value === filterParams?.ownership);

  return (
    <React.Fragment>
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h3" page>
              Equipment And Spares
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {equipmentList?.pagination?.totalCount} Equipment and Spares.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {filterParams.status ? (
                <FilteredByList data={selectedStatusFilter.label} title={"Status"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.isKeyComponent ? (
                <FilteredByList data={keyoptionFilter.label} title={"Key Component"} isNotMap={true} isStr={true} />
              ) : null}
              {filterParams.ownership ? (
                <FilteredByList data={selectedOwnerShipFilter.label} title={"Owned By"} isNotMap={true} isStr={true} />
              ) : null}
              <FilteredByList data={manufacturerSelectedFilter} title={"Supplier"} isMap={true} isStr={false} />
              <FilteredByList data={plantFilter} title={"Plant"} isMap={true} isStr={false} />
              <FilteredByList data={assetCategoryFilter} title={"Category"} isMap={true} isStr={false} />
              <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <Check permission={["getInventory", "manageInventory"]}>
                    <li>
                      <a
                        className={`btn btn-white btn-outline-light ${
                          equipmentList?.pagination?.totalCount === 0 ? "disabled" : ""
                        }`}
                        type="export"
                        isLoading={isExportLoading}
                        onClick={() => {
                          setIsExportModalOpen(true);
                        }}
                      >
                        <Icon name="download-cloud"> </Icon>
                        <span>Export</span>
                      </a>
                    </li>
                  </Check>
                  <Check permission={["manageInventory"]}>
                    {currentViewPvPlant?.status === "ACTIVE" && currentViewPvPlant?.isArchived === false && (
                      <li className="nk-block-tools-opt">
                        <Button color="primary" className="btn-icon" onClick={createInventory}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    )}
                  </Check>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group" style={{ justifyContent: "end" }}>
              <div className="card-tools mr-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <div id="filter-plantstring" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      handleApplyFilter({ ...filterParams, searchText: "" });
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                    }}
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Equipment name, Asset Category and Plant name"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <DataTableBody compact>
            <DataTableHead>
              <DataTableRow style={{ display: "flex" }}>
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    id="spareparts-sort"
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "model" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "model", "assets")
                        : handleNameSort("ASC", "model", "assets");
                    }}
                  >
                    Equipment Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      id="spareparts-asc"
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "model", "assets");
                      }}
                      name={`${
                        filterParams.sortingField === "model" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      id="spareparts-desc"
                      onClick={() => {
                        handleNameSort("DESC", "model", "assets");
                      }}
                      name={`${
                        filterParams.sortingField === "model" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "name", "plants")
                        : handleNameSort("ASC", "name", "plants");
                    }}
                  >
                    Plant Name
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "name", "plants");
                      }}
                      name={`${
                        filterParams.sortingField === "name" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "name", "plants");
                      }}
                      name={`${
                        filterParams.sortingField === "name" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="sm">
                <div className="d-flex " style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingField === "manufacturer_id" && filterParams.sortingOrder === "ASC"
                        ? handleNameSort("DESC", "manufacturer_id", "assets")
                        : handleNameSort("ASC", "manufacturer_id", "assets");
                    }}
                  >
                    Supplier
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "manufacturer_id", "assets");
                      }}
                      name={`${
                        filterParams.sortingField === "manufacturer_id" && filterParams.sortingOrder === "ASC"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "manufacturer_id", "assets");
                      }}
                      name={`${
                        filterParams.sortingField === "manufacturer_id" && filterParams.sortingOrder === "DESC"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="md">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                        ? handleNameSort("DESC", "asset_category_id", "assets")
                        : handleNameSort("ASC", "asset_category_id", "assets");
                    }}
                  >
                    Category
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "asset_category_id", "assets");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "asset_category_id"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "asset_category_id", "assets");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "asset_category_id"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="d-flex" style={{ cursor: "pointer" }}>
                  <span
                    className="sub-text pr-1"
                    onClick={() => {
                      filterParams.sortingOrder === "ASC" && filterParams.sortingField === "quantity"
                        ? handleNameSort("DESC", "quantity")
                        : handleNameSort("ASC", "quantity");
                    }}
                  >
                    Total Spares
                  </span>
                  <div className="d-flex flex-column justify-content-center">
                    <Icon
                      style={{ margin: "0px 0px -3px 0px" }}
                      onClick={() => {
                        handleNameSort("ASC", "quantity");
                      }}
                      name={`${
                        filterParams.sortingOrder === "ASC" && filterParams.sortingField === "quantity"
                          ? "caret-up-fill "
                          : "caret-up"
                      } `}
                    ></Icon>

                    <Icon
                      onClick={() => {
                        handleNameSort("DESC", "quantity");
                      }}
                      name={`${
                        filterParams.sortingOrder === "DESC" && filterParams.sortingField === "quantity"
                          ? "caret-down-fill"
                          : "caret-down"
                      }`}
                    ></Icon>
                  </div>
                </div>
              </DataTableRow>
              <DataTableRow size="lg">
                <div className="sub-text" style={{ cursor: "pointer" }}>
                  <span>Contractual Quantity</span>
                </div>
              </DataTableRow>
              <DataTableRow>
                {
                  <span className="sub-text" style={{ textAlign: "end" }}>
                    Actions
                  </span>
                }
              </DataTableRow>
            </DataTableHead>
            <Sidebar toggleState={showFilter}>
              <EquipmentAndSpareFilter isPlantName={true} subList={true} />
            </Sidebar>
            {/*Head*/}
            {isLoading ? (
              <></>
            ) : (
              equipmentList?.items.map((item) => {
                return (
                  <DataTableItem key={item.id}>
                    <DataTableRow>
                      <div
                        className="user-card"
                        role="button"
                        tabIndex="0"
                        onClick={() => {
                          viewInventory(item.id);
                        }}
                      >
                        <div className="user-name">
                          <span className="tb-lead">{item.assets.model}</span>
                        </div>
                      </div>
                    </DataTableRow>
                    <DataTableRow size={"md"}>
                      <span>{item.plantId ? plant[item.plantId] : "-"}</span>
                    </DataTableRow>
                    <DataTableRow size="sm">
                      <span>{supplier[item.assets.manufacturerId]}</span>
                    </DataTableRow>
                    <DataTableRow size="md">
                      <span>{category[item.assets.assetCategoryId]}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span>{item.quantity}</span>
                    </DataTableRow>
                    <DataTableRow size="lg">
                      <span style={{ color: item.quantity < item.contractualQuantity ? "#FF9999" : "" }}>
                        {/* {item.contractualQuantity ? (
                            (item.quantity / item.contractualQuantity) * 100 >= 100 ? (
                              <>
                                {((item.quantity / item.contractualQuantity) * 100).toFixed()}
                                %<Icon name="check-circle" className={"text-success pl-1 fs-16px"} />
                              </>
                            ) : (
                              <>
                                {((item.quantity / item.contractualQuantity) * 100).toFixed()}
                                %<Icon name="alert-circle" className={"text-danger pl-1 fs-16px"} />
                              </>
                            )
                          ) : (
                            <>
                              100%
                              <Icon name="alert-circle" className={"text-danger pl-1 fs-16px"} />
                            </>
                          )} */}
                        {item.contractualQuantity}
                      </span>
                    </DataTableRow>

                    <Check permission={["manageInventory"]}>
                      <DataTableRow className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                          <li className="nk-tb-action-hidden" onClick={() => editInventory(item.id)}>
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-trigger btn-icon"
                              id={"edit" + item.id}
                              icon="edit-alt-fill"
                              direction="top"
                              text="Edit"
                            />
                          </li>

                          {item.status !== "SUSPENDED" && (
                            <li className="nk-tb-action-hidden" onClick={() => handleDeleteConfirmation(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"delete-" + item.id}
                                icon="na"
                                direction="top"
                                text="Delete"
                              />
                            </li>
                          )}
                        </ul>
                      </DataTableRow>
                    </Check>
                  </DataTableItem>
                );
              })
            )}
          </DataTableBody>
          {isLoading && <Skeleton count={10} className="w-100" />}

          <div className="card-inner">
            {equipmentList?.pagination && equipmentList?.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination?.size}
                totalItems={equipmentList?.pagination?.totalCount}
                paginate={updateShowListPage}
                currentPage={equipmentList?.pagination?.currentPage}
                paginatePage={updateShowSizePage}
              />
            ) : (
              !isLoading && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
        {equipmentListLoading && <Skeleton count={10} className="w-100" />}
      </Block>
      <ModalViewer
        size={"xl"}
        title="Equipment And Spares"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Equipment And Spares - Select the Export Fields"
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={() => {
          setIsExportModalOpen(!isExportModalOpen);
        }}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default EquipmentAndSpareList;
