import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup";
import { Block, Button, Icon, PreviewCard, RSelect } from "../../../../components/Component";
import ActivityLog from "../../../../components/activity_log/components/ActivityLog";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Head from "../../../../layout/head/Head";
import { ActivityLogContext } from "../../../../providers/ActivtiyLogProvider";
import { sortOption } from "../../../../utils/Utils";
import { MODE } from "../../../maintenance/preventative/constants";
import { PreventativeContext } from "../PreventativeProvider";
import {
  createPreventativeMaster,
  deletePreventativeMaster,
  getPreventativeMaster,
  updatePreventativeMaster,
} from "../PreventativeRepository";
import { FREQUENCY_DROPDOWN } from "../constants";

const PreventiveMaintenanceForm = (props) => {
  const preventativeContext = useContext(PreventativeContext);
  const { loadPreventativeMasters, assetCategories, preventativeMasterListId } = preventativeContext;
  const [currentTask, setCurrentTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const { loadActivityLogList } = useContext(ActivityLogContext);

  const createSchema = yup.object({
    title: yup.string().required(),
    code: yup.string().required().max(10),
    frequency: yup.string().trim().required(),
    description: yup.string().trim().notRequired().nullable(),
    assetCategoryId: yup.number().required(),
  });

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const taskFormRef = useForm({
    resolver: yupResolver(createSchema),
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    clearErrors,
  } = taskFormRef;

  const loadActivity = () => {
    const filter = { entityId: props.taskId, entityType: "TASK" };
    loadActivityLogList(filter);
  };
  useEffect(() => {
    if (props.taskId) {
      loadTask(props.taskId);
      loadActivity();
    }
  }, [props.taskId]);

  const loadTask = async (taskId) => {
    try {
      const response = await getPreventativeMaster(taskId);
      setCurrentTask(response.task);
      reset({ ...response.task });
    } catch (e) {
      // CAPTURE EXCEPTION
    }
  };
  const handleFormSubmit = async (formData) => {
    if (props.mode === MODE.CREATE) {
      try {
        setIsLoading(true);
        await createPreventativeMaster({ ...formData, isArchived, preventativeMasterListId });
        props.onSuccessfulModal(preventativeMasterListId);
        successToast({ description: "Successfully Created" });
        setIsLoading(false);
      } catch (err) {
        errorToast({ description: "Error happened while creating Preventative Master maintenance" });
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        await updatePreventativeMaster(props.taskId, { ...formData, isArchived });
        loadPreventativeMasters(preventativeMasterListId);
        loadActivity();
        successToast({ description: "Successfully Updated" });
        setIsLoading(false);
        props.setMode(MODE.VIEW);
      } catch (err) {
        errorToast({ description: "Error happened while updating Preventative Master maintenance" });
        setIsLoading(false);
      }
    }
  };
  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeMaster(taskId);
      // props.onSuccessfulModal()
      loadPreventativeMasters(preventativeMasterListId);
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Master maintenance" });
    }
  };
  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };
  const handleIsArchivedCheckBox = (value) => {
    setCurrentTask({ ...currentTask, isArchived: value.target.checked });
    setIsArchived(value.target.checked);
  };

  return (
    <React.Fragment>
      <div className="overflow-auto h-max-450px">
        <Head
          title={
            props.mode === MODE.CREATE
              ? "Brighter App |  Task | Create"
              : props.mode === MODE.EDIT
              ? "Brighter App |  Task | Edit"
              : "Brighter App |  Task | View"
          }
        />

        <Block size="lg">
          <PreviewCard>
            <Check permission={["manageEntity"]}>
              {props.mode === MODE.VIEW ? (
                <Row className="d-flex justify-content-end mb-2">
                  <Col className="d-flex justify-content-end">
                    <Button color="danger" size="md" onClick={() => handleDeleteConfirmation(currentTask.id)}>
                      <Icon name="na" />
                      <span>Delete</span>
                    </Button>
                    <Button className="ml-2" color="primary" size="md" onClick={() => props.setMode(MODE.EDIT)}>
                      <Icon name="edit" />
                      <span>Edit</span>
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Check>
            <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
              <Row className="mt-3">
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Title of the Task
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        {...register("title")}
                        type="text"
                        name="title"
                        className="form-control"
                        disabled={props.mode === MODE.VIEW}
                      />
                    </div>
                    {errors.title && (
                      <span className="invalid" style={{ color: "red" }}>
                        Title is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" className="mt-3 mt-md-0">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Code
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        {...register("code")}
                        type="text"
                        name="code"
                        className="form-control"
                        disabled={props.mode === MODE.VIEW}
                      />
                    </div>
                    {errors.code && (
                      <span className="invalid" style={{ color: "red" }}>
                        Code is required and must be equal or below 10 digits
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Frequency
                    </Label>
                    <Controller
                      control={control}
                      name="frequency"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = FREQUENCY_DROPDOWN;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => {
                              setValue("frequency", o.value);
                              clearErrors("frequency");
                            }}
                            isDisabled={props.mode === MODE.VIEW}
                          />
                        );
                      }}
                    />
                    {errors?.frequency && (
                      <span className="invalid" style={{ color: "red" }}>
                        Frequency is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6" className="mt-3 mt-md-0">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Asset Category
                    </Label>
                    <Controller
                      control={control}
                      name="assetCategoryId"
                      // rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = assetCategories;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={sortOption(options)}
                            onChange={(o) => {
                              setValue("assetCategoryId", o.value);
                              clearErrors("assetCategoryId");
                            }}
                            isDisabled={props.mode === MODE.VIEW}
                          />
                        );
                      }}
                    />
                    {errors?.assetCategoryId && (
                      <span className="invalid" style={{ color: "red" }}>
                        Asset Category is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      Description
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        {...register("description")}
                        name="description"
                        className="form-control form-control-sm"
                        disabled={props.mode === MODE.VIEW}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col size="6">
                  <div className="custom-control custom-control-sm custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      id="isArchived-form"
                      checked={currentTask?.isArchived}
                      onChange={(e) => {
                        handleIsArchivedCheckBox(e);
                      }}
                      disabled={props?.mode === MODE.VIEW}
                    />
                    <label className="custom-control-label" htmlFor="isArchived-form">
                      Archived
                    </label>
                  </div>
                </Col>
              </Row>
              {props.mode !== MODE.VIEW ? (
                <Row className="d-flex justify-content-end mt-4">
                  <Col className="d-flex justify-content-end">
                    {props.mode === MODE.EDIT ? (
                      <Button
                        color="danger"
                        size="md"
                        onClick={() => {
                          props.setIsOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    ) : null}
                    <Button
                      className="ml-3"
                      color="primary"
                      size="md"
                      onClick={handleSubmit(handleFormSubmit)}
                      isLoading={isLoading}
                    >
                      {props.mode === MODE.CREATE ? "Create" : "Save"}
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Form>
            {props.taskId ? <ActivityLog id={props.taskId} entityType="TASK" /> : null}
          </PreviewCard>
        </Block>
      </div>
    </React.Fragment>
  );
};
export default PreventiveMaintenanceForm;
