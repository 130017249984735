/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import ViewPvPlant from "../components/PvPlantView";
import { PvPlantManagementContext, withPvPlantManagementProvider } from "../PvPlantManagementProvider";
import { CorrectiveContext } from "../../maintenance/corrective/CorrectiveProvider";
import { RemedialContext } from "../../maintenance/remedial/RemedialProvider";
import { PreventativeTaskContext } from "../../maintenance/preventative/preventativeTasks/PreventativeTaskProvider";
import { ActivityLogContext } from "../../../providers/ActivtiyLogProvider";
import { StringContext } from "../../maintenance/string/StringProvider";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";

const PvPlantManagementViewContainer = (props) => {
  const location = useLocation().search;
  const status = new URLSearchParams(location).get("status");
  const tab = new URLSearchParams(location).get("tab");

  const params = useParams();
  const { plantId } = params;

  const { loadPvPlant, loadAllUsers, loadAllDnos, loadAllHealthAndSafeties, loadPvPlantTask, loadAttributeMasterList } =
    useContext(PvPlantManagementContext);
  const { loadActivityLogList } = useContext(ActivityLogContext);
  const [correctiveStatus, setCorrectiveStatus] = useState("");
  const [remedialStatus, setRemedialStatus] = useState("");
  const [preventativeStatus, setPreventativeStatus] = useState("");
  // const [stringStatus, setStringStatus] = useState("");

  useEffect(() => {
    if (status) {
      if (tab === "corrective") {
        setCorrectiveStatus(status);
      }
      if (tab === "remedial") {
        setRemedialStatus(status);
      }
      if (tab === "preventative") {
        setPreventativeStatus(status);
      }
      if (tab === "string") {
        setStringStatus(status);
      }
    }
  }, [status]);
  const {
    loadCorrectiveList,
    pagination: correctivePagination,
    filterParams: correctiveFilterParams,
  } = useContext(CorrectiveContext);

  const { loadRemedialList } = useContext(RemedialContext);
  const { pagination: remedialPagination, filterParams: remedialFilterParams } = useContext(RemedialContext);

  const { loadFilterOptions } = useContext(MaintenanceContext);

  const { loadOptions: loadPreventativeOptions, loadPreventativeTasks } = useContext(PreventativeTaskContext);
  const { pagination: preventativePagination, filterParams: preventativeFilterParams } =
    useContext(PreventativeTaskContext);

  const filter = { entityId: plantId, entityType: "PLANT" };

  useEffect(() => {
    loadActivityLogList(filter);
    if (plantId) {
      loadPvPlant(plantId);
      loadAllUsers();
      loadAllDnos();
      loadAllHealthAndSafeties();
      loadFilterOptions();
      loadPreventativeOptions();
      loadPvPlantTask(plantId);
    }
  }, [plantId]);

  const { loadStringList, loadOptions } = useContext(StringContext);
  const {
    pagination: stringPagination,
    filterParams: stringFilter,
    setStringStatus,
    stringStatus,
  } = useContext(StringContext);

  useEffect(() => {
    if (plantId) {
      loadStringList(plantId, stringStatus);
    }
    if (plantId && !stringStatus) {
      loadStringList(plantId);
    }
  }, [stringPagination, stringFilter, stringStatus]);

  useEffect(() => {
    loadOptions();
  }, []);

  useEffect(() => {
    if (plantId) {
      loadCorrectiveList(plantId);
    }
  }, [plantId, correctivePagination, correctiveFilterParams, correctiveStatus]);

  useEffect(() => {
    if (plantId) {
      loadRemedialList(plantId);
    }
  }, [plantId, remedialPagination, remedialFilterParams, remedialStatus]);

  useEffect(() => {
    if (plantId) {
      loadPreventativeTasks(plantId);
    }
  }, [plantId, preventativePagination, preventativeFilterParams, preventativeStatus]);

  return (
    <React.Fragment>
      <Head title={`${plantId} - PV-Plant Management`} />
      <Content>
        <div style={{overflow:"auto"}}>
          <ViewPvPlant />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantManagementProvider(PvPlantManagementViewContainer);
