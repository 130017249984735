import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormGroup, Modal, ModalBody, Spinner } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  Row,
} from "../../../components/Component";
import Attachments from "../../../components/attachments/Attachment";
import Check from "../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { CURRENT_USER, USER } from "../../../constants/PreferenceKeys";
import { USER_MANAGEMENT_EDIT_PAGE, USER_MANAGEMENT_PAGE } from "../../../constants/routes";
import Head from "../../../layout/head/Head";
import { UserManagementContext } from "../UserManagementProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePasswordSchema } from "../validation";

const ViewUser = () => {
  const history = useHistory();

  const [modalTab, setModalTab] = useState("1");
  const [newPassword, setNewPassword] = useState({});
  const [passwordState, setPasswordState] = useState(false);
  const [confirmPasswordState, setConfirmPasswordState] = useState(false);

  const [modal, setModal] = useState(false);

  const userManagementContext = useContext(UserManagementContext);
  const { currentViewUser, deleteUser, usersList, inlineLoading } = userManagementContext;
  useEffect(() => {
    if (!modal) resetChangePassword({ newPassword: "", confirmPassword: "" });
  }, [modal]);

  const updatePasswordFormRef = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });

  const {
    reset: resetChangePassword,
    formState: { errors: updatePasswordError },
    register: updatePasswordRegister,
    handleSubmit: handleUpdatePassword,
  } = updatePasswordFormRef;
  if (!currentViewUser) return <Spinner />;

  const handlePasswordSubmit = (formData) => {
    const password = {
      password: formData?.newPassword,
    };
    userManagementContext
      .userPassword(currentViewUser.id, password)
      .then((res) => {
        setModal(false);
        successToast({ description: "Password Updated Successfully " });
      })
      .catch((e) => {
        errorToast({ description: `${e}` });
      });
  };

  const handleDeleteUser = async (userId) => {
    deleteUser(userId)
      .then((res) => {
        successToast({ description: "User is deleted successfully" });
        history.replace(USER_MANAGEMENT_PAGE);
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting User" });
      });
  };
  const handleDeleteConfirmationUser = async (userId) => {
    confirmationSweetAlert({
      id: userId,
      handleConfirmation: handleDeleteUser,
    });
  };

  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));

  return (
    <>
      <BlockHeadContent className="mb-1">
        <Head title="Brighter App | User | View" />

        <Link to={USER_MANAGEMENT_PAGE}>
          <Button color="light" outline className="bg-white d-none d-sm-inline-flex">
            <Icon name="arrow-left"></Icon>
            <span>Back</span>
          </Button>
        </Link>
      </BlockHeadContent>
      <Block className={"bg-white p-4"}>
        <BlockHead size="md">
          <div className="d-block d-md-flex justify-content-between">
            <BlockHeadContent>
              <BlockTitle tag="h4">Personal Information</BlockTitle>
              <BlockDes>
                <p>Basic info, like your name and address, that you use on this Platform.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent className="align-self-end">
              <Check permission={["updateUser"]}>
                <Button color="primary" className="mr-2 my-1" onClick={() => setModal(true)}>
                  <Icon name="edit" />
                  <span>Change Password</span>
                </Button>
              </Check>
              <Check permission={["deleteUser"]}>
                {currentViewUser.id === currentUser.id ? null : (
                  <Button
                    color="danger"
                    className={"mr-2 my-1"}
                    onClick={() => {
                      handleDeleteConfirmationUser(currentViewUser.id);
                    }}
                  >
                    <Icon name="na" />
                    <span>Delete</span>
                  </Button>
                )}
              </Check>
              <Link to={USER_MANAGEMENT_EDIT_PAGE.replace(":userId", currentViewUser.id)}>
                <Check permission={["updateUser"]}>
                  <Button color="primary" className="my-1">
                    <Icon name="edit" />
                    <span>Edit</span>
                  </Button>
                </Check>
              </Link>
              {/* <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="edit"></Icon>
              <span>EDIT</span>
            </Button> */}
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block size="lg">
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Full Name</span>
                <span className="profile-ud-value">
                  {currentViewUser.firstName + " " + currentViewUser.lastName || "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Phone Number</span>
                <span className="profile-ud-value">{currentViewUser.phoneNumber || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Email</span>
                <span className="profile-ud-value">{currentViewUser.email || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Mobile Number</span>
                <span className="profile-ud-value">{currentViewUser.mobileNumber || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"> Status</span>
                <span className="profile-ud-value">{currentViewUser.status || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"> Category</span>
                <span className="profile-ud-value">{currentViewUser.category || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"> Last Active At</span>
                <span className="profile-ud-value">
                  {currentViewUser.lastActiveAt !== null
                    ? format(new Date(currentViewUser?.lastActiveAt || null), "dd/MM/yyyy hh:mm:ss aaa")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"> CreatedAt</span>
                <span className="profile-ud-value">
                  {currentViewUser.created_at
                    ? format(new Date(currentViewUser?.created_at || null), "dd/MM/yyyy HH:mm")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label"> UpdatedAt</span>
                <span className="profile-ud-value">
                  {currentViewUser.updated_at
                    ? format(new Date(currentViewUser?.updated_at || null), "dd/MM/yyyy HH:mm")
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </Block>

        <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
          <ModalBody>
            <a
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                setModal(false);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title mb-4">Change Password</h5>

              <div className="tab-content">
                <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                  <Row className="gy-4">
                    <Col md="6">
                      <FormGroup>
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="new-password">
                            New Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="#new-password"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setPasswordState(!passwordState);
                            }}
                            className={`form-icon lg form-icon-right passcode-switch ${
                              passwordState ? "is-hidden" : "is-shown"
                            }`}
                          >
                            <Icon name="eye" className="passcode-icon icon-show"></Icon>

                            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                          </a>
                          <input
                            {...updatePasswordRegister("newPassword", { required: true, validate: true })}
                            type={passwordState ? "text" : "password"}
                            id="new-password"
                            autocomplete="new-password"
                            placeholder="New password"
                            className="form-control"
                          />
                        </div>
                        {updatePasswordError.newPassword?.message && (
                          <span style={{ color: "red" }} className="invalid">
                            {updatePasswordError.newPassword?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="confirm-password">
                            Confirm Password
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <a
                            href="#confirm-password"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setConfirmPasswordState(!confirmPasswordState);
                            }}
                            className={`form-icon lg form-icon-right passcode-switch ${
                              confirmPasswordState ? "is-hidden" : "is-shown"
                            }`}
                          >
                            <Icon name="eye" className="passcode-icon icon-show"></Icon>

                            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                          </a>
                          <input
                            {...updatePasswordRegister("confirmPassword", { required: true, validate: true })}
                            type={confirmPasswordState ? "text" : "password"}
                            id="confirm-password"
                            required
                            placeholder="confirm password"
                            className="form-control"
                          />
                        </div>
                        {updatePasswordError.confirmPassword?.message && (
                          <span style={{ color: "red" }} className="invalid">
                            {updatePasswordError.confirmPassword?.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            isLoading={inlineLoading}
                            color="primary"
                            size="lg"
                            onClick={handleUpdatePassword(handlePasswordSubmit)}
                          >
                            Reset Password
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal(false);
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Block>
      <Block className={"bg-white mt-3"}>
        {
          <Col lg="12" className=" px-4">
            <FormGroup className="form-group">
              <div className="form-control-wrap">
                <Attachments
                  module={USER}
                  id={currentViewUser.id}
                  attachments={currentViewUser.attachment}
                  subModule={"view"}
                />
              </div>
            </FormGroup>
          </Col>
        }
      </Block>
    </>
  );
};
export default ViewUser;
