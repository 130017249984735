/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, Icon } from "../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import Head from "../../../layout/head/Head";
import { successToast } from "../../../components/toastify/Toastify";

const ClientUserForm = (props) => {
  const [passState, setPassState] = useState(false);

  const userSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(8, "Password must be 8 characters long").default(""),
  });

  const userUpdateSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
  });

  const userFormRef = useForm({
    resolver: yupResolver(props.userId !== "" ? userSchema : userUpdateSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = userFormRef;

  useEffect(() => {
    if (props.userId !== "") {
      reset({ ...props.users[props.userId] });
    }
  }, [props.userId]);

  useEffect(() => {
    if (props.isUserEditMode && props.clientUserId) {
      const usersData = props.clientUserData.filter((user) => user.userId === props.clientUserId);
      reset({ ...usersData[0].clientUser });
    }
  }, [props.clientUserId]);

  const handleFormSubmit = async (formData) => {
    if (formData.email) {
      formData.email = formData.email.toLowerCase()
    }
    if (props.userId !== "") {
      props.users[props.userId] = formData;
      props.setUsers([...props.users]);
      successToast({ description: "Successfully Updated" });
      props.toggleModal();
    } else if (props.clientUserId) {
      const editedUsersData = props.clientUsers.findIndex((user) => user.userId === props.clientUserId);
      props.clientUserData.splice(editedUsersData,1);
      props.users.push(formData);
      props.setUsers([...props.users]);
      props.setClientUserId("");
      props.toggleModal();
    } else {
      props.users.push(formData);
      props.setUsers([...props.users]);
      successToast({ description: "Successfully Created" });
      props.toggleModal();
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderSaveButton = () => {
    return (
      <Button id="client-add-user" color="primary" size="lg" onClick={handleSubmit(handleFormSubmit)}>
        {props.userId !== "" || props.clientUserId ? "Update User" : "Add User"}
      </Button>
    );
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter App | User | Create" />
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row className="g-4">
              <Col lg="6" md="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="firstName">
                    First Name
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      className="form-control"
                      {...register("firstName")}
                    />
                  </div>
                  {errors.firstName && <span className="invalid text-danger">{errors.firstName?.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="form-group">
                  <Label className="form-label" htmlFor="lastName">
                    Last Name
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      className="form-control"
                      {...register("lastName")}
                    />
                  </div>
                  {errors.lastName && <span className="invalid text-danger">{errors.lastName?.message}</span>}
                </FormGroup>
              </Col>
              <Col lg="6" md="6">
                <FormGroup className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <div className="form-control-wrap">
                    <input
                      id="client-email-input"
                      {...register("email")}
                      type="email"
                      name="email"
                      className="form-control"
                      autoComplete="new-password"
                    />
                  </div>
                  {errors.email && <span className="invalid text-danger">{errors.email?.message}</span>}
                </FormGroup>
              </Col>
              {!props.isUserEditMode && (
                <Col lg="6" md="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Password</label>
                    <div className="form-control-wrap">
                      <a
                        id="client-pass-hidden"
                        href="#password"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPassState(!passState);
                        }}
                        className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"
                          }`}
                      >
                        <Icon name="eye" className="passcode-icon icon-show"></Icon>
                        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                      </a>
                      <input
                        id="client-password-input"
                        type={passState ? "text" : "password"}
                        className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                        {...register("password")}
                        autoComplete="new-password"
                      />
                    </div>
                    {errors.password && <span className="invalid text-danger">{errors.password?.message}</span>}
                  </FormGroup>
                </Col>
              )}

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ClientUserForm;
