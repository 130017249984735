export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ADMIN_LOGIN = `${BASE_URL}/api/sign-in`;
export const USER_LOOKUP = `${BASE_URL}/api/users/lookup`;

export const ADMIN_FORGOT_PASSWORD = `${BASE_URL}/api/forgot-password`;

//RESET_PASSWORD
export const ADMIN_RESET_PASSWORD = `${BASE_URL}/api/reset-password/:resetPasswordToken`;

//FORM
export const FORM_DATA = `${BASE_URL}/api/forms/:formId/:formToken`;
export const FORM_SUBMISSION_CREATE = `${BASE_URL}/api/formsubmitted/create`;

//GLOBAL_SEARCH
export const ADMIN_GLOBAL_SEARCH = `${BASE_URL}/api/admin/global-search`;

// USER_MANAGEMENT
export const USER_MANAGEMENT_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/users/filter-options`;
export const USER_MANAGEMENT_LIST_API = `${BASE_URL}/api/admin/users`;
export const USER_MANAGEMENT_CREATE_API = `${BASE_URL}/api/admin/users/create`;
export const USER_MANAGEMENT_VIEW_API = `${BASE_URL}/api/admin/users/:userId`;
export const USER_MANAGEMENT_UPDATE_API = `${BASE_URL}/api/admin/users/:userId/update`;
export const USER_MANAGEMENT_ARCHIVE_API = `${BASE_URL}/api/admin/users/:userId/archive`;
export const USER_MANAGEMENT_UNARCHIVE_API = `${BASE_URL}/api/admin/users/:userId/unarchive`;
export const USER_MANAGEMENT_UPDATE_STATUS_API = `${BASE_URL}/api/admin/users/:userId/status`;
export const USER_MANAGEMENT_DELETE_API = `${BASE_URL}/api/admin/users/:userId/delete`;
export const USER_MANAGEMENT_ALL_USERS_API = `${BASE_URL}/api/admin/all-users`;
export const USER_MANAGEMENT_PASSWORD_API = `${BASE_URL}/api/admin/users/:userId/password`;
export const USER_MANAGEMENT_USER_ROLES_API = `${BASE_URL}/api/admin/userRoles`;
export const EXPORT_USER_API = `${BASE_URL}/api/admin/user/export`;

//CLIENT MANAGEMENT
export const CLIENT_MANAGEMENT_LIST_API = `${BASE_URL}/api/admin/clients`;
export const CLIENT_MANAGEMENT_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/clients/filter-options`;
export const CLIENT_MANAGEMENT_CREATE_API = `${BASE_URL}/api/admin/clients/create`;
export const CLIENT_MANAGEMENT_VIEW_API = `${BASE_URL}/api/admin/clients/:clientId`;
export const CLIENT_MANAGEMENT_UPDATE_API = `${BASE_URL}/api/admin/clients/:clientId/update`;
export const CLIENT_MANAGEMENT_ARCHIVE_API = `${BASE_URL}/api/admin/clients/:clientId/archive`;
export const CLIENT_MANAGEMENT_UNARCHIVE_API = `${BASE_URL}/api/admin/clients/:clientId/unarchive`;
export const CLIENT_MANAGEMENT_DELETE_API = `${BASE_URL}/api/admin/clients/:clientId/delete`;
export const CLIENT_MANAGEMENT_ALL_CLIENTS_API = `${BASE_URL}/api/admin/all-clients`;
export const CLIENT_MANAGEMENT_ALL_CLIENT_USERS_API = `${BASE_URL}/api/admin/all-client-users`;
export const EXPORT_CLIENT_API = `${BASE_URL}/api/admin/client/export`;

//CORRECTIVE
export const CORRECTIVE_LIST_API = `${BASE_URL}/api/admin/corrective`;
export const CORRECTIVE_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/corrective/filter-options`;
export const CORRECTIVE_LIST_VIEW_API = `${BASE_URL}/api/admin/corrective/:taskId`;
export const CORRECTIVE_CREATE = `${BASE_URL}/api/admin/corrective/create`;
export const CORRECTIVE_UPDATE = `${BASE_URL}/api/admin/corrective/:taskId/update`;
export const CORRECTIVE_DELETE = `${BASE_URL}/api/admin/corrective/:taskId/delete`;
export const CORRECTIVE_ALL_TASKS_API = `${BASE_URL}/api/admin/task/correctivetasks`;

//CORRECTIVE RELATED - FOLLOW_UP TASKS
export const CORRECTIVE_SUBTASK_LIST_API = `${BASE_URL}/api/admin/corrective/sub-task`;
export const CORRECTIVE_SUBTASK_LIST_VIEW_API = `${BASE_URL}/api/admin/corrective/sub-task/:taskId`;
export const CORRECTIVE_SUBTASK_CREATE = `${BASE_URL}/api/admin/corrective/sub-task/create`;
export const CORRECTIVE_SUBTASK_UPDATE = `${BASE_URL}/api/admin/corrective/sub-task/:taskId/update`;
export const CORRECTIVE_SUBTASK_DELETE = `${BASE_URL}/api/admin/corrective/sub-task/:taskId/delete`;

//REMEDIAL
export const REMEDIAL_LIST_API = `${BASE_URL}/api/admin/remedial`;
export const REMEDIAL_LIST_VIEW_API = `${BASE_URL}/api/admin/remedial/:taskId`;
export const REMEDIAL_CREATE = `${BASE_URL}/api/admin/remedial/create`;
export const REMEDIAL_UPDATE = `${BASE_URL}/api/admin/remedial/:taskId/update`;
export const REMEDIAL_DELETE = `${BASE_URL}/api/admin/remedial/:taskId/delete`;
export const REMEDIAL_ALL_TASKS_API = `${BASE_URL}/api/admin/task/remedialtasks`;

export const ALL_PLANT_TASKS_API = `${BASE_URL}/api/admin/plantTasks`;

//PVPLANT_MANAGEMENT
export const PVPLANT_MANAGEMENT_LIST_API = `${BASE_URL}/api/admin/plants`;
export const PVPLANT_MANAGEMENT_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/plants/filter-options`;
export const PVPLANT_MANAGEMENT_CREATE_API = `${BASE_URL}/api/admin/plants/create`;
export const PVPLANT_MANAGEMENT_VIEW_API = `${BASE_URL}/api/admin/plants/:plantId`;
export const PVPLANT_MANAGEMENT_UPDATE_API = `${BASE_URL}/api/admin/plants/:plantId/update`;
export const PVPLANT_MANAGEMENT_ARCHIVE_API = `${BASE_URL}/api/admin/plants/:plantId/archive`;
export const PVPLANT_MANAGEMENT_UNARCHIVE_API = `${BASE_URL}/api/admin/plants/:plantId/unarchive`;
export const PVPLANT_MANAGEMENT_DELETE_API = `${BASE_URL}/api/admin/plants/:plantId/delete`;
export const PVPLANT_MANAGEMENT_USERS_API = `${BASE_URL}/api/admin/plants/users`;
export const EXPORT_PVPLANT_API = `${BASE_URL}/api/admin/plant/export`;
export const GET_ALL_PVPLANTS = `${BASE_URL}/api/admin/all-plants`;
export const GET_PVPLANT_TASKS_API = `${BASE_URL}/api/admin/plant/:plantId/total-tasks`;

//MANUFACTURER
export const MANUFACTURER_LIST_API = `${BASE_URL}/api/admin/manufacturers`;
export const MANUFACTURER_CREATE_API = `${BASE_URL}/api/admin/manufacturer/create`;
export const MANUFACTURER_VIEW_API = `${BASE_URL}/api/admin/manufacturer/:manufacturerId`;
export const MANUFACTURER_UPDATE_API = `${BASE_URL}/api/admin/manufacturer/:manufacturerId/update`;
export const MANUFACTURER_DELETE_API = `${BASE_URL}/api/admin/manufacturer/:manufacturerId/delete`;
export const EXPORT_MANUFACTURER_API = `${BASE_URL}/api/admin/manufacturer/export`;
export const MANUFACTURER_FILTER_LIST_API = `${BASE_URL}/api/admin/manufacturer/filter-options`;

//ASSET_CATEGORY
export const ASSET_CATEGORY_LIST_API = `${BASE_URL}/api/admin/asset-categories`;
export const ASSET_CATEGORY_CREATE_API = `${BASE_URL}/api/admin/asset-category/create`;
export const ASSET_CATEGORY_VIEW_API = `${BASE_URL}/api/admin/asset-category/:assetCategoryId`;
export const ASSET_CATEGORY_UPDATE_API = `${BASE_URL}/api/admin/asset-category/:assetCategoryId/update`;
export const ASSET_CATEGORY_DELETE_API = `${BASE_URL}/api/admin/asset-category/:assetCategoryId/delete`;
export const EXPORT_ASSET_CATEGORY_API = `${BASE_URL}/api/admin/asset-category/export`;
export const ASSET_CATEGORY_FILTER_LIST_API = `${BASE_URL}/api/admin/asset-category/filter-options`;

//ACTIVITY LOG
export const ACTIVTIYLOG_LIST_API = `${BASE_URL}/api/admin/activity-logs`;

//BGE OWNED INVENTORY
export const BGE_OWNED_INVENTORY_LIST_API = `${BASE_URL}/api/admin/bge-owned-inventories`;
export const BGE_OWNED_INVENTORY_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/bge-owned-inventory/filter-options`;
export const BGE_OWNED_INVENTORY_VIEW_API = `${BASE_URL}/api/admin/bge-owned-inventory/:inventoryId`;
export const BGE_OWNED_INVENTORY_CREATE = `${BASE_URL}/api/admin/bge-owned-inventory/create`;
export const BGE_OWNED_INVENTORY_UPDATE = `${BASE_URL}/api/admin/bge-owned-inventory/:inventoryId/update`;
export const BGE_OWNED_INVENTORY_DELETE = `${BASE_URL}/api/admin/bge-owned-inventory/:inventoryId/delete`;
export const MOVE_TO_PLANT_API = `${BASE_URL}/api/admin/bge-owned-inventory/:inventoryId/move-to-plant`;
export const EXPORT_BGE_OWNED_INVENTORY_API = `${BASE_URL}/api/admin/bge-owned-inventory/export`;

//PLANT OWNED INVENTORY
export const PLANT_OWNED_INVENTORY_LIST_API = `${BASE_URL}/api/admin/plant-owned-inventories`;
export const PLANT_OWNED_INVENTORY_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/plant-owned-inventory/filter-options`;
export const PLANT_OWNED_INVENTORY_VIEW_API = `${BASE_URL}/api/admin/plant-owned-inventory/:inventoryId`;
export const PLANT_OWNED_INVENTORY_CREATE = `${BASE_URL}/api/admin/plant-owned-inventory/create`;
export const PLANT_OWNED_INVENTORY_UPDATE = `${BASE_URL}/api/admin/plant-owned-inventory/:inventoryId/update`;
export const PLANT_OWNED_INVENTORY_DELETE = `${BASE_URL}/api/admin/plant-owned-inventory/:inventoryId/delete`;
export const MOVE_TO_BGE_PLANT_API = `${BASE_URL}/api/admin/plant-owned-inventory/:inventoryId/move-to-bge-plant`;
export const EXPORT_PLANT_OWNED_INVENTORY_API = `${BASE_URL}/api/admin/bge-owned-inventory/export`;

//DNO
export const DNO_LIST_API = `${BASE_URL}/api/admin/dnos`;
export const DNO_CREATE_API = `${BASE_URL}/api/admin/dno/create`;
export const DNO_VIEW_API = `${BASE_URL}/api/admin/dno/:dnoId`;
export const DNO_UPDATE_API = `${BASE_URL}/api/admin/dno/:dnoId/update`;
export const DNO_DELETE_API = `${BASE_URL}/api/admin/dno/:dnoId/delete`;
export const ALL_DNO_API = `${BASE_URL}/api/admin/all-dnos`;
export const DNO_FILTER_OPIONS = `${BASE_URL}/api/admin/all-dno/filter-option`;
export const EXPORT_DNO_API = `${BASE_URL}/api/admin/dno/export`;

//HEALTH_AND_SAFETY
export const HEALTH_AND_SAFETY_LIST_API = `${BASE_URL}/api/admin/medical-centers`;
export const HEALTH_AND_SAFETY_CREATE_API = `${BASE_URL}/api/admin/medical-center/create`;
export const HEALTH_AND_SAFETY_VIEW_API = `${BASE_URL}/api/admin/medical-center/:medicalCenterId`;
export const HEALTH_AND_SAFETY_UPDATE_API = `${BASE_URL}/api/admin/medical-center/:medicalCenterId/update`;
export const HEALTH_AND_SAFETY_DELETE_API = `${BASE_URL}/api/admin/medical-center/:medicalCenterId/delete`;
export const ALL_HEALTH_AND_SAFETY_API = `${BASE_URL}/api/admin/all-medicalCenters`;
export const EXPORT_HEALTH_AND_SAFETY_API = `${BASE_URL}/api/admin/medical-center/export`;
export const HEALTH_AND_SAFETY_FILTER_LIST_API = `${BASE_URL}/api/admin/medical-center/filter-options`;

//SLA
export const SLA_LIST_API = `${BASE_URL}/api/admin/slas`;
export const SLA_CREATE_API = `${BASE_URL}/api/admin/sla/create`;
export const SLA_VIEW_API = `${BASE_URL}/api/admin/sla/:slaId`;
export const SLA_UPDATE_API = `${BASE_URL}/api/admin/sla/:slaId/update`;
export const SLA_DELETE_API = `${BASE_URL}/api/admin/sla/:slaId/delete`;
export const EXPORT_SLA_API = `${BASE_URL}/api/admin/sla/export`;
export const SLA_FILTER_LIST_API = `${BASE_URL}/api/admin/sla/filter-options`;

//ASSET
export const ASSET_LIST_API = `${BASE_URL}/api/admin/assets`;
export const ASSET_CREATE_API = `${BASE_URL}/api/admin/assets/create`;
export const ASSET_VIEW_API = `${BASE_URL}/api/admin/assets/:assetId`;
export const ASSET_UPDATE_API = `${BASE_URL}/api/admin/assets/:assetId/update`;
export const ASSET_DELETE_API = `${BASE_URL}/api/admin/assets/:assetId/delete`;
export const ASSET_ALL_MANUFACTURER_API = `${BASE_URL}/api/admin/all-manufacturers`;
export const ASSET_ALL_ASSET_CATEGORY_API = `${BASE_URL}/api/admin/all-assetCategories`;
export const EXPORT_ASSET_API = `${BASE_URL}/api/admin/asset/export`;
export const GET_PRODUCT_ID_API = `${BASE_URL}/api/admin/asset/productId`;
export const ASSET_FILTER_LIST_API = `${BASE_URL}/api/admin/asset/filter-options`;

//FILE
export const SINGLE_FILE_UPLOAD_API = `${BASE_URL}/api/files/single`;
export const MULTIPLE_FILE_UPLOAD_API = `${BASE_URL}/api/files/multi`;
export const GET_FILE_API = `${BASE_URL}/api/files/:key`;
export const SINGLE_FILE_UPLOAD_ON_CREATION_API = `${BASE_URL}/api/files/create/single`;

//ROLE

export const ROLE_MANAGEMENT_LIST_API = `${BASE_URL}/api/admin/roles`;
export const ROLE_MANAGEMENT_CREATE_API = `${BASE_URL}/api/admin/role/create`;
export const ROLE_MANAGEMENT_VIEW_API = `${BASE_URL}/api/admin/role/:roleId`;
export const ROLE_MANAGEMENT_UPDATE_API = `${BASE_URL}/api/admin/role/:roleId/update`;
export const ROLE_MANAGEMENT_ALL_ROLES_API = `${BASE_URL}/api/admin/all-roles`;
export const EXPORT_ROLE_API = `${BASE_URL}/api/admin/role/export`;
export const ROLE_MANAGEMENT_ACTION_API = `${BASE_URL}/api/admin/roleActions`;

//NOTIFICATION
export const NOTIFICATION_LIST_API = `${BASE_URL}/api/admin/notifications`;

//COMMENTS
export const COMMENT_LIST_API = `${BASE_URL}/api/admin/get-comment`;
export const GET_COMMENT_API = `${BASE_URL}/api/admin/comment/:commentId`;
export const CREATE_COMMENT_API = `${BASE_URL}/api/admin/comment/create`;
export const UPDATE_COMMENT_API = `${BASE_URL}/api/admin/comment/:commentId/update`;
export const DELETE_COMMENT_API = `${BASE_URL}/api/admin/comment/:commentId/delete`;

//FORM_MANAGEMENT
export const FORM_MANAGEMENT_LIST_API = `${BASE_URL}/api/admin/manage/forms`;
export const GET_FORMS_API = `${BASE_URL}/api/admin/user/forms`;
export const FORM_MANAGEMENT_CREATE_API = `${BASE_URL}/api/admin/form/create`;
export const FORM_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/form/filteroption`;
export const FORM_MANAGEMENT_VIEW_API = `${BASE_URL}/api/admin/form/:formId`;
export const FORM_MANAGEMENT_UPDATE_API = `${BASE_URL}/api/admin/form/:formId/update`;
export const FORM_MANAGEMENT_DELETE_API = `${BASE_URL}/api/admin/form/:formId/delete`;
export const EXPORT_FORM_API = `${BASE_URL}/api/admin/form/export`;
export const FORM_SUBMISSION_LIST_API = `${BASE_URL}/api/admin/submittedForms`;
export const FORM_SUBMISSION_CREATE_API = `${BASE_URL}/api/admin/formSubmission/create`;
export const FORM_SUBMISSION_VIEW_API = `${BASE_URL}/api/admin/submittedForms/:formId`;
export const EXPORT_SUBMISSION = `${BASE_URL}/api/admin/form/export/submissions`;
export const FORM_FILTER_OPTION = `${BASE_URL}/api/admin/form/submiteduseroption`;

//PREVENTATIVE MAINTENANCE
export const PREVENTATIVE_MASTER = `${BASE_URL}/api/admin/preventative-master`;
export const PREVENTATIVE_MASTER_CREATE = `${BASE_URL}/api/admin/preventative-master/create`;
export const PREVENTATIVE_TASK = `${BASE_URL}/api/admin/preventative-task`;
export const PREVENTATIVE_TASK_CREATE = `${BASE_URL}/api/admin/preventative-task/create`;
export const EXPORT_TASK_API = `${BASE_URL}/api/admin/task/export`;
export const EXPORT_PARTICULAR_TASK_API = `${BASE_URL}/api/admin/export/task/:taskId`;
export const PREVENTATIVE_MASTER_TASK_ARCHIVE_API = `${BASE_URL}/api/admin/preventative-master/:id/archive`;
export const PREVENTATIVE_MASTER_TASK_UNARCHIVE_API = `${BASE_URL}/api/admin/preventative-master/:id/unarchive`;
export const ADMIN_TASK_ARCHIVE_API = `${BASE_URL}/api/admin/tasks/:taskId/archive`;
export const ADMIN_TASK_UNARCHIVE_API = `${BASE_URL}/api/admin/tasks/:taskId/unarchive`;

//STRING MAINTENANCE
export const STRING_LIST_API = `${BASE_URL}/api/admin/string`;
export const MANAGE_STRING = `${BASE_URL}/api/admin/string/:stringId`;
export const CREATE_STRING = `${BASE_URL}/api/admin/string/create`;
export const CHECK_IDENTIFIER = `${BASE_URL}/api/admin/string/check-identifier`;
export const STRING_OPTIONS = `${BASE_URL}/api/admin/string/options`;
export const EXPORT_STRING = `${BASE_URL}/api/admin/string/export`;

//PREVENTATIVE MASTER LIST
export const PREVENTATIVE_MASTER_LIST = `${BASE_URL}/api/admin/preventative-master-list`;
export const MANAGE_PREVENTATIVE_MASTER_LIST = `${BASE_URL}/api/admin/preventative-master-list/:id`;
export const PREVENTATIVE_MASTER_LIST_EXPORT = `${BASE_URL}/api/admin/preventative-master-list/export`;
export const PREVENTATIVE_MASTER_TEMPLATE_EXPORT = `${BASE_URL}/api/admin/preventative-master-list/:id/export`;

//PREVENTATIVE SCHEDULE
export const PREVENTATIVE_SCHEDULE = `${BASE_URL}/api/admin/preventative-schedules`;
export const MANAGE_PREVENTATIVE_SCHEDULE = `${BASE_URL}/api/admin/preventative-schedules/:id`;
export const PREVENTATIVE_SCHEDULE_EXPORT = `${BASE_URL}/api/admin/preventative-schedules/export`;
export const PREVENTATIVE_SCHEDULE_OPTIONS = `${BASE_URL}/api/admin/preventative-schedules/options`;
export const PREVENTATIVE_SCHEDULE_TASK = `${BASE_URL}/api/admin/preventative-schedules/schedule-tasks/:preventativeScheduleId`;
export const PREVENTATIVE_MASTER_TASK = `${BASE_URL}/api/admin/preventative-schedules/master-tasks/:preventativeMasterListId`;
export const SCHEDULE_PREVENTATIVE_TASK = `${BASE_URL}/api/admin/preventative-schedules/preventative-tasks`;

//DASHBOARD
export const GET_DASHBOARD_API = `${BASE_URL}/api/admin/dashboard`;

//FAILURE REASON
export const CREATE_FAILURE_REASON = `${BASE_URL}/api/admin/failure-reasons/create`;
export const MANAGE_FAILURE_REASON = `${BASE_URL}/api/admin/failure-reasons/:failureReasonId`;
export const GET_FAILURE_REASON = `${BASE_URL}/api/admin/failure-reasons`;
export const EXPORT_FAILURE_REASON = `${BASE_URL}/api/admin/failure-reasons/export`;
export const FAILURE_REASON_FILTER_LIST_API = `${BASE_URL}/api/admin/failure-reasons/filter-options`;

//ATTRIBUTE MASTER
export const MANAGE_ATTRIBUTE_MASTER = `${BASE_URL}/api/admin/attribute-masters`;

//ATTRIBUTE
export const MANAGE_ATTRIBUTE = `${BASE_URL}/api/admin/attributes`;
export const UPDATE_ATTRIBUTE = `${BASE_URL}/api/admin/attribute/update`;

//IMPORT
//STRING
export const STRING_IMPORT_URL = `${BASE_URL}/api/admin/string/import-file`;
export const IMPORT_STRING = `${BASE_URL}/api/admin/string/import`;
export const GET_IMPORT_ITEMS = `${BASE_URL}/api/admin/import-items/:importId`;
export const UPDATE_IMPORT = `${BASE_URL}/api/admin/import/:importId/update`;
export const FETCH_IMPORT = `${BASE_URL}/api/admin/import`;
export const UPDATE_IMPORT_ITEM = `${BASE_URL}/api/admin/import-items/update`;

//EQUIPMENT AND SPARES
export const EQUIPMENT_SPARE_LIST_API = `${BASE_URL}/api/admin/equipment-and-spares`;
export const EQUIPMENT_SPARE_LIST_FILTER_OPTIONS_API = `${BASE_URL}/api/admin/equipment-and-spares/filter-options`;
export const EQUIPMENT_SPARE_VIEW_API = `${BASE_URL}/api/admin/equipment-and-spares/:equipmentId`;
export const EQUIPMENT_SPARE_CREATE = `${BASE_URL}/api/admin/equipment-and-spares/create`;
export const EQUIPMENT_SPARE_UPDATE = `${BASE_URL}/api/admin/equipment-and-spares/:equipmentId/update`;
export const EQUIPMENT_SPARE_DELETE = `${BASE_URL}/api/admin/equipment-and-spares/:equipmentId/delete`;
export const EXPORT_EQUIPMENT_SPARE_API = `${BASE_URL}/api/admin/equipment-and-spares/export`;
export const GET_ALL_TASK_LIST_API = `${BASE_URL}/api/admin/all-tasks`;
export const EQUIPMENT_SPARE_LIST_OPTIONS_API = `${BASE_URL}/api/admin/equipment-and-spares/all-options`;

//STOCK TRANSACTION
export const STOCK_TRANSACTION_LIST_API = `${BASE_URL}/api/admin/stock-transactions`;
export const STOCK_TRANSACTION_CREATE = `${BASE_URL}/api/admin/stock-transactions/create`;
