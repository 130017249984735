import React, { useState } from "react";
import useBoolean from "../hooks/useBoolean";
import {
  createCorrectiveMaintenance,
  createRemedialMaintenance,
  getCorrectiveListFilterOptions,
} from "../repositories/MaintenanceRepository";
export const MaintenanceContext = React.createContext();

const initialFilterOptions = {
  correctiveStatuses: [],
};
export const AuthProvider = (props) => {
  const [filterOptions, setFilterOptions] = useState(Object.assign({}, initialFilterOptions));

  const [filtersLoading, flState] = useBoolean(false);
  const [createLoading, clState] = useBoolean(false);

  const loadFilterOptions = async () => {
    flState.on();
    try {
      const response = await getCorrectiveListFilterOptions();
      setFilterOptions(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      flState.off();
    }
  };

  //CREATE CORRECTIVE
  const createCorrective = async (createParams) => {
    clState.on();
    try {
      await createCorrectiveMaintenance(createParams);
    } catch (e) {
      throw e;
    } finally {
      clState.off();
    }
  };

  //CREATE REMEDIAL
  const createRemedial = async (createParams) => {
    clState.on();
    try {
      await createRemedialMaintenance(createParams);
    } catch (e) {
      throw e;
    } finally {
      clState.off();
    }
  };
  const mContext = {
    loadFilterOptions,
    createLoading,
    filterOptions,
    createCorrective,
    createRemedial,
  };
  return <MaintenanceContext.Provider value={mContext}>{props.children}</MaintenanceContext.Provider>;
};

export const withMaintenanceProvider = (Container, containerProps = {}) => {
  return () => (
    <AuthProvider>
      <Container {...containerProps} />
    </AuthProvider>
  );
};
