import { format } from "date-fns";
import React from "react";
import useBoolean from "../../../hooks/useBoolean";
import {
  deleteTaskAPI,
  getCorrectiveList,
  getTaskAPI,
  updateTaskAPI,
  getAllCorrectivetasks,
  unarchiveTaskAPI,
  archiveTaskAPI,
} from "./CorrectiveRepository";
export const CorrectiveContext = React.createContext();

const initialPaginationState = {
  page: 1,
  size: 10,
};

const initialFilterParams = {
  selectedTaskStatus: [],
  selectedAssignedEngineer: [],
  selectedPlant: [],
  isArchiveSelected: false,
  searchText: "",
  startDate: "",
  resolvedDate: "",
  sortingOrder: "",
  sortingField: "",
  table: "",
};

export const CorrectiveProvider = (props) => {
  const [correctiveList, setCorrectiveList] = React.useState([]);
  const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState));
  const [filterParams, setFilterParams] = React.useState(Object.assign({}, initialFilterParams));
  const [currentViewTask, setCurrentViewTask] = React.useState();
  const [correctivetasks, setCorrectiveTasks] = React.useState([]);

  //Loaders
  const [correctiveLoading, correctiveState] = useBoolean(false);
  const [loadingTasks, tlState] = useBoolean(false);
  const [dashboardFilterStatus, setDashboardFilterStatus] = useBoolean(false);
  const [updateLoading, upState] = useBoolean(false);

  //Close Tab
  const [showFilter, sfState] = useBoolean(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const getCorrectiveListFilterParams = () => {
    const queryParams = { ...pagination };
    const {
      selectedTaskStatus,
      isArchiveSelected,
      searchText,
      selectedAssignedEngineer,
      resolvedDate,
      startDate,
      selectedPlant,
      sortingField,
      sortingOrder,
      table,
    } = filterParams;

    if (selectedTaskStatus.length) queryParams["taskStatus"] = selectedTaskStatus;
    if (selectedPlant.length) queryParams["taskPlant"] = selectedPlant;
    if (selectedAssignedEngineer.length) queryParams["taskAssignedEngineer"] = selectedAssignedEngineer;
    if (searchText) queryParams["taskText"] = searchText;
    if (isArchiveSelected != null) queryParams["taskArchived"] = isArchiveSelected;
    if (startDate) queryParams["taskStartDate"] = startDate;
    if (resolvedDate) queryParams["taskResolvedDate"] = resolvedDate;
    if (sortingField) queryParams["sortingField"] = sortingField;
    if (sortingOrder) queryParams["sortingOrder"] = sortingOrder;
    if (table) queryParams["table"] = table;
    return queryParams;
  };

  const loadCorrectiveList = async (plantId, status) => {
    correctiveState.on();
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] });
    }

    try {
      const filterParams = getCorrectiveListFilterParams();
      if (status) {
        filterParams.taskStatus = status;
      }
      if (plantId) {
        filterParams["taskPlant"] = plantId;
      }
      const response = await getCorrectiveList(filterParams);
      setCorrectiveList(response.correctiveList);
    } catch (e) {
      // CAPTURE EXCEPTION
      throw e;
    } finally {
      correctiveState.off();
    }
  };
  // VIEW TASK
  const loadTask = async (taskId) => {
    try {
      const response = await getTaskAPI(taskId);
      setCurrentViewTask(response.task);
      return response;
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };
  // UPDATE TASK
  const updateTask = async (taskId, updateParams) => {
    upState.on();
    try {
      const response = await updateTaskAPI(taskId, updateParams);
      setCurrentViewTask(response.task);
    } catch (e) {
      throw e;
    } finally {
      upState.off();
    }
  };

  // DELETE TASK
  const deleteTask = async (taskId, plantId) => {
    try {
      await deleteTaskAPI(taskId);
      if (plantId) {
        filterParams["taskPlant"] = plantId;
      }
      const response = await getCorrectiveList(filterParams);
      setCorrectiveList(response.correctiveList);
    } catch (e) {
      throw e;
    } finally {
      //
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  //APPLY FILTER
  const checkIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedPlant.length != 0 ||
      filterParams.selectedAssignedEngineer.length != 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else return false;
  };
  const isFilterApplied = checkIsFilterApplied();
  const subCheckIsFilterApplied = () => {
    if (
      filterParams.isArchiveSelected ||
      filterParams.selectedTaskStatus.length != 0 ||
      filterParams.selectedAssignedEngineer.length != 0 ||
      filterParams.startDate ||
      filterParams.resolvedDate
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isSubFilterApplied = subCheckIsFilterApplied();

  const loadAllCorrectiveTasks = async (filter) => {
    tlState.on();
    try {
      const response = await getAllCorrectivetasks(filter);
      setCorrectiveTasks(response);
    } catch (e) {
      // HANDLE ERROR
    } finally {
      tlState.off();
    }
  };
  //ARCHIVE TASK
  const archiveTask = async (taskId) => {
    try {
      tlState.on();
      await archiveTaskAPI(taskId);
      loadCorrectiveList();
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  //UNARCHIVE TASK
  const unarchiveTask = async (taskId) => {
    try {
      tlState.on();
      await unarchiveTaskAPI(taskId);
      loadCorrectiveList();
    } catch (e) {
      throw e;
    } finally {
      tlState.off();
    }
  };

  const handleApplyFilter = (selectedFilters) => {
    setFilterParams(selectedFilters);
  };

  const resetClientListFilter = () => setFilterParams(Object.assign({}, initialFilterParams));

  const mContext = {
    checkIsFilterApplied,
    showFilter,
    updateLoading,
    sfState,
    correctiveList,
    pagination,
    loadCorrectiveList,
    toggleModal,
    isOpen,
    setIsOpen,
    isFilterApplied,
    correctiveLoading,
    filterParams,
    isSubFilterApplied,
    subCheckIsFilterApplied,
    loadTask,
    handleApplyFilter,
    resetClientListFilter,
    currentViewTask,
    updateTask,
    deleteTask,
    correctivetasks,
    loadingTasks,
    archiveTask,
    unarchiveTask,
    loadAllCorrectiveTasks,
    dashboardFilterStatus,
    setDashboardFilterStatus,
    updateShowListSize: (size) => setPagination({ ...pagination, size, page: 1 }),
    updateShowListPage: (page) => setPagination({ ...pagination, page }),
  };

  return <CorrectiveContext.Provider value={mContext}>{props.children}</CorrectiveContext.Provider>;
};

export const withCorrectiveProvider = (Container, containerProps) => (props) =>
  (
    <CorrectiveProvider>
      <Container {...containerProps} {...props} />
    </CorrectiveProvider>
  );
