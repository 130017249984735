import React, { useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import { FormGroup, UncontrolledDropdown } from "reactstrap";
import { Button, Col, Icon, RSelect, Row } from "../../../../../components/Component";

import "react-datepicker/dist/react-datepicker.css";
import { PREVENTATIVE_TASK_FILTERS } from "../../../../../constants/PreferenceKeys";
import { sortOption } from "../../../../../utils/Utils";
import { MONTH_OPTIONS, STATUS } from "../../constants";
import { PreventativeTaskContext } from "../PreventativeTaskProvider";

const PreventativeListFilter = (props) => {
  const preventativeContext = useContext(PreventativeTaskContext);
  const { plants, engineers } = props;

  const {
    filterParams,
    handleApplyFilter,
    setDashboardFilterStatus,
    sfState,
    subCheckIsFilterApplied,
    checkIsFilterApplied,
    showFilter,
  } = preventativeContext;
  const [selectedFilter, setSelectedFilter] = React.useState(filterParams);

  useEffect(() => {
    setSelectedFilter(filterParams);
  }, [filterParams]);

  useEffect(() => {
    const filters = localStorage.getItem(PREVENTATIVE_TASK_FILTERS);
    if (filters) {
      const parseFilters = JSON.parse(filters);
      handleApplyFilter({
        ...parseFilters,
        startDate: parseFilters.startDate ? new Date(parseFilters.startDate) : "",
        resolvedDate: parseFilters.resolvedDate ? new Date(parseFilters.resolvedDate) : "",
      });
    }
  }, []);
  const handleReset = () => {
    setDashboardFilterStatus.on();
    preventativeContext.resetClientListFilter();
    localStorage.removeItem(PREVENTATIVE_TASK_FILTERS);
  };

  const handleApply = () => {
    handleApplyFilter(selectedFilter);
    setDashboardFilterStatus.on();
    checkIsFilterApplied();
    subCheckIsFilterApplied();
    sfState.off();
  };

  const handleFilterSelection = (params) => {
    setSelectedFilter({
      ...selectedFilter,
      ...params,
    });
  };

  const handleStateFilterSelection = (selectedOption) => {
    const statusFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedTaskStatus: statusFilter });
  };

  const handleAssignedFilterSelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedAssignedEngineer: assignedFilter });
  };

  const handleMonthFilterSelection = (selectedOption) => {
    const monthFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedMonthFilter: monthFilter });
  };

  const handlePlantSelection = (selectedOption) => {
    const assignedFilter = selectedOption.map((item, key) => {
      return item.value;
    });
    handleFilterSelection({ selectedPlant: assignedFilter });
  };

  const handleArchiveFilter = (e) => {
    const isChecked = e.target.checked;
    handleFilterSelection({ isArchiveSelected: isChecked });
  };

  const handleStartDateSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ startDate: "" });
    } else {
      handleFilterSelection({ startDate: selectedOption });
    }
  };

  const handleResolvedDateSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ resolvedDate: "" });
    } else {
      handleFilterSelection({ resolvedDate: selectedOption });
    }
  };

  const handleYearSelection = (selectedOption) => {
    if (selectedOption === null) {
      handleFilterSelection({ taskYear: "" });
    } else {
      handleFilterSelection({ taskYear: selectedOption });
    }
  };

  const statusSelectedFilter = selectedFilter.selectedTaskStatus.map((item, key) => {
    return STATUS?.[STATUS?.findIndex((status) => status.value === item)];
  });

  const assignedEngineerSelectedFilter = selectedFilter.selectedAssignedEngineer.map((item, key) => {
    return engineers?.[engineers?.findIndex((status) => status.value === item)];
  });

  const monthSelectedFilter = selectedFilter.selectedMonthFilter.map((item, key) => {
    return MONTH_OPTIONS?.[MONTH_OPTIONS?.findIndex((status) => status.value === item)];
  });

  const plantSelectedFilter = selectedFilter.selectedPlant.map((item, key) => {
    return plants?.[plants?.findIndex((status) => status.value === item)];
  });

  return (
    <UncontrolledDropdown isOpen={showFilter} onToggle={sfState.toggle}>
      <div className="vh-100">
        <div className="dropdown-head">
          <span className="sub-title dropdown-title fs-16px">Filter Options</span>
          <div onClick={sfState.off}>
            <Icon className={"fs-18px text-dark"} style={{ cursor: "pointer" }} name={"cross"}></Icon>
          </div>
        </div>
        <div className="dropdown-body dropdown-body-rg">
          <Row className="gx-6 gy-3">
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Status</label>
                <RSelect
                  options={STATUS}
                  placeholder="Any Status"
                  onChange={handleStateFilterSelection}
                  isMulti
                  value={statusSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size={12}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Assigned Engineer</label>
                <RSelect
                  options={sortOption(engineers)}
                  placeholder="Any Engineer"
                  onChange={handleAssignedFilterSelection}
                  isMulti
                  value={assignedEngineerSelectedFilter}
                />
              </FormGroup>
            </Col>
            {props.subList ? null : (
              <Col size={12}>
                {props.isPlantName ? (
                  <FormGroup>
                    <label className="overline-title overline-title-alt">Plant Name</label>
                    <RSelect onChange={handlePlantSelection} value={plantSelectedFilter} isDisabled />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <label className="overline-title overline-title-alt">Plant Name</label>
                    <RSelect
                      options={sortOption(plants)}
                      placeholder="Any Plant"
                      onChange={handlePlantSelection}
                      isMulti
                      value={plantSelectedFilter}
                    />
                  </FormGroup>
                )}
              </Col>
            )}
            <Col size={6}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Month</label>
                <RSelect
                  options={MONTH_OPTIONS}
                  placeholder="Any Month"
                  onChange={handleMonthFilterSelection}
                  isMulti
                  value={monthSelectedFilter}
                />
              </FormGroup>
            </Col>
            <Col size={6}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Year</label>
                <DatePicker
                  placeholderText="Any Year"
                  showYearPicker
                  dateFormat="yyyy"
                  className="form-control"
                  selected={selectedFilter.taskYear ? new Date(selectedFilter.taskYear) : null}
                  onChange={(date) => {
                    handleYearSelection(date.getFullYear().toString());
                  }}
                />
              </FormGroup>
            </Col>
            <Col size={6}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Start Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.startDate || null}
                  onChange={(date) => handleStartDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size={6}>
              <FormGroup>
                <label className="overline-title overline-title-alt">Resolved Date</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  selected={selectedFilter.resolvedDate || null}
                  onChange={(date) => handleResolvedDateSelection(date)}
                />
              </FormGroup>
            </Col>
            <Col size={6}>
              <div className="custom-control custom-control-sm custom-checkbox" style={{ zIndex: 0 }}>
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  id="isArchive"
                  onChange={handleArchiveFilter}
                  checked={selectedFilter.isArchiveSelected}
                />
                <label className="custom-control-label" htmlFor="isArchive">
                  {" "}
                  Archived
                </label>
              </div>
            </Col>
            <Col className="d-flex justify-content-between" size="12">
              <Button
                outline={true}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleReset();
                }}
                color="secondary"
              >
                Reset Filter
              </Button>
              <Button onClick={handleApply} color="secondary">
                Apply
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </UncontrolledDropdown>
  );
};

export default PreventativeListFilter;
