/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../components/Component";
import { exportPlantAPI } from "../PvPlantManagementRepository";
import download from "js-file-download";
import { errorToast, successToast } from "../../../components/toastify/Toastify";

const PLANT_FIELDS = [
  {
    label: "Name",
    value: "name",
  },

  {
    label: "Size",
    value: "size",
  },
  {
    label: "Identifier",
    value: "identifier",
  },

  {
    label: "OnBoarded At",
    value: "onboardedAt",
  },
  {
    label: "Client",
    value: "clients.name",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Address",
    value: "address",
  },
  {
    label: "Postal Code",
    value: "postalCode",
  },
  {
    label: "Plant Managers",
    value: "plantManager.firstName",
  },
  {
    label: "Team Leaders",
    value: "teamLeaderId",
  },
  {
    label: "Created By",
    value: "createdById",
  },
  {
    label: "Is Archived",
    value: "isArchived",
  },
  {
    label: "Dno Id",
    value: "dnos.name",
  },
  {
    label: "Medical Centers",
    value: "medicalCenters.name",
  },
  {
    label: "GoogleMap Link",
    value: "googleMapLink",
  },
  {
    value: "what3Word Link",
    label: "what3WordLink",
  },
  {
    value: "fieldEngineers",
    label: "Field Engineers",
  },
  {
    value:"attachments",
    label:"Attachments"
  }
];
const ExportForm = (props) => {
  const [value, setValue] = useState(
    PLANT_FIELDS.map((plant) => {
      return plant.value;
    })
  );
  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async (formData) => {
    try {
      setExportLoading(true);
      const response = await exportPlantAPI({...props.filterParams,headerData: value});
      download(response, `pv-plant.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting plants" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export-pvplant-btn"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Plant Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0 ">
                {PLANT_FIELDS &&
                  PLANT_FIELDS.map((plant, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${plant} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${plant}-${key}`}
                                type="checkbox"
                                checked={value.includes(plant.value)}
                                id={`${plant}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([plant.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === plant.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${plant}-${key}`} check>
                                {plant.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
